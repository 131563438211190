import { useState, useContext, useEffect } from "react";
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from "sweetalert2";
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion con api
import {
    apiGetPictureProductId,
    apiGetProduct,
    apiPatchPictureProduct
} from '../../../common/api-invent';

const EditProdPicture = ({ show, setShow, id }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 13;

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState({
        nombre: ''
    });


    // Hook de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });
    // Hook de formulario
    const [data, setData] = useState({
        nombre: '',
        descripcion: '',
        archivo: '',
    });

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Informacion del detalle
                const reqDetail = await apiGetPictureProductId(id);
                setData({
                    ...reqDetail.data.content
                });
                // Informacion del producto
                const reqProd = await apiGetProduct(reqDetail.data.content.id_producto);
                setProduct(prod => ({
                    ...prod,
                    nombre: reqProd.data.content.nombre
                }));
                setLoading(false);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setLoading(false);
        }
    }, [setLoading, show, id]);

    // Moficacion de campos
    const onInputChange = e => {
        setData({
            ...data,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }

    const isFormValid = () => {
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre',
                show: true
            });
            return false;
        }
        return true;
    }
    // Boton de agregar en formulario
    const handleSubmit = async e => {
        if (isFormValid()) {
            try {
                const response = await apiPatchPictureProduct(id, data);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu fotografía ha sido editada exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al editar fotografía");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data?.msg ?? 'Error al editar nueva fotografía',
                    text: 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Editar Fotografía"
                buttonTitle="Guardar"
                handleClick={handleSubmit}
                size="md"
            >{
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="producto_nombre" className="form-label">
                                            Producto:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="producto_nombre"
                                            value={product.nombre}
                                            disabled
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="4">
                                    <div className="form-group">
                                        <label htmlFor="nombre" className="form-label">
                                            Nombre: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            value={data.nombre}
                                            onChange={onInputChange}
                                            placeholder="Foto Frente Producto"
                                        />
                                    </div>
                                </Col>
                                <Col md="8">
                                    <div className="form-group">
                                        <label htmlFor="descripcion" className="form-label">
                                            Descripción:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="descripcion"
                                            name="descripcion"
                                            value={data.descripcion}
                                            onChange={onInputChange}
                                            placeholder="Foto Frente Producto"
                                        />
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className="form-group">
                                        <label htmlFor="imagen" className="form-label">
                                            Imagen:
                                        </label>
                                        <img
                                            className="form-control"
                                            src={data.archivo}
                                            alt="Imagen Producto"
                                            style={{
                                                height: "150px",
                                                width: "auto"
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    )
}

export default EditProdPicture