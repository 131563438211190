import { useState, useEffect, useContext } from "react";
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from "sweetalert2";
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import {
    apiGetAllCountries,
    apiPostNewStore,
} from '../../../common/api-invent';

const AddStoreModal = ({ show, setShow }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [countries, setCountries] = useState([]);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [store, setStore] = useState({
        nombre: '',
        calle: '',
        no_exterior: '',
        no_interior: '',
        colonia: '',
        municipio: '',
        estado: '',
        cp: '',
        id_pais: '0',
    });
    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Informacion de paises
                const reqCou = await apiGetAllCountries();
                setCountries([...reqCou.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData();
        return () => { }
    }, [setLoading, show]);
    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setStore({
            ...store,
            [e.target.name]: e.target.value
        });
    }

    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        // Validación para campos vacios
        if (store.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere del nombre',
                show: true,
            });
            return false
        }
        if (store.calle === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere de calle',
                show: true,
            });
            return false
        }
        if (store.no_exterior === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere de número exterior',
                show: true,
            });
            return false
        }
        if (store.cp === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere de código postal',
                show: true,
            });
            return false
        }
        if (store.colonia === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere de colonia',
                show: true,
            });
            return false
        }
        if (store.municipio === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere de municipio',
                show: true,
            });
            return false
        }
        if (store.id_pais === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere de un país',
                show: true,
            });
            return false
        }
        if (store.estado === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere de un estado',
                show: true,
            });
            return false
        }
        if (store.cp.length !== 5) {
            setAlert({
                title: 'Codigo postal incompleto',
                message: 'Su longitud requiere 5 caracteres',
                show: true
            });

            return false;
        }
        return true;
    }

    /*Insertar un nuevo almacen*/
    const handleSubmit = async e => {
        e.preventDefault();

        if (isFormValid()) {
            try {
                const response = await apiPostNewStore(store);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "El almacén ha sido agregado exitosamente",
                        text: response.data.content.nombre,
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                    });
                    // Carga de componente
                    onReloadComponent(3);
                    // Limpiar datos
                    setStore({
                        nombre: '',
                        calle: '',
                        no_exterior: '',
                        no_interior: '',
                        colonia: '',
                        municipio: '',
                        estado: '',
                        cp: '',
                        id_pais: '0',
                    });
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al agregar nuevo almacén");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nuevo almacén',
                    text: error.response.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Agregar Almacén"
                buttonTitle="Agregar"
                handleClick={handleSubmit}
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="50px"
                            marginTop="50px"
                        />
                        : <Form>
                            <Row>
                                <Col lg="6" md="8">
                                    <div className="form-group">
                                        <label htmlFor="nombre" className="form-label">
                                            Nombre Almacén: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            value={store.nombre}
                                            onChange={handleFormChange}
                                            placeholder="Quéretaro A-1"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <h5 className="box-title  mb-20 mt-5">
                                    <i className="si si-directions me-15"></i>
                                    Dirección
                                </h5>
                                <Col md="4">
                                    <div className="form-group">
                                        <label htmlFor="id_pais" className="form-label">
                                            País: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_pais"
                                            id="id_pais"
                                            value={store.id_pais}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un país</option>
                                            {
                                                countries.map(({ glb_id_pais, glb_nombre }, index) => (
                                                    <option value={glb_id_pais} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="form-group">
                                        <label htmlFor="estado" className="form-label">
                                            Estado: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="estado"
                                            name="estado"
                                            value={store.estado}
                                            onChange={handleFormChange}
                                            placeholder="CDMX"
                                        />
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="form-group">
                                        <label htmlFor="municipio" className="form-label">
                                            Municipio / Distrito: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="municipio"
                                            name="municipio"
                                            value={store.municipio}
                                            onChange={handleFormChange}
                                            placeholder="Tlalpan"
                                        />
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="form-group">
                                        <label htmlFor="colonia" className="form-label">
                                            Colonia / Vecindario: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="colonia"
                                            name="colonia"
                                            value={store.colonia}
                                            onChange={handleFormChange}
                                            placeholder="Nueva Soledad"
                                        />
                                    </div>
                                </Col>
                                <Col md="3">
                                    <div className="form-group">
                                        <label htmlFor="cp" className="form-label">
                                            Código Postal: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cp"
                                            name="cp"
                                            value={store.cp}
                                            maxLength="10"
                                            onChange={handleFormChange}
                                            placeholder="01234"
                                        />
                                    </div>
                                </Col>
                                <Col md="5">
                                    <div className="form-group">
                                        <label htmlFor="calle" className="form-label">
                                            Calle: *
                                        </label>
                                        <input type="text"
                                            className="form-control"
                                            id="calle"
                                            name="calle"
                                            value={store.calle}
                                            onChange={handleFormChange}
                                            placeholder="Avenida Siempre Viva"
                                        />
                                    </div>
                                </Col>
                                <Col md="3">
                                    <div className="form-group">
                                        <label htmlFor="no_exterior" className="form-label">
                                            No. Exterior: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="no_exterior"
                                            name="no_exterior"
                                            value={store.no_exterior}
                                            onChange={handleFormChange}
                                            placeholder="12123"
                                        />
                                    </div>
                                </Col>
                                <Col md="3">
                                    <div className="form-group">
                                        <label htmlFor="no_interior" className="form-label">
                                            No. Interior:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="no_interior"
                                            name="no_interior"
                                            value={store.no_interior}
                                            onChange={handleFormChange}
                                            placeholder="Unidad, Edificio, Depto"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default AddStoreModal;    