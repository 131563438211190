import { useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
// Componentes Bootstrap
import {
  ButtonGroup,
  Button
} from 'react-bootstrap';
import { OverlayTooltip } from '../common.component';
// Estilos
import './tableSimple.styled.css'
// Utilidades
import { ColorPrimary } from '../../utilities/pallete.const';

//---------------------------------------------------------------------
const EditButton = ({ editComponent, idElement }) => {
  const [show, setShow] = useState(false);
  const AuxComponent = editComponent;
  return (
    <>
      <OverlayTooltip
        placement="top"
        tooltip="Editar"
      >
        <Button
          onClick={() => { setShow(true) }}
          variant="info"
          className='waves-effect waves-light'
        >
          <i className='si si-pencil' />
        </Button>
      </OverlayTooltip>
      <AuxComponent
        show={show}
        setShow={setShow}
        id={idElement}
      />
    </>
  )
}

//---------------------------------------------------------------------
// Generacion de componente
const TableSimple = ({
  isCountable,
  mainTitle,
  headTitles,
  body,
  actions,
  deleteTitle,
  deleteText,
  deleteFunction,
  editComponent,
  customIcon,
  customTextOverlay,
  onCustomAction,
}) => {
  // Funcion para eliminar un elemento
  const handleDeleteElement = async id => {
    const response = await Swal.fire({
      showCancelButton: true,
      showCloseButton: true,
      title: deleteTitle,
      text: deleteText,
      icon: 'warning',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'No, regresar',
      cancelButtonColor: ColorPrimary,
      reverseButtons: true
    });
    // Revisar si se ha aceptado la alerta
    if (response.isConfirmed) {
      deleteFunction(id);
    }
  }
  return (
    <div>
      {
        mainTitle !== ''
          ? <h3 className="mb-20 px-5">{mainTitle}</h3>
          : <></>
      }
      <div className="table-responsive" >
        <table className="table table-striped mb-0 simple-table" >
          <thead>
            <tr>
              {
                isCountable
                  ? <th>#</th>
                  : <></>
              }
              {
                headTitles.map((title, i) => (
                  <th key={i}>
                    {title}
                  </th>
                ))
              }
              {
                actions.length > 0
                  ? <th className='actions-title'>Acciones</th>
                  : <></>
              }
            </tr>
          </thead>
          <tbody>
            {
              body.map((row, index) => (
                <tr key={index}>
                  {
                    isCountable
                      ? <td>{index + 1}</td>
                      : <></>
                  }
                  {
                    row.content.map((element, index) => (
                      <td key={index}>
                        {element}
                      </td>
                    ))
                  }
                  {
                    actions.length > 0
                      ? <td className='d-flex actions-container'>
                        <ButtonGroup>
                          {
                            actions.includes('edit')
                              ? <EditButton
                                editComponent={editComponent}
                                idElement={row.id} />
                              : <></>
                          }
                          {
                            actions.includes('delete')
                              ? <OverlayTooltip
                                placement="top"
                                tooltip="Eliminar"
                              >

                                <Button
                                  onClick={() => { handleDeleteElement(row.id) }}
                                  variant="danger"
                                  className='waves-effect waves-light'
                                >
                                  <i className='si si-trash' />
                                </Button>
                              </OverlayTooltip>
                              : <></>
                          }
                          {
                            actions.includes('extra')
                              ? <OverlayTooltip
                                placement="top"
                                tooltip={customTextOverlay}
                              >
                                <Button
                                  onClick={() => { onCustomAction(row.id) }}
                                  variant="warning"
                                  className='waves-effect waves-light btnMas'
                                  id={row.id}
                                  index={row.id}
                                >
                                  <i className={customIcon + " btnMas"} id={row.id} index={row.id}  />
                                </Button>
                              </OverlayTooltip>
                              : <></>
                          }
                        </ButtonGroup>
                      </td>
                      : <></>
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div >
  );
}
// Documentacion PropTypes
TableSimple.propTypes = {
  isCountable: PropTypes.bool,
  mainTitle: PropTypes.string,
  headTitles: PropTypes.array.isRequired,
  body: PropTypes.array.isRequired,
  actions: PropTypes.array,
  deleteTitle: PropTypes.string,
  deleteText: PropTypes.string,
  deleteFunction: PropTypes.func,
  editComponent: PropTypes.elementType,
  customIcon: PropTypes.string,
  customTextOverlay: PropTypes.string,
  onCustomAction: PropTypes.func,
}
TableSimple.defaultProps = {
  mainTitle: '',
  isCountable: false,
  actions: [],
  deleteTitle: '!Cuidado!',
  deleteText: '¿Esta seguro que desea eliminar al elemento?',
  deleteFunction: e => { },
  customTextOverlay: 'Más',
  customIcon: 'si si-plus',
  onCustomAction: id => { },
}

export default TableSimple;