import { useState, useEffect, useContext } from "react";
import { Row, Col, } from 'react-bootstrap';
import Swal from "sweetalert2";
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
import SelectItemContext from "../../../context/selectItem/selectItem.context";
// Conexion Api
import {
    apiGetStores,
    apiPostNewLevel,
    apiGetAllRacks
} from '../../../common/api-invent';

const AddLevelModal = ({ show, setShow }) => {
    const {
        selectedRack,
        selectedStore,
        onSelectRack,
        onSelectStore
    } = useContext(SelectItemContext);
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 2;

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [stores, setStores] = useState([]);
    const [racks, setRacks] = useState([]);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        nombre: '',
        nivel: '',
        codigo_ubicacion: '',
        id_almacen: '0',
        id_rack: '0',
        cantidad_cajas: '',
        descripcion: '',
        golden: ''
    });

    // Carga de almacenes y racks
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const reqSto = await apiGetStores();
                setStores([...reqSto.data.content]);
                if (data.id_almacen !== '0') {
                    const reqRac = await apiGetAllRacks(data.id_almacen);
                    setRacks([...reqRac.data.content]);
                }
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData();
        return () => {
            setStores([]);
            setRacks([]);
        }
    }, [show, setLoading, setStores, setRacks, data.id_almacen])

    // Cambiar id de almacen /rack seleccionado
    useEffect(() => {
        setData(data => ({
            ...data,
            id_almacen: selectedStore,
            id_rack: selectedRack,
        }));
    }, [selectedStore, selectedRack]);

    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.id_almacen === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere almacen',
                show: true
            });
            return false;
        }
        if (data.id_rack === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere rack',
                show: true
            });
            return false;
        }
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre',
                show: true
            });
            return false;
        }
        if (data.nivel === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nivel',
                show: true
            });
            return false;
        }
        if (data.cantidad_cajas === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere cantidad de embalajes',
                show: true
            });
            return false;
        }
        return true;
    }
    /*Insertar un nuevo nivel*/
    const _handleSubmit = async (closeModal = true) => {
        if (isFormValid()) {
            try {
                const sendData = { ...data };
                delete sendData.id_almacen;
                const response = await apiPostNewLevel(sendData, data.id_rack);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu nivel ha sido agregado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Carga de componente
                    onReloadComponent(idReloadComp);
                    // Modificar contenedor de tabla del rack
                    onSelectStore(data.id_almacen);
                    onSelectRack(data.id_rack);
                    // Limpiar datos
                    setData({
                        nombre: '',
                        nivel: '',
                        codigo_ubicacion: '',
                        id_almacen: selectedStore,
                        id_rack: selectedRack,
                        cantidad_cajas: '',
                        descripcion: '',
                        golden: ''
                    });
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar nuevo nivel");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nuevo nivel',
                    text: error.response.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(true);
    }

    // Guardar y limpiar
    const handleSave = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(false);
    }

    return (
        <>
            <ToastModalContainer show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                size="md"
                modalTitle="Agregar Nivel"
                buttonTitle="Agregar y cerrar"
                handleClick={handleSaveClose}
                auxButtonTitle="Agregar y guardar"
                handleAuxButton={handleSave}
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_almacen" className="form-label">
                                            Almacen: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_almacen"
                                            id="id_almacen"
                                            value={data.id_almacen}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un almacen</option>
                                            {
                                                stores.map(({ glb_id_almacen, glb_nombre }, index) => (
                                                    <option value={glb_id_almacen} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_rack" className="form-label">
                                            Rack: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_rack"
                                            id="id_rack"
                                            value={data.id_rack}
                                            onChange={handleFormChange}
                                            disabled={data.id_almacen === '0'}
                                        >
                                            <option value="0">Seleccione un Rack</option>
                                            {
                                                racks.map(({ id_rack, nombre, codigo_rack }, index) => (
                                                    <option value={id_rack} key={index}>
                                                        {`${nombre} - ${codigo_rack}`}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="nombre" className="form-label">
                                            Nombre: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            value={data.nombre}
                                            onChange={handleFormChange}
                                            placeholder="Nivel 1-A"
                                        />
                                    </div>
                                </Col>
                                <Col md="4" xs="6">
                                    <div className="form-group">
                                        <label htmlFor="codigo_ubicacion" className="form-label">
                                            Código Ubicación:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="codigo_ubicacion"
                                            name="codigo_ubicacion"
                                            value={data.codigo_ubicacion}
                                            onChange={handleFormChange}
                                            placeholder="UB-0001"
                                        />
                                    </div>
                                </Col>
                                <Col md="2" xs="6">
                                    <div className="form-group">
                                        <label htmlFor="nivel" className="form-label">
                                            Nivel: *
                                        </label>
                                        <input
                                            value={data.nivel}
                                            onChange={handleFormChange}
                                            type="number"
                                            className="form-control"
                                            name="nivel"
                                            id="nivel"
                                            min="1"
                                            step="1"
                                            placeholder="1"
                                        />
                                    </div>
                                </Col>
                                <Col xs="5">
                                    <div className="form-group">
                                        <label htmlFor="cantidad_cajas" className="form-label">
                                            Capacidad de Embalajes: *
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="cantidad_cajas"
                                            name="cantidad_cajas"
                                            value={data.cantidad_cajas}
                                            onChange={handleFormChange}
                                            placeholder="3"
                                            min="1"
                                        />
                                    </div>
                                </Col>
                                <Col xs="7">
                                    <div className="form-group">
                                        <label htmlFor="descripcion" className="form-label">
                                            Descripción:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="descripcion"
                                            name="descripcion"
                                            value={data.descripcion}
                                            onChange={handleFormChange}
                                            placeholder="Nivel para almacenar placas de 60x60"
                                        />
                                    </div>
                                </Col>
                                <Col xs="5">
                                    <div className="form-group">
                                        <label htmlFor="golden" className="form-label">
                                            Golden:
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="golden"
                                            name="golden"
                                            value={data.golden}
                                            onChange={handleFormChange}
                                            
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </>
                }
            </Modals>
        </>
    );
}

export default AddLevelModal;