import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import {
    apiGetProduct,
    apiGetDetailProductId,
    apiPatchDetailProduct,
} from '../../../common/api-invent';

const EditProdFeat = ({ show, setShow, id }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 11;
    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState({
        nombre: ''
    });

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        nombre: '',
        valor: '',
    });
    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Informacion del detalle
                const reqDetail = await apiGetDetailProductId(id, 'caracteristicas');
                setData(data => ({
                    ...data,
                    nombre: reqDetail.data.content.nombre,
                    valor: reqDetail.data.content.valor,
                }));
                // Informacion del producto
                const reqProd = await apiGetProduct(reqDetail.data.content.id_producto);
                setProduct(prod => ({
                    ...prod,
                    nombre: reqProd.data.content.nombre
                }))
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setLoading(false);
        }
    }, [setProduct, setData, setLoading, show, id]);

    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre de la propiedad',
                show: true
            });
            return false;
        }
        if (data.valor === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere valor de la propiedad',
                show: true
            });
            return false;
        }
        return true;
    }

    // Editar Caracterisica
    const handleSubmit = async event => {
        event.preventDefault();

        if (isFormValid()) {
            try {
                const response = await apiPatchDetailProduct('caracteristicas', id, data);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu caraceterística ha sido editada exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al editar caraceterística");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al editar caraceterística',
                    text: 'Intentelo nuevamente más tarde',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Editar Característica"
                buttonTitle="Guardar"
                handleClick={handleSubmit}
                size="md"
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="producto_nombre" className="form-label">
                                            Producto:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="producto_nombre"
                                            value={product.nombre}
                                            disabled
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="nombre" className="form-label">
                                            Nombre de la Característica: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            value={data.nombre}
                                            onChange={handleFormChange}
                                            placeholder="Color"
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="valor" className="form-label">
                                            Valor: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="valor"
                                            name="valor"
                                            value={data.valor}
                                            onChange={handleFormChange}
                                            placeholder="Rojo"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default EditProdFeat;