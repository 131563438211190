import { auth_types } from './auth.context'

const AuthReducer = (state, action) => {
    switch (action.type) {
        case auth_types.LOGIN_SUCCESS:
            localStorage.setItem('tokenUser', action.payload.token);
            // TODO: Leer desde el BE
            localStorage.setItem('idCompanyUser', 1);

            return {
                ...state,
                isAuth: true,
                isUserLoading: false,
                token: action.payload.token,
                user: {
                    id: action.payload.userId,
                }
            }
        case auth_types.LOGIN_ERROR:
            localStorage.removeItem('tokenUser');
            return {
                ...state,
                token: null,
                isAuth: false,
                isUserLoading: false,
                errorMessage: action.payload?.msg,
            }
        case auth_types.GET_USER:
            return {
                ...state,
                isAuth: true,
                isUserLoading: false,
                user: {
                    id: action.payload.userId,
                }
            }
        case auth_types.LOGOUT:
            localStorage.removeItem('tokenUser');
            localStorage.removeItem('idCompanyUser');
            return {
                ...state,
                token: null,
                isAuth: false,
                isUserLoading: false,
            }
        default:
            return {
                ...state,
                isUserLoading: false,
            }
    }
}
export default AuthReducer;