import backendRequest from './requests';
const id_system = process.env.REACT_APP_DB_ID_SYSTEM;
// ---------------- GENERAL ----------------------

/* ---------------------------------------
    Modulos para la conexion con la API
----------------------------------------- */
// Obtener un tipo de menu
export const apiGetMenu = idMenu => (
    backendRequest.get(`/general/menu/${id_system}/${idMenu}`)
)

// Obtener los componentes de una pagina
export const apiGetComponentsPage = idPage => (
    backendRequest.get(`/general/page/${id_system}/${idPage}`)
)
export const apiGetModaslPage = (idPage, typeModal) => (
    backendRequest.get(`/general/modal/${idPage}?id_tipo_modal=${typeModal}`)
)
// Obtener manual de una pagina
export const apiGetManualPage = id => (
    backendRequest.get(`/manual/getManuales?id_pagina=${id}`)
)
// Obtener información de una tabla
export const apiGetReadTable = (tableName, item = null, value = null) => (
    backendRequest.get(`/general/table/${tableName}/?item=${item}&value=${value}`)
);
// Ingreso al sistema
export const apiPostLogIn = (username, password) => {
    const data = {
        username,
        password
    }
    return backendRequest.post('/user/login', data);
}
/* ----------------------------- USUARIOS --------------------------------*/
// Crear nuevo usuario par ingresar
export const apiPostNewUser = (data) => (
    backendRequest.post('/user/new', data)
)
// Obtener informacion del usuario por ID
export const apiGetUserById = (id) => (
    backendRequest.get(`/user/getById?id_usuario=${id}`)
)
// Editar informacion del usuario
export const apiPatchUser = (id, data) => (
    backendRequest.patch(`/user/editById/${id}`, data)
)
// Obtener todos los usuarios de una empresa
export const apiGetAllUsers = () => (
    backendRequest.get(`/user/userEmp`)
)
// Obtener informacion del usuario con token (enviado en header)
export const apiGetUserToken = () => {
    return backendRequest.get(`/user/getByToken`);
}

//Obtiene las notificaciones del sistema
export const apiGetNotifications = (params) => {
    return backendRequest.get(`/notificacion/getNotificaciones${params}`);
}

// Obtener roles de usuario
export const apiGetRoles = () => {
    return backendRequest.get('/user/getRoles');
}

// Nuev rol
export const apiPostNewRole = (data) => (
    backendRequest.post('/user/newRole', data)
)
// Editar rol
export const apiPatchRole = (id, data) => (
    backendRequest.patch(`/user/editRole/${id}`, data)
)

// traer Rol Por id
export const apiGetRoleById = (id) => (
    backendRequest.get(`/user/getRole/${id}`)
)
// Delete role
export const apiDeleteRole = (id) => (
    backendRequest.delete(`/user/deleteRole/${id}`)
)