import { Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import {apiGetAllStaff} from '../../common/api-invent';
export const FormParams = ({form, handleChange}) => {
    const [staffs, setStaffs] = useState([]);
    useEffect(() => {
        const loadData = async () => {
        try {
            // Solicitar personal
            let reqStaffs = await apiGetAllStaff();
            setStaffs([...reqStaffs.data.content]);
        } catch (e) {
            console.log(`Ocurrio un error:\n${e}`)
            Swal.fire({
                icon: 'error',
                title: 'Error al cargar datos, intentelo despúes',
                confirmButtonText: 'Aceptar',
            });
        }
        }
        loadData();
    }, [])
    return(
        <Row>
            <Col xs = "12" md = "2" style={{marginBottom: '20px'}}>
            <label htmlFor="fecha_inicio" className ="form-label">
                Fecha Inicio: *
            </label>
            <input
                type="date"
                className="form-control"
                id="fecha_inicio"
                name="fecha_inicio"
                value={form.fecha_inicio}
                onChange={handleChange}
            />
            </Col>
            <Col xs = "12" md = "2">
            <label htmlFor="fecha_fin" className ="form-label">
                Fecha Término: *
            </label>
            <input
                type="date"
                className="form-control"
                id="fecha_fin"
                name="fecha_fin"
                value={form.fecha_fin}
                onChange={handleChange}
            />
            </Col>
            <Col xs = "12" md = "3" style={{marginBottom: '20px'}}>
            <label htmlFor="operador" className ="form-label">
                Operador:
            </label>
            <select 
                className="form-select"
                name="operador"
                id="operador"
                value={form.operador}
                onChange={handleChange}
            >
                <option default value="0">Todos los operadores</option>
                {
                staffs.map(({ id_personal, nombre }, index) => (
                    <option value={id_personal} key={index}>
                        {nombre}
                    </option>
                ))
                }
            </select>
            </Col>
            <Col xs = "12" md = "3" style={{marginBottom: '20px'}}>
            <label htmlFor="turno" className ="form-label">
                Turno:
            </label>
            <select 
                name="turno" 
                id="turno" 
                className='form-select'
                value={form.turno}
                onChange={handleChange}
            >
                <option default value="0">Seleccione un turno</option>
                <option value="manana">7:00 - 15:00</option>
                <option value="tarde">15:00 - 23:00</option>
                <option value="noche">23:00 - 7:00</option>
            </select>
            </Col>
            <Col xs = "12" md = "2" style={{marginBottom: '20px'}}>
            <label htmlFor="cdi" className ="form-label">
                CDI:
            </label>
            <select 
                name="cdi" 
                id="cdi" 
                className='form-select'
                value={form.cdi}
                onChange={handleChange}
            >
                <option default value="0">Seleccione un CDI</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
            </select>
            </Col>
        </Row>
    )
}