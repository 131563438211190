import { useState, useEffect } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Conexion Api
import {
    apiGetStores,
    apiPostNewReportScrap,
} from '../../../common/api-invent';

const ReportScrap = ({ show, setShow }) => {
    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [stores, setStores] = useState([]);

    // Hook para formulario
    const [data, setData] = useState({
        id_almacen: '0',
        format: 'excel',
    });

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Informacion de proveedores
                const reqStores = await apiGetStores();
                setStores([...reqStores.data.content]);
                setLoading(false);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData();
        return () => { }
    }, [setLoading, show]);

    // Llena datos con informacion del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.id_almacen === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere almacen',
                show: true
            });
            return false;
        }
        if (data.format === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere formato',
                show: true
            });
            return false;
        }
        return true;
    }

    // Generar reporte
    const handleSubmit = async e => {
        e.preventDefault();

        if (isFormValid()) {
            try {
                setLoading(true);
                const response = await apiPostNewReportScrap(data);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu reporte ha sido generado exitosamente",
                        icon: "success",
                        confirmButtonText: 'Aceptar'
                    });
                    // Limpiar datos
                    setData({
                        id_almacen: '0',
                        format: 'excel'
                    });
                    // Descargar / mostrar reporte
                    window.open(response.data.content, '_blank');
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al agregar reporte");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al generar reporte',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="md"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Generar reporte de Pedaceria"
                buttonTitle="Generar"
                handleClick={handleSubmit}
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="50px"
                            marginTop="50px"
                        />
                        : <Form>
                            <Row>
                                <Col xs md="6">
                                    <div className="form-group">
                                        <label htmlFor="id_almacen" className="form-label">
                                            Almacen: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_almacen"
                                            id="id_almacen"
                                            value={data.id_almacen}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un almacén</option>
                                            {
                                                stores.map(({ glb_id_almacen, glb_nombre }, index) => (
                                                    <option value={glb_id_almacen} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default ReportScrap;