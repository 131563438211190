import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
    Button,
} from 'react-bootstrap';
import Swal from 'sweetalert2';

// Componentes Generales
import { OverlayTooltip } from '../../common.component';
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
import SelectItemContext from "../../../context/selectItem/selectItem.context";
// Conexion Api
import {
    apiGetStores,
    apiGetAllProducts,
    // apiGetUnitFromMagnitud,
    apiPostNewScrap,
    apiGetMeasureFromMagnitud,
} from '../../../common/api-invent';

const KUnitMeters = 1;

const AddScrap = ({ show, setShow }) => {
    const {
        selectedStore,
        onSelectStore,
        selectedProduct,
        onSelectProduct,
    } = useContext(SelectItemContext);
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 29;

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [stores, setStores] = useState([]);
    const [products, setProducts] = useState([]);
    const [measures, setMeasures] = useState([]);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        id_almacen: "0",
        id_producto: "0",
        propiedades: []
    });

    // Carga de datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Solicitud de almacenes
                const reqStores = await apiGetStores();
                setStores([...reqStores.data.content]);
                // Solicitud de productos
                const reqProducts = await apiGetAllProducts();
                setProducts([...reqProducts.data.content]);
                // Solicitud de unidades
                const reqMeasures = await apiGetMeasureFromMagnitud(KUnitMeters);  // 1 => "m" => "Largo, Ancho y Profundo"
                setMeasures([...reqMeasures.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setStores([]);
            setProducts([]);
        }
    }, [setStores, setLoading, show]);

    // Seleccionar almacen con variable global
    useEffect(() => {
        setData(d => ({
            ...d,
            id_almacen: selectedStore
        }));
    }, [selectedStore])

    // Manejar cambio de datos
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    // Cambio en valores de fila de producto
    const handleChangeRow = e => {
        const index = Number(e.target.getAttribute('index'));
        // Cambiar id de Producto Seleccionado
        const auxProps = [...data.propiedades];
        auxProps[index] = {
            ...auxProps[index],
            [e.target.name]: e.target.value,
        };
        setData({
            ...data,
            propiedades: auxProps,
        })
    }
    // Eliminar fila de producto
    const handleDeleteRow = e => {
        const index = Number(e.currentTarget.getAttribute('index'));
        // Objeto auxiliar para guardar valores
        const auxProps = [...data.propiedades];
        // Eliminar valor
        auxProps.splice(index, 1);
        // Guardar valores
        setData({
            ...data,
            propiedades: auxProps,
        })
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (Number(data.id_almacen) === 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere almacen',
                show: true
            });
            return false;
        }
        if (Number(data.id_producto) === 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere producto',
                show: true
            });
            return false;
        }
        if (Number(data.id_unidad) === 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere unidad',
                show: true
            });
            return false;
        }
        if (!data.propiedades.every(e => e.valor !== "")) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere valor',
                show: true
            });
            return false;
        }
        if (!data.propiedades.every(e => Number(e.valor) > 0)) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Solo numeros mayores a cero en valor',
                show: true
            });
            return false;
        }
        if (!data.propiedades.every(e => e.id_medida !== "0")) {
            setAlert({
                title: 'Formulario erroneo',
                message: 'Se requiere medida',
                show: true
            });
            return false;
        }
        return true;
    }
    // Enviar información del formulario
    const _submitForm = async (closeModal = true) => {
        if (isFormValid()) {
            try {
                const response = await apiPostNewScrap(data);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "La pedaceria ha sido agregado exitosamente",
                        icon: "success",
                        confirmButtonText: 'Aceptar'
                    });
                    // Carga de componente
                    onReloadComponent(idReloadComp);
                    // Modificar contenedor de almacenes
                    onSelectStore(data.id_almacen);
                    onSelectProduct(data.id_producto);
                    // Limpiar datos
                    setData({
                        id_almacen: selectedStore,
                        id_producto: selectedProduct,
                        propiedades: []
                    });
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar rack");
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error al agregar pedaceria",
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: "Aceptar",
                });
            }
        }
    }

    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _submitForm(true);
    }

    // Guardar y limpiar
    const handleSave = async e => {
        e.preventDefault();
        // Envio de información
        await _submitForm(false);
    }


    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Agregar Pedaceria"
                buttonTitle="Agregar y cerrar"
                handleClick={handleSaveClose}
                auxButtonTitle="Agregar y guardar"
                handleAuxButton={handleSave}
                size="md"
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_almacen" className="form-label">
                                            Almacen: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_almacen"
                                            id="id_almacen"
                                            value={data.id_almacen}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un almacen</option>
                                            {
                                                stores.map(({ glb_id_almacen, glb_nombre }, index) => (
                                                    <option value={glb_id_almacen} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_producto" className="form-label">
                                            Producto: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_producto"
                                            id="id_producto"
                                            value={data.id_producto}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un producto</option>
                                            {
                                                products.map(({ pr_id_producto, pr_nombre, codigo_proveedor }, index) => (
                                                    <option value={pr_id_producto} key={index}>
                                                        {pr_nombre} - {codigo_proveedor}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            {
                                data.propiedades.map((element, index) => (
                                    <ItemRowPropierty
                                        index={index}
                                        key={index}
                                        measures={measures}
                                        onChangeRow={handleChangeRow}
                                        onDeleteRow={handleDeleteRow}
                                        formIsShowed={show}
                                        rowData={element}
                                    />
                                ))
                            }
                            <Row style={{ marginTop: "10px" }}>
                                <Col>
                                    <Button
                                        variant="info"
                                        className='waves-effect waves-light mb-20'
                                        disabled={data.id_almacen === "0"}
                                        onClick={() => {
                                            setData({
                                                ...data,
                                                propiedades: [
                                                    ...data.propiedades,
                                                    {
                                                        valor: "0",
                                                        id_medida: "0",
                                                        id_unidad: KUnitMeters,
                                                    }
                                                ]
                                            })
                                        }}
                                    >
                                        <i className='si si-plus' /> Agregar Medida
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

const ItemRowPropierty = ({
    index,
    measures,
    rowData,
    onChangeRow,
    onDeleteRow,
}) => {
    return (
        <Row>
            <Col xs="3">
                <div className="form-group">
                    <label htmlFor="valor" className="form-label">
                        Valor: *
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        id="valor"
                        name="valor"
                        value={rowData.valor}
                        onChange={onChangeRow}
                        placeholder="30"
                        index={index}
                        min="1"
                        
                    />
                </div>
            </Col>
            <Col xs="5">
                <div className="form-group">
                    <label htmlFor="id_medida" className="form-label">
                        Medida: *
                    </label>
                    <select
                        index={index}
                        className="form-select"
                        name="id_medida"
                        id="id_medida"
                        value={rowData.id_medida}
                        onChange={onChangeRow}
                    >
                        <option value="0">Seleccione una medida</option>
                        {
                            measures.map(({ mag_id_medida, mag_nombre }, index) => (
                                <option value={mag_id_medida} key={index}>
                                    {mag_nombre}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </Col>
            <Col xs="2">
                <div className="form-group">
                    <label htmlFor="read_Unit" className="form-label">
                        Unidad:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="read_Unit"
                        name="read_Unit"
                        value="m"
                        readOnly={true}
                    />
                </div>
            </Col>
            <Col xs="1" style={{ paddingTop: "30px" }}>
                <OverlayTooltip
                    placement="top"
                    tooltip="Eliminar"
                >
                    <Button
                        size="sm"
                        variant="danger"
                        className='waves-effect waves-light'
                        onClick={onDeleteRow}
                        index={index}
                    >
                        <i className='si si-trash' />
                    </Button>
                </OverlayTooltip>
            </Col>
        </Row>
    )
}

export default AddScrap;
