import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
import SelectItemContext from "../../../context/selectItem/selectItem.context";
// Conexion Api
import {
    apiPostNewRack,
    apiGetStores
} from '../../../common/api-invent';

const AddRackModal = ({ show, setShow }) => {
    const {
        selectedStore,
        onSelectStore,
        onSelectRack,
    } = useContext(SelectItemContext);
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 1;

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [stores, setStores] = useState([]);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        id_almacen: '0',
        nombre: '',
        codigo_rack: '',
        fila: '',
        columna: ''
    });

    // Carga de datos
    useEffect(() => {
        const loadRack = async () => {
            setLoading(true);
            try {
                const reqSto = await apiGetStores();
                setStores([...reqSto.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadRack()
        // Funcion limpieza
        return () => {
            setStores([]);
            setLoading(false);
        }
    }, [setStores, setLoading, show]);

    // Cambiar id de almacen seleccionado
    useEffect(() => {
        setData(data => ({ ...data, id_almacen: selectedStore }));
    }, [selectedStore]);


    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.id_almacen === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere almacen',
                show: true
            });
            return false;
        }
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre',
                show: true
            });
            return false;
        }
        if (data.codigo_rack === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere del código',
                show: true
            });
            return false;
        }
        if (data.fila === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere una fila',
                show: true
            });
            return false;
        }
        if (data.columna === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere una columna',
                show: true
            });
            return false;
        }
        if (data.fila === '0') {
            setAlert({
                title: 'Datos erroneos',
                message: 'La fila no puede ser 0',
                show: true
            });
            return false;
        }
        if (data.columna === '0') {
            setAlert({
                title: 'Datos erroneos',
                message: 'La columna no puede ser 0',
                show: true
            });
            return false;
        }

        return true;
    }

    // Enviar información del formulario
    const _submitForm = async (closeModal = true) => {
        if (isFormValid()) {
            try {
                const response = await apiPostNewRack(data, data.id_almacen);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu rack ha sido agregado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Carga de componente
                    onReloadComponent(idReloadComp);
                    // Modificar contenedor de almacenes
                    onSelectStore(data.id_almacen);
                    onSelectRack(0);
                    // Limpiar datos
                    setData({
                        id_almacen: selectedStore,
                        nombre: '',
                        codigo_rack: '',
                        fila: '',
                        columna: ''
                    });
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar rack");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nuevo rack',
                    text: error.response.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _submitForm(true);
    }

    // Guardar y limpiar
    const handleSave = async e => {
        e.preventDefault();
        // Envio de información
        await _submitForm(false);
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Agregar Rack"
                buttonTitle="Agregar y cerrar"
                handleClick={handleSaveClose}
                auxButtonTitle="Agregar y guardar"
                handleAuxButton={handleSave}
                size="md"
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_almacen" className="form-label">
                                            Almacen: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_almacen"
                                            id="id_almacen"
                                            value={data.id_almacen}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un almacen</option>
                                            {
                                                stores.map(({ glb_id_almacen, glb_nombre }, index) => (
                                                    <option value={glb_id_almacen} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="nombre" className="form-label">
                                            Nombre: *
                                        </label>
                                        <input type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            value={data.nombre}
                                            onChange={handleFormChange}
                                            placeholder="Rack1"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="codigo_rack" className="form-label">
                                            Código Rack: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="codigo_rack"
                                            name="codigo_rack"
                                            value={data.codigo_rack}
                                            onChange={handleFormChange}
                                            placeholder="R-0001"
                                        />
                                    </div>
                                </Col>
                                <Col md="3" xs="6">
                                    <div className="form-group">
                                        <label htmlFor="fila" className="form-label">
                                            Fila: *
                                        </label>
                                        <input
                                            value={data.fila}
                                            onChange={handleFormChange}
                                            type="number"
                                            className="form-control"
                                            name="fila"
                                            id="fila"
                                            min="1"
                                            step="1"
                                            placeholder="1"
                                        />
                                    </div>
                                </Col>
                                <Col md="3" xs="6">
                                    <div className="form-group">
                                        <label htmlFor="columna" className="form-label">
                                            Columna: *
                                        </label>
                                        <input
                                            value={data.columna}
                                            onChange={handleFormChange}
                                            type="number"
                                            className="form-control"
                                            name="columna"
                                            id="columna"
                                            min="1"
                                            max="13"
                                            step="1"
                                            placeholder="1"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default AddRackModal;