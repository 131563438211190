import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
    Button,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import { OverlayTooltip } from '../../common.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Context
import SelectItemContext from '../../../context/selectItem/selectItem.context';
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import {
    apiGetAllBadges,
    apiGetAllProviders,
    apiGetAllProductsByProv,
    apiPostListProducts
} from '../../../common/api-invent';

// ---------------------------------------------------------
// ------------------ Item de Fila para nuevo producto
const ItemRowProduct = ({ index, selectedProduct, price, products, onChangeRow, onDeleteRow }) => {
    return (
        <Row>
            <Col xs="12" md="6">
                <div className="form-group">
                    <label htmlFor="id_producto" className="form-label">
                        Producto: *
                    </label>
                    <select
                        className="form-select"
                        name="id_producto"
                        id="id_producto"
                        value={selectedProduct}
                        onChange={onChangeRow}
                        index={index}
                    >
                        <option value="0">Seleccione un producto</option>
                        {
                            products.map(({ glb_id_producto, glb_nombre, glb_nombre_prod_proveedor }, index) => (
                                <option value={glb_id_producto} key={index}>
                                    {`${glb_nombre} - ${glb_nombre_prod_proveedor}`}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </Col>
            <Col xs="9" md="4">
                <div className="form-group">
                    <label htmlFor="precio_x_caja" className="form-label">
                        Precio por Embalaje: *
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        name="precio_x_caja"
                        id="precio_x_caja"
                        index={index}
                        onChange={onChangeRow}
                        value={price}
                        min="1"
                    />
                </div>
            </Col>
            <Col xs="3" md="2" style={{ paddingTop: "30px" }}>
                <OverlayTooltip
                    placement="top"
                    tooltip="Eliminar"
                >
                    <Button
                        size="sm"
                        variant="danger"
                        className='waves-effect waves-light'
                        onClick={onDeleteRow}
                        index={index}
                    >
                        <i className='si si-trash' />
                    </Button>
                </OverlayTooltip>
            </Col>
        </Row>
    )
}

// ------------------------------------------------------
// ---------------- AGREGAR LISTA PRECIO ----------------
const AddListPricesProds = ({ show, setShow }) => {
    const { selectedProvider, onSelectListPrice, onSelectProvider } = useContext(SelectItemContext);
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 17;

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [providers, setProviders] = useState([]);
    const [prodsProviders, setProdsProviders] = useState([]);
    const [badges, setBadges] = useState([]);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        id_proveedor: '0',
        fecha_recepcion: '',
        fecha_validez: '',
        codigo_moneda_sat: '',
        productos: [
            {
                id_producto: '0',
                precio_x_caja: '0'
            }
        ],
    });
    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Solicitar info de proveedores
                const reqProvs = await apiGetAllProviders(1);
                setProviders([...reqProvs.data.content]);
                // Solicitar info de proveedores
                const reqBadges = await apiGetAllBadges();
                setBadges([...reqBadges.data.content]);
                setLoading(false);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setProviders([]);
            setLoading(false);
        }
    }, [setProviders, setLoading, show]);

    // Traer productos de proveedor
    useEffect(() => {
        const loadProducts = async () => {
            setLoading(true);
            try {
                const reqProds = await apiGetAllProductsByProv(data.id_proveedor);
                setProdsProviders([...reqProds.data.content]);
                setLoading(false);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        if (show)
            loadProducts();
    }, [data.id_proveedor, setProdsProviders, setLoading, show]);

    // Cambiar id de producto seleccionado
    useEffect(() => {
        setData(data => ({ ...data, id_proveedor: selectedProvider }));
    }, [selectedProvider]);

    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    // Cambio en valores de fila de producto
    const handleChangeRow = e => {
        const index = Number(e.target.getAttribute('index'));
        // Cambiar id de Producto Seleccionado
        const auxProds = [...data.productos];
        auxProds[index] = {
            ...auxProds[index],
            [e.target.name]: e.target.value,
        };
        setData({
            ...data,
            productos: auxProds,
        })
    }

    // Eliminar fila de producto
    const handleDeleteRow = e => {
        const index = Number(e.currentTarget.getAttribute('index'));
        // Objeto auxiliar para guardar valores
        const auxProds = [...data.productos];
        // Eliminar valor
        auxProds.splice(index, 1);
        // Guardar valores
        setData({
            ...data,
            productos: auxProds,
        })
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.id_proveedor === '0' || data.id_proveedor === 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere proveedor',
                show: true
            });
            return false;
        }
        if (data.fecha_recepcion === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere fecha de recepción',
                show: true
            });
            return false;
        }
        if (data.codigo_moneda_sat === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere divisa',
                show: true
            });
            return false;
        }
        if (data.fecha_validez === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere fecha de validez',
                show: true
            });
            return false;
        }
        if (!data.productos.every((element) => (element.id_producto !== '0' && element.precio_x_caja !== '0'))) {
            setAlert({
                title: 'Formulario Erroneo',
                message: 'Se requiere producto y precio en todas las filas',
                show: true
            });
            return false;
        }
        return true;
    }

    // Insertar nueva caja
    const handleSubmit = async event => {
        event.preventDefault();

        if (isFormValid()) {
            try {
                // Agregar moneda a productos
                const sendData = {
                    ...data,
                    productos: data.productos.map(product => (
                        { ...product, moneda_codigo_sat: data.codigo_moneda_sat }
                    ))
                };
                const response = await apiPostListProducts(data.id_proveedor, sendData);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu lista ha sido agregado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Limpiar datos
                    setData({
                        id_proveedor: '0',
                        fecha_recepcion: '',
                        fecha_validez: '',
                        productos: [
                            {
                                id_producto: '0',
                                precio_x_caja: '0'
                            }
                        ],
                    });
                    // Seleccionar variables gloables
                    onSelectProvider(data.id_proveedor);
                    onSelectListPrice(0);
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al agregar lista");
                }
            } catch (e) {
                console.warn(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar lista',
                    text: e.response.data.msg ?? 'Intentelo más tarde',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Agregar Lista"
                buttonTitle="Agregar"
                handleClick={handleSubmit}
                size="md"
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_proveedor" className="form-label">
                                            Proveedor: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_proveedor"
                                            id="id_proveedor"
                                            value={data.id_proveedor}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un proveedor</option>
                                            {
                                                providers.map(({ id_proveedor, nombre }, index) => (
                                                    <option value={id_proveedor} key={index}>
                                                        {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="codigo_moneda_sat" className="form-label">
                                            Divisa: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="codigo_moneda_sat"
                                            id="codigo_moneda_sat"
                                            value={data.codigo_moneda_sat}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione una divisa</option>
                                            {
                                                badges.map(({ codigo_sat, nombre }, index) => (
                                                    <option value={codigo_sat} key={index}>
                                                        {codigo_sat} - {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="fecha_recepcion" className="form-label">
                                            Fecha Recepción: *
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="fecha_recepcion"
                                            name="fecha_recepcion"
                                            value={data.fecha_recepcion}
                                            onChange={handleFormChange}
                                            placeholder="Color"
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="fecha_validez" className="form-label">
                                            Fecha Validez: *
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="fecha_validez"
                                            name="fecha_validez"
                                            value={data.fecha_validez}
                                            onChange={handleFormChange}
                                            placeholder="Rojo"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            {
                                data.productos.map((element, index) => (
                                    <ItemRowProduct
                                        index={index}
                                        key={index}
                                        products={prodsProviders}
                                        selectedProduct={element.id_producto}
                                        price={element.precio_x_caja}
                                        onChangeRow={handleChangeRow}
                                        onDeleteRow={handleDeleteRow}
                                    />
                                ))
                            }
                            <Row style={{ marginTop: "10px" }}>
                                <Col>
                                    <Button
                                        variant="info"
                                        className='waves-effect waves-light'
                                        onClick={() => {
                                            setData({
                                                ...data,
                                                productos: [
                                                    ...data.productos,
                                                    {
                                                        id_producto: '0',
                                                        precio_x_caja: '0'
                                                    }
                                                ]
                                            })
                                        }}
                                    >
                                        <i className='si si-plus' /> Agregar Producto
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default AddListPricesProds;