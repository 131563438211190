import { useEffect, useState } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
// Componentes Bootstrap
import {
  ButtonGroup,
  Button,
  Form
} from 'react-bootstrap';
// Componentes Generales
import { OverlayTooltip } from '../common.component';
// Colores
import { ColorPrimary } from '../../utilities/pallete.const';
// CSS
import './tableData.styled.css';

// Cambiar idioma en opciones
const languageOptions = {
  length_menu: "Mostrar _MENU_ registros por página",
  filter: "Buscar en registros...",
  info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
  pagination: {
    first: "Primero",
    previous: "Anterior",
    next: "Siguiente",
    last: "Último"
  }
};
// Opciones personalizadas
const config = {
  page_size: 10,
  length_menu: [10, 20, 50],
  show_filter: true,
  show_pagination: true,
  show_first: false,
  show_last: false,
  language: languageOptions
}
//---------------------------------------------------------------------
const EditButton = ({ editComponent, idElement, editProps, customIconOverlay, customIconEdit }) => {
  const [show, setShow] = useState(false);
  const AuxComponent = editComponent;
  return (
    <>
      <OverlayTooltip
        placement="top"
        tooltip={customIconOverlay}
      >
        <Button
          onClick={() => { setShow(true) }}
          variant="info"
          className='waves-effect waves-light btnMas'
        >
          <i className={customIconEdit + " btnEdit"}/>
        </Button>
      </OverlayTooltip>
      <AuxComponent
        show={show}
        setShow={setShow}
        id={idElement}
        {...editProps}
      />
    </>
  )
}
// Documentacion PropTypes
EditButton.propTypes = {
  editComponent: PropTypes.elementType.isRequired,
  idElement: PropTypes.number.isRequired,
  editProps: PropTypes.object,
  onCustomActionEdit: PropTypes.func,
  customIconOverlay: PropTypes.string,
  customIconEdit: PropTypes.string,
}
// DefaultProps
EditButton.defaultProps = {
  editProps: {},
  onCustomActionEdit: id => { },
  customIconOverlay: 'Editar',
  customIconEdit: 'si si-pencil',
}

//---------------------------------------------------------------------
// Generacion de componente
const TableData = ({
  isSelectable,
  isCountable,
  data,
  columns,
  actions,
  deleteTitle,
  deleteText,
  deleteFunction,
  editComponent,
  editProps,
  customIcon,
  customTextOverlay,
  onCustomAction,
  selectedRows,
  setSelectedRows,
}) => {
  const [titles, setTitles] = useState([]);
  const [dataContent, setDataContent] = useState([]);

  // Inicializar hook para manejar seleccion
  useEffect(() => {
    if (isSelectable) {
      const auxObj = {};
      data.forEach(item => {
        auxObj[item.id] = false;
      });
      setSelectedRows({ ...auxObj });
    }
  }, [isSelectable, data, setSelectedRows])

  // Modificar informacion para agregar botones de "acciones"
  useEffect(() => {
    // Funcion para eliminar un elemento
    const handleDeleteElement = async id => {
      const response = await Swal.fire({
        showCancelButton: true,
        showCloseButton: true,
        title: deleteTitle,
        text: deleteText,
        icon: 'warning',
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, regresar',
        cancelButtonColor: ColorPrimary,
        reverseButtons: true
      });
      // Revisar si se ha aceptado la alerta
      if (response.isConfirmed) {
        deleteFunction(id);
      }
    }

    // Funcion para  des / seleccionar todas las filas
    const changeAllRows = (isChecked) => {
      const auxObj = {};
      data.forEach(item => {
        auxObj[item.id] = isChecked;
      });
      setSelectedRows({ ...auxObj });
    }

    setTitles([
      isSelectable
        ? {
          key: "selectable",
          text: <Form.Check
            type="checkbox"
            id="all-items"
            label={""}
            onChange={e => changeAllRows(e.target.checked)}
          />,
          width: "80px"
        }
        : null,
      isCountable
        ? {
          key: "count",
          text: "#",
          width: "50px"
        }
        : null,
      ...columns,
      (actions.length > 0)
        ? {
          key: "actions",
          text: "Acciones",
          width: `${90 + (actions.length * 32)}px`
        }
        : null
    ].filter(c => c !== null));

    // Modificar filas (agregar acciones, contador, etc)
    setDataContent(
      data.map((row, index) => {
        return ({
          selectable: isSelectable
            ? <Form.Check
              type="checkbox"
              id={`item-${index}`}
              label=""
              checked={selectedRows[row.id] ?? false}
              onChange={(e) => {
                setSelectedRows({
                  ...selectedRows,
                  [row.id]: e.target.checked
                })
              }}
            />
            : null,
          count: index + 1,
          ...row,
          actions:
            <ButtonGroup>
              {
                actions.includes('edit')
                  ? <EditButton
                    editComponent={editComponent}
                    idElement={row.id}
                    editProps={editProps}
                  />
                  : <></>
              }
              {
                actions.includes('delete')
                  ? <OverlayTooltip
                    placement="top"
                    tooltip="Eliminar"
                  >
                    <Button
                      onClick={() => { handleDeleteElement(row.id) }}
                      variant="danger"
                      className='waves-effect waves-light'
                    >
                      <i className='si si-trash' />
                    </Button>
                  </OverlayTooltip>
                  : <></>
              }
              {
                actions.includes('extra')
                  ? <OverlayTooltip
                    placement="top"
                    tooltip={customTextOverlay}
                  >
                    <Button
                      onClick={() => { onCustomAction(row.id) }}
                      variant="warning"
                      className='waves-effect waves-light btnMas'
                      id={row.id}
                      index={row.id}
                    >
                      <i className={customIcon + " btnMas"} id={row.id} index={row.id}/>
                    </Button>
                  </OverlayTooltip>
                  : <></>
              }
            </ButtonGroup>
        })
      })
    )

  }, [
    isCountable,
    isSelectable,
    columns,
    data,
    actions,
    deleteText,
    deleteFunction,
    deleteTitle,
    editComponent,
    editProps,
    customIcon,
    customTextOverlay,
    onCustomAction,
    selectedRows,
    setSelectedRows
  ]);

  return (
    <ReactDatatable
    width={450} height={450}
      className="table table-striped"
      config={config}
      records={dataContent}
      columns={titles}
    />
  );
}

// Documentacio PropTypes
TableData.propTypes = {
  isCountable: PropTypes.bool,
  isSelectable: PropTypes.bool,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  actions: PropTypes.array,
  deleteTitle: PropTypes.string,
  deleteText: PropTypes.string,
  deleteFunction: PropTypes.func,
  editComponent: PropTypes.elementType,
  editProps: PropTypes.object,
  customTextOverlay: PropTypes.string,
  customIcon: PropTypes.string,
  onCustomAction: PropTypes.func,
  selectedRows: PropTypes.object,
  setSelectedRows: PropTypes.func
}
TableData.defaultProps = {
  isCountable: false,
  isSelectable: false,
  actions: [],
  deleteTitle: '!Cuidado!',
  deleteText: '¿Esta seguro que desea eliminar al elemento?',
  deleteFunction: e => { },
  customTextOverlay: 'Más',
  customIcon: 'si si-plus',
  onCustomAction: id => { },
  selectedRows: {},
  setSelectedRows: () => { },
}
export default TableData;