import { useState } from 'react';
import PropTypes from 'prop-types';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './imageSlider.styled.css';
import Swal from 'sweetalert2';
// Componentes Bootstrap
import {
    Button
} from 'react-bootstrap';
import { OverlayTooltip } from '../common.component';
// Utilidades
import { ColorPrimary } from '../../utilities/pallete.const';

// Boton para editar fotografía
const EditButton = ({ editComponent, idElement }) => {
    const [show, setShow] = useState(false);
    const AuxComponent = editComponent;
    return (
        <>
            <OverlayTooltip
                placement="top"
                tooltip="Editar"
            >
                <Button
                    onClick={() => { setShow(true) }}
                    variant="info"
                    className='waves-effect waves-light'
                >
                    <i className='si si-pencil' />
                </Button>
            </OverlayTooltip>
            <AuxComponent
                show={show}
                setShow={setShow}
                id={idElement}
            />
        </>
    )
}

//---------------------------------------------------------------------
// Generacion de componente
const ImageSlider = ({
    items,
    deleteTitle,
    deleteText,
    deleteFunction,
    editComponent
}) => {
    const handleDeleteElement = async id => {
        const response = await Swal.fire({
            showCancelButton: true,
            showCloseButton: true,
            title: deleteTitle,
            text: deleteText,
            icon: 'warning',
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'No, regresar',
            cancelButtonColor: ColorPrimary,
            reverseButtons: true
        });
        // Revisar si se ha aceptado la alerta
        if (response.isConfirmed) {
            deleteFunction(id);
        }
    }

    return (
        <Carousel
            showStatus={false}
        >
            {
                items.map((element, index) => (
                    <div key={index}>
                        <img src={element.path} alt={element.legend} />
                        <p className="legend">
                            {element.legend}
                        </p>
                        <div className="action left">
                            <EditButton
                                editComponent={editComponent}
                                idElement={element.id} />
                        </div>
                        <div className="action right">
                            <OverlayTooltip
                                placement="top"
                                tooltip="Eliminar"
                            >
                                <Button
                                    onClick={() => { handleDeleteElement(element.id) }}
                                    variant="danger"
                                    className='waves-effect waves-light'
                                >
                                    <i className='si si-trash' />
                                </Button>
                            </OverlayTooltip>

                        </div>
                    </div>
                ))
            }
        </Carousel>
    )
}

// Documentacion con PropTypes
ImageSlider.propTypes = {
    items: PropTypes.array.isRequired,
    deleteTitle: PropTypes.string,
    deleteText: PropTypes.string,
    deleteFunction: PropTypes.func,
    editComponent: PropTypes.elementType
}

ImageSlider.defaultProps = {
    deleteTitle: '!Cuidado!',
    deleteText: '¿Esta seguro que desea eliminar al elemento?',
    deleteFunction: e => { }
}

export default ImageSlider;