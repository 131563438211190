// Librerias
import crypto from 'crypto'
import Swal from 'sweetalert2';

// Formatear moneda
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
export const formatCurrency = (number) => (currencyFormatter.format(Number(number)))

// Hashear text
export const hashText = (text) => {
    const shasum = crypto.createHash('sha256')
    shasum.update(text);
    return shasum.digest('hex');
}

// Validar un email
export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const launchSwal = (icon, title, textButton = 'aceptar') => {
    Swal.fire({
        icon: icon,
        title: title,
        confirmButtonText: textButton,
    });
}