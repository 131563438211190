export const ColorBgDark1 = "#172b4c";
export const ColorBgDark2 = "#0c1a32";
export const ColorBgDark3 = "#081835";

export const ColorDanger = "#fb5ea8";
export const ColorWarning = "#fbc66c";
export const ColorPrimary = "#6cbbfa";

export const ColorBgPrimary = "#1c4c4c";
export const ColorBgSuccess = " #1c4c2e";
export const ColorBgWarning = "#4c4c1c";
export const ColorBgDanger = "#4c2e4c";
export const ColorBgLight = "#2e2e2e";