import { useState, useEffect, useContext } from "react";
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from "sweetalert2";
import { Switch, useCheckboxState } from 'pretty-checkbox-react';
import 'pretty-checkbox';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
import { useTranslation } from "react-i18next";
// Conexion Api
import {
    apiPostNewProvider,
    apiGetTypeProviders
} from '../../../common/api-invent';

const AddProviderModal = ({ show, setShow }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComponent = 4;
    const {t} = useTranslation();
    // Switches de opciones
    const s1 = useCheckboxState();
    const s2 = useCheckboxState();
    // Manejar carga de datos
    const [loading, setLoading] = useState(true);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        nombre: '',
        nombre_razon_social: '',
        rfc: '',
        id_tipo_proveedor: '0',
        cfdi: 0,
        version_cfdi: '',
        pdf_invoice: 0,
    });
    const arrVersCfdi = ['3.3', '4.0'];
    const [providers, setProviders] = useState([]);

    // Cargar informacion
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                const reqPro = await apiGetTypeProviders();
                setProviders([...reqPro.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData();
        return () => { }
    }, [show]);

    // Manejo de estados de switch
    useEffect(() => {
        setData(d => ({
            ...d,
            cfdi: Number(s1.state),
            pdf_invoice: Number(s2.state),
            version_cfdi: ''
        }));
    }, [s1.state, s2.state]);

    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre',
                show: true
            });
            return false;
        }
        if (data.nombre_razon_social === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere razón social',
                show: true
            });
            return false;
        }
        if (!!data.cfdi && data.version_cfdi === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere versión de cfdi',
                show: true
            });
            return false;
        }
        return true;
    }

    // Boton de creacion
    const handleSubmit = async event => {
        event.preventDefault();

        if (isFormValid()) {
            try {
                const response = await apiPostNewProvider(data);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "El proveedor ha sido agregado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Carga de componente
                    onReloadComponent(idReloadComponent);
                    // Limpiar datos de formulario
                    setData({
                        nombre: '',
                        nombre_razon_social: '',
                        rfc: '',
                        cfdi: 0,
                        version_cfdi: '',
                        pdf_invoice: 0,
                    });
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al agregar nuevo proveedor");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar proveedor',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle={t('agregar')+" "+t('proveedor')}
                buttonTitle={t('agregar')}
                handleClick={handleSubmit}
                size="md"
            >
                {
                    loading
                    ? <Load
                        size={150}
                        marginBottom="10px"
                        marginTop="10px"
                    />
                    : <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md="4" xs="6">
                                <div className="form-group">
                                    <label htmlFor="nombre" className="form-label">
                                        {t('nombre')}: *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="nombre"
                                        name="nombre"
                                        value={data.nombre}
                                        onChange={handleFormChange}
                                        placeholder="Proveedor 1"
                                    />
                                </div>
                            </Col>
                            <Col md="8" xs="12">
                                <div className="form-group">
                                    <label htmlFor="nombre" className="form-label">
                                        {t('razon')} Social: *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="nombre_razon_social"
                                        name="nombre_razon_social"
                                        value={data.nombre_razon_social}
                                        onChange={handleFormChange}
                                        placeholder="Proveedor S.A de C.V"
                                    />
                                </div>
                            </Col>
                            <Col md="6" xs="12">
                                <div className="form-group">
                                    <label htmlFor="nombre" className="form-label">
                                        RFC/Id Fiscal:
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="rfc"
                                        name="rfc"
                                        value={data.rfc}
                                        onChange={handleFormChange}
                                        placeholder="XXXX000000XXX"
                                    />
                                </div>
                            </Col>
                            <Col md="6" xs="12">
                                <div className="form-group">
                                    <label htmlFor="id_tipo_proveedor" className="form-label">
                                        {t('tipo_proveedor')}: *
                                    </label>
                                    <select
                                        className="form-select"
                                        name="id_tipo_proveedor"
                                        id="id_tipo_proveedor"
                                        value={data.id_tipo_proveedor}
                                        onChange={handleFormChange}
                                    >
                                        <option value="0">{t('seleccione')+" "+t('tipo_proveedor')}</option>
                                        {
                                            providers.map(({ id_tipo_proveedor, nombre }, index) => (
                                                <option value={id_tipo_proveedor} key={index}>
                                                    {nombre}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col className="mt-30" md="4">
                                <Switch
                                    color="success"
                                    shape="fill"
                                    bigger
                                    className="form-label"
                                    disabled={s2.state}
                                    {...s1}
                                >
                                    ¿Usa CFDI?
                                </Switch>
                            </Col>
                            <Col md="8">
                                <div className="form-group">
                                    <label htmlFor="version_cfdi" className="form-label">
                                        Versión CFDI:
                                    </label>
                                    <select
                                        className="form-select"
                                        name="version_cfdi"
                                        id="version_cfdi"
                                        value={data.version_cfdi}
                                        onChange={handleFormChange}
                                        disabled={!s1.state}
                                    >
                                        <option value="">{t('seleccione')} una versión</option>
                                        {
                                            arrVersCfdi.map((element, index) => (
                                                <option value={element} key={index}>
                                                    {element}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </Col>
                            <Col className="mt-30" md="4">
                                <Switch
                                    color="success"
                                    shape="fill"
                                    bigger
                                    className="form-label"
                                    disabled={s1.state}
                                    {...s2}
                                >
                                    {t('factura')} {t('extranjera')} ? (PDF)
                                </Switch>
                            </Col>
                        </Row>
                    </Form>
                }
            </Modals>
        </>
    );
}

export default AddProviderModal;