export const SmallDetailBox2 = ({
  principalData,
  complementPrincipalData,
  principalTitle,
  rigthColumnData,
  rigthColumnTitle,
  leftColumnData,
  leftColumnTitle,
  fontColorPricipalData,
  fontColorPricipalTitle,
  fontColorDataColumns,
  fontColorTitleColumns,
  backgroundColor
}) => {

  return (
    <div className="box" style={{backgroundColor: `${backgroundColor}`, height: '90%'}}>
      <div className="box-body heading-font d-flex flex-column justify-content-between flex-fill" style={{gap: '10px', height: '100%'}}>
        <div className="bg-bubbles-dark">
          <div style={{ color:`${fontColorPricipalData}`}} className="text-center hover-dark" >
            <div style={{ display: "inline-block", opacity: "0.85" }} className={`fs-1 fw-500`} >{principalData}</div>
            <div style={{ display: "inline-block" }} className={`fs-2 fw-500`} >&nbsp;</div>
            <div style={{ display: "inline-block", opacity: "0.60" }} className={`fs-4 fw-400 `} >{complementPrincipalData}</div>
          </div>
          <div style={{ color:`${fontColorPricipalTitle}`, opacity: "0.75"}} className="text-center" >
            <div style={{ opacity: "0.85" }} className={`fs-2 fw-600`} >{principalTitle}</div>          
          </div>
        </div>
        {
          (rigthColumnData || rigthColumnTitle)
            ?
              <div className="row bg-brick-dark">
                <div className="col-sm-12 col-md-6">
                  <div style={{ color:`${fontColorDataColumns}`}} className="hover-white" >
                    <div className={`fs-7 fw-400`} >{leftColumnData}</div>
                  </div>
                  <div style={{ color:`${fontColorTitleColumns}`}} className="hover-white" >
                    <div style={{ opacity: "0.75"}} className={`fs-11 fw-700`}  >{leftColumnTitle}</div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div style={{ color:`${fontColorDataColumns}`}} className="hover-white" >
                    <div style={{ textAlign: 'end'}} className={`fs-7 fw-400`} >{rigthColumnData}</div>
                  </div>
                  <div style={{ color:`${fontColorTitleColumns}`}} className="hover-white" >
                    <div style={{ textAlign: 'end', opacity: "0.75"}} className={`fs-11 fw-700`}  >{rigthColumnTitle}</div>
                  </div> 
                </div>
              </div>
            : null
        }
      </div>
    </div>
  )
}