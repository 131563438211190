import { Col } from 'react-bootstrap';
import GeneralBox from '../GeneralBox/generalBox.component';
import DonutChart from '../ChartDonut/chartDonut.component';
const colors = ['primary', 'success', 'danger']
export const ChartDonuts = ({data}) => {

    const getLabels = (element) => {
        return (
            <>
                {
                    element.porcentaje ? <p className="intro">{`${element.porcentaje}%`}</p> : null
                }
                {
                    element.costo ? <p className="intro">{`Costo: ${element.costo}`}</p> : null
                }
                {
                   element.cm2 ? <p className="intro">{`cm²: ${element.cm2}`}</p> : null
                }
                {
                   element.cantidad ? <p className="intro">{`Cantidad: ${element.cantidad}`}</p> : null
                } 
            </>
        )

    }
    return (    
        data.map(({items, title, dataKey}, index) => (
            <Col xs='12' md="12" lg="6">
                <GeneralBox 
                styles={{textAlign: 'center', alignItems: 'center'}}>
                    <h3 className={`my-0 fw-500 text-${colors[index]}`}>
                        {title}
                    </h3>
                    {items.length > 0 ? <DonutChart data={items} dataKey={dataKey} activeTooltip={true} getLabels={getLabels} valueLegend={'nombre'} widthTooltip={100}/> : <div>No se encontraron datos</div>}
                </GeneralBox>
            </Col> 
        ))  
    )
}