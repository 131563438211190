import { useState } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, Cell } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#9A26DD', '#FF69B4', '#808080', '#A52A2A', '#D4526E', '#FFFFFF', '#7CFC00', '#FF1493', '#FFD700', '#00FFFF', '#800080', '#FFA500', '#008000', '#FF0000', '#000080', '#FF00FF'];

const ApexChart = ({ data, dataKey, activeTooltip = false, getLabels, valueLegend, widthTooltip = 100, layout = 'vertical', xAxisProps, yAxisProps }) => {
  const [visibleSegments, setVisibleSegments] = useState(
    data.map(() => true)
  );

  const [segmentColors, setSegmentColors] = useState(
    COLORS.slice(0, data.length)
  );

  const toggleSegmentVisibility = (index) => {
    if (
      visibleSegments.filter((segment) => segment).length === 1 &&
      visibleSegments[index]
    ) {
      return;
    }
    const updatedSegments = [...visibleSegments];
    updatedSegments[index] = !updatedSegments[index];
    setVisibleSegments(updatedSegments);

    const updatedColors = COLORS.filter((_, i) => updatedSegments[i]);
    setSegmentColors(updatedColors);
  };

  const getVisibleData = () => {
    return data.filter((_, index) => visibleSegments[index]);
  };

  const handleLegendClick = (entry) => {
    const index = data.findIndex((item) => item[valueLegend] === entry.value);
    toggleSegmentVisibility(index);
  };

  function getIntroOfPage(element) {
    if (getLabels) {
      const label = getLabels(element);
      return label;
    }
    return;
  }

  function CustomTooltip({ payload, active }) {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {getIntroOfPage(payload[0].payload)}
        </div>
      );
    }
    return null;
  }

  return (
    <BarChart width={450} height={450} data={getVisibleData()} layout={layout}>
      <Bar dataKey={dataKey} barSize={50} radius={4} isAnimationActive={true}>
        {getVisibleData().map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={segmentColors[index]}
            onClick={() => toggleSegmentVisibility(index)}
            cursor="pointer"
          />
        ))}
      </Bar>
      {activeTooltip ? (
        <Tooltip cursor={false} wrapperStyle={{ borderRadius: '5px', padding: '5px', width: { widthTooltip }, backgroundColor: 'rgba(0,0,0,0.8)', }} content={<CustomTooltip />} /> ) : null}
      <Legend
        payload={data.map((entry, index) => ({
          value: entry[valueLegend],
          type: 'square',
          id: entry.name,
          color: COLORS[index % COLORS.length],
          active: visibleSegments[index],
        }))}
        onClick={handleLegendClick}
      />
      {xAxisProps && <XAxis {...xAxisProps} />}
      {yAxisProps && <YAxis {...yAxisProps} />}
    </BarChart>
  );
};

ApexChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  activeTooltip: PropTypes.bool,
  getLabels: PropTypes.func,
  valueLegend: PropTypes.string.isRequired,
  widthTooltip: PropTypes.number.isRequired,
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
  xAxisProps: PropTypes.object, // Propiedades para personalizar el eje X
  yAxisProps: PropTypes.object, // Propiedades para personalizar el eje Y
};

export default ApexChart;
