import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
// Hooks Personalizados
import useDashboard from '../hooks/useDashboard';
// Contexto
import SelectItemContext from '../context/selectItem/selectItem.context';
// Componentes generales
import MainHeader from '../components/MainHeader/mainHeader.component';
import MainSidebar from '../components/MainSidebar/mainSidebar.component';
import Footer from '../components/Footer/footer.component';
import Load from '../components/Load/load.component';

// Componente de dashboard
const DashBoardPage = () => {
    const { lastVisitedPage } = useContext(SelectItemContext);
    // Leer URL
    const { idPage } = useParams();
    // Estados Internos
    const [collapseSideBar, setCollapseSideBar] = useState(false);
    // Hook de contenido
    const {
        globalLoading,
        sidebarItems,
        pageLoading,
        pageName,
        componentsPage,
        contentAddButton,
        contentReportButton,
        contentManualButton,
        setActionsHeader,
        isAuth
    } = useDashboard(idPage ?? lastVisitedPage);

    // -------------------------------------------
    // Mientras carga el contenido
    if (globalLoading || !isAuth) {
        return (
            <main className="dark-skin">
                <div className="wrapper d-flex flex-column justify-content-center" style={{
                    height: "100vh",
                }}>
                    <Load
                        size={150}
                    />
                </div>
            </main >
        )
    }
    // -------------------------------------------
    // Obtener componentes o dibujar "loader"
    const contentPage = pageLoading
        ? <Load
            size={150}
            marginTop="20vh"
        />
        : <div className="container-full">
            {/* Titulo de la pagina */}
            <div className="content-header">
                <div className="d-flex align-items-center">
                    <div className="me-auto">
                        <h4 className="page-title">
                            {pageName}
                        </h4>
                    </div>
                </div>
            </div>
            {/* Contenido Principal */}
            <section className="content">
                <div className="row">
                    {/* Componentes */}
                    {componentsPage}
                </div>
            </section>
        </div>;
    return (
        <main
            className={`
            ${collapseSideBar ? 'sidebar-collapse' : 'sidebar-open'}
            dark-skin 
            sidebar-mini 
            theme-primary 
            fixed`}
            style={{
                height: "auto",
                minHeight: "100%"
            }}>
            <div className="wrapper" style={{
                height: "auto",
                minHeight: "100%"
            }}>
                {/* Encabezado Principal */}
                <MainHeader
                    actionsPlusButton={setActionsHeader}
                    contentPlusButton={contentAddButton}
                    contentReportButton={contentReportButton}
                    contentManualButton={contentManualButton}
                    onMenuButton={() => { setCollapseSideBar(!collapseSideBar) }}
                />
                {/* Menu Lateral */}
                <MainSidebar
                    listItems={sidebarItems}
                    isCollapsed={collapseSideBar}
                    idPageActive={
                        typeof (idPage) === 'undefined'
                            ? Number(lastVisitedPage)
                            : Number(idPage)
                    }
                />
                {/* Contenido General */}
                <div className="content-wrapper" style={{ minHeight: "634px" }}>
                    {/* Contenido de la pagina */}
                    {contentPage}
                </div>
                {/* Footer */}
                <Footer />
            </div >
        </main >
    );
}
export default DashBoardPage;