// Dependencias
import PropTypes from 'prop-types';
// Libreria loader
import Loader from 'react-loader-spinner'
// Utilidades
import { ColorDanger } from '../../utilities/pallete.const';

const Load = ({ size, marginTop, marginBottom }) => (
    <div
        style={{ marginTop, marginBottom }}
        className="d-flex justify-content-center">
        <Loader
            type="Rings"
            color={ColorDanger}
            secondaryColor={ColorDanger}
            height={size}
            width={size}
            radius={2}
        />
    </div>
);

// Documentacion con proptypes
Load.propTypes = {
    size: PropTypes.number.isRequired,
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
}

// Default Props
Load.defaultProps = {
    marginTop: "0px",
    marginBottom: "0px"
}

export default Load;