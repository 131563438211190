import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Switch, useCheckboxState } from 'pretty-checkbox-react';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import {
    apiPatchUser,
    apiGetUserById,
    apiGetRoles
} from '../../../common/api-general';
// Utilidades
import {
    validateEmail,
    hashText
} from '../../../utilities/funcs';

const EditUser = ({ show, setShow, id }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 30;
    const s1 = useCheckboxState();
    const { state: s1State, setState: s1SetState } = s1;

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    
    const [providers, setProviders] = useState([]);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        nombres: "",
        apellidos: "",
        email: "",
        username: "",
        id_rol:0,
        notificaciones: 0
    });
    const [dataPassword, setDataPassword] = useState({
        password: "",
        confirmPassword: ""
    })
    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Informacion de proveedores
                const reqPro = await apiGetRoles();
                setProviders([...reqPro.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setProviders([]);
            setLoading(false);
        }
    }, [setProviders, setLoading, show]);
    // Moficacion de campos
    const onInputChange = e => {
        setData({
            ...data,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }
    useEffect(() => {
        setData(data => ({
            ...data,
            notificaciones: Number(s1State),
        }));
    }, [s1State]);

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Informacion del personal
                const reqUser = await apiGetUserById(id);
                setData(data => ({
                    ...data,
                    ...reqUser.data.content,
                }));
                s1SetState(Boolean(Number(reqUser.data.content.notificaciones)));
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setLoading(false);
        }
    }, [setData, setLoading, show, id, s1SetState]);
    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }
    const handlePassChange = e => {
        setDataPassword({
            ...dataPassword,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if(data.id_rol === '')
        {
            setAlert({
                title: 'Error',
                message: 'Debe seleccionar un rol',
                show: true,
            });
            return false;
        }
        if (data.nombres === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre del usuario',
                show: true
            });
            return false;
        }
        if (data.apellidos === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere apellidos del usuario',
                show: true
            });
            return false;
        }
        if (data.email === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere email del usuario',
                show: true
            });
            return false;
        }
        if (!validateEmail(data.email)) {
            setAlert({
                title: 'Formulario erroneo',
                message: 'El email es incorrecto',
                show: true
            });
            return false;
        }
        if (data.username === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre de usuario',
                show: true
            });
            return false;
        }
        if (dataPassword.password !== dataPassword.confirmPassword) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Las contraseñas deben coincidir',
                show: true
            });
            return false;
        }
        return true;
    }

    // Editar personal
    const handleSubmit = async event => {
        event.preventDefault();

        if (isFormValid()) {
            try {
                const sendData = { ...data };
                if (dataPassword.password !== "") {
                    sendData.password = hashText(dataPassword.password);
                }else{
                    sendData.password = null;
                }
                const response = await apiPatchUser(id, sendData);
                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "El usuario ha sido editado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al editar usuario");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al editar usuario',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente más tarde',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Editar Usuario"
                buttonTitle="Guardar"
                handleClick={handleSubmit}
                size="md"
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="nombres" className="form-label">
                                            Nombre(s): *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombres"
                                            name="nombres"
                                            value={data.nombres}
                                            onChange={handleFormChange}
                                            placeholder="Juan Pedro"
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="apellidos" className="form-label">
                                            Apellido(s): *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="apellidos"
                                            name="apellidos"
                                            value={data.apellidos}
                                            onChange={handleFormChange}
                                            placeholder="Santa Cruz"
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="email" className="form-label">
                                            Email: *
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={data.email}
                                            onChange={handleFormChange}
                                            placeholder="usario@mail.com"
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="username" className="form-label">
                                            Nombre de Usuario: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            name="username"
                                            value={data.username}
                                            onChange={handleFormChange}
                                            placeholder="usuario001"
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className='form-group'>
                                        <label htmlFor='id_rol' className='form-label'>
                                            Seleccione el rol del usuario: * 
                                        </label>
                                        <select
                                        className="form-select"
                                        name="id_rol"
                                        id="id_rol"
                                        value={data.id_rol}
                                        onChange={onInputChange}
                                        >
                                            {
                                                providers.map(({ id_rol, rol }, index) => (
                                                    (id_rol === index) 
                                                        ? 
                                                        <option value={id_rol} key={index} selected>
                                                            {rol}
                                                        </option>
                                                        :
                                                        <option value={id_rol} key={index}>
                                                            {rol}
                                                        </option>

                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <hr />
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="password" className="form-label">
                                            Contraseña:
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            placeholder="*******"
                                            value={dataPassword.password}
                                            onChange={handlePassChange}
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword" className="form-label">
                                            Confirme Contraseña:
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            placeholder="*******"
                                            value={dataPassword.confirmPassword}
                                            onChange={handlePassChange}
                                        />
                                    </div>
                                </Col>
                                <Col className="mt-30" md="4">
                                    <Switch
                                        color="success"
                                        shape="fill"
                                        bigger
                                        className="form-label"
                                        {...s1}
                                    >
                                        Recicbir notificacion por correo electronico
                                    </Switch>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default EditUser;