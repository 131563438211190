import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
import SelectItemContext from "../../../context/selectItem/selectItem.context";
// Conexion API
import {
    apiGetAllStaff,
    apiGetStores,
    apiGetAllRacks,
    apiGetLevelsWithRack,
    apiGetPossibleExitsByLevel,
    apiGetEntryById,
    apiGetEntryExtraById,
    apiGetLevelId,
    apiGetRackId,
    apiGetReasonByType,
    apiPostNewExit,
    apiGetUnitFromMagnitud,
    apiGetAllClients,
} from '../../../common/api-invent';


const AddExitByLevel = ({ show, setShow }) => {
    const kDefaultReason = process.env.REACT_APP_DEFAULT_ID_REASON_EXIT;
    // Recarga de componente
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadCompA = 25;
    const idReloadCompB = 26;
    // Cargar con nivel
    const {
        extraParams,
        onDeleteExtraParam,
        selectedLevel,
        onSelectLevel,
    } = useContext(SelectItemContext);

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [stores, setStores] = useState([]);
    const [racks, setRacks] = useState([]);
    const [levels, setLevels] = useState([]);
    const [entries, setEntries] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [reasons, setReasons] = useState([]);
    const [units, setUnits] = useState([]);
    const [clients, setClients] = useState([]);

    // Hook para formulario
    const [data, setData] = useState({
        id_entrada: '0',
        id_personal: '0',
        id_razon_movimiento: kDefaultReason,
        id_cliente: '0',
        cantidad: '0',
        fecha_movimiento: moment().format('YYYY-MM-DD'),
        orden_trabajo: '',
        orden_venta: '',
        id_unidad: '0',
        largo: '0',
        ancho: '0',
        estatus: '0'
    });
    // Datos para nivel
    const [infoPlace, setInfoPlace] = useState({
        id_almacen_ubicacion: '0',
        store: '0',
        rack: '0',
    });
    // Datos para producto
    const [infoProduct, setInfoProduct] = useState({
        producto_nombre: 'Elija un Producto',
        codigo_caja: 'Elija un Producto',
        codigo_tarima: 'Elija un Producto',
        lote: 'Elija un Producto',
        cantidad_actual: 'Elija un Producto',
    });

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Escuchar cambio en id
    useEffect(() => {
        if (selectedLevel !== 0) {
            setShow(true);
            setInfoPlace(current => ({ ...current, id_almacen_ubicacion: selectedLevel }));
        }
    }, [selectedLevel, setShow]);

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de almacenes
                const reqStores = await apiGetStores();
                setStores([...reqStores.data.content]);
                // Solicitar personal
                const reqStaffs = await apiGetAllStaff();
                setStaffs([...reqStaffs.data.content]);
                // Solicitar razones
                const reqReasons = await apiGetReasonByType(2);
                setReasons([...reqReasons.data.content]);
                // Solicitar unidades
                const reqUnits = await apiGetUnitFromMagnitud(1);
                setUnits([...reqUnits.data.content]);
                // Solicitar clientes
                const reqClients = await apiGetAllClients();
                setClients([...reqClients.data.content]);
                console.log("🚀 ~ reqClients.data.content", reqClients.data.content)
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData()
        return () => setLoading(false);
    }, [show, selectedLevel, extraParams]);

    // Mostrar modal con url o nivel seleccionado
    useEffect(() => {
        const loadData = async (idLevel) => {
            try {
                setShow(true);
                setLoading(true);
                // Solicitar datos de lugar
                const reqLevel = await apiGetLevelId(idLevel);
                const idRack = reqLevel.data.content?.id_rack;
                const reqRack = await apiGetRackId(idRack);
                const idStore = reqRack.data.content?.id_almacen;
                // Cargar informacion
                setInfoPlace({
                    store: idStore,
                    rack: idRack,
                    id_almacen_ubicacion: idLevel,
                });
                setLoading(false);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        if (extraParams && extraParams[1] && extraParams[1] !== 0) {
            const idlevelAux = extraParams[1];
            loadData(idlevelAux)
        }
        if (selectedLevel !== 0) {
            loadData(selectedLevel)
        }
    }, [extraParams, selectedLevel, setShow]);
    // Llena el arreglo de datos con los datos del formulario
    const handleDataChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }
    // Cambio en informacion de lugar
    const handleChangeInfoPlace = e => {
        setInfoPlace({
            ...infoPlace,
            [e.target.name]: e.target.value
        });
    }

    // Cargar racks
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de racks
                const reqRacks = await apiGetAllRacks(infoPlace.store);
                setRacks([...reqRacks.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData()
        return () => setLoading(false);
    }, [show, infoPlace.store]);
    // Cargar Niveles
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de racks
                const reqLevels = await apiGetLevelsWithRack(infoPlace.rack);
                setLevels([...reqLevels.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`);
                setLevels([]);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        if (show)
            loadData()
        return () => setLoading(false);
    }, [show, infoPlace.rack]);

    // Cargar Posibles salidas
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de racks
                const reqEntries = await apiGetPossibleExitsByLevel(infoPlace.id_almacen_ubicacion);
                setEntries([...reqEntries.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                setEntries([]);
            } finally {
                setLoading(false);
            }
        }
        if (show && infoPlace.id_almacen_ubicacion !== '0')
            loadData()
        else if (infoPlace.id_almacen_ubicacion === '0')
            setEntries([]);

        return () => setLoading(false);
    }, [show, infoPlace.id_almacen_ubicacion]);
    // Cargar Informacion de producto
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de producto
                const reqEntry = await apiGetEntryById(data.id_entrada);
                const reqEntryExtra = await apiGetEntryExtraById(data.id_entrada);
                setInfoProduct(currentData => ({
                    ...currentData,
                    producto_nombre: reqEntry.data.content.producto,
                    codigo_caja: reqEntry.data.content.codigo_caja,
                    lote: reqEntry.data.content.lote,
                    cantidad_actual: Number(reqEntry.data.content.cantidad_actual ?? 0),
                    codigo_tarima: reqEntryExtra.data.content.codigo_tarima,
                }))
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
            } finally {
                setLoading(false);
            }
        }
        if (show && data.id_entrada !== '0' && data.id_entrada)
            loadData()
        else if (data.id_entrada === '0') {
            setInfoProduct({
                producto_nombre: 'Elija un Producto',
                codigo_caja: 'Elija un Producto',
                codigo_tarima: 'Elija un Producto',
                lote: 'Elija un Producto',
                cantidad_actual: 'Elija un Producto',
            })
        }
        return () => setLoading(false);
    }, [show, data.id_entrada]);

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (infoPlace.id_almacen_ubicacion === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nivel para almacenear',
                show: true
            });
            return false;
        }
        if (data.id_entrada === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere producto',
                show: true
            });
            return false;
        }
        // Comproboar unidad y medidas
        if (data.id_unidad !== '0') {
            if (data.largo === '0') {
                setAlert({
                    title: 'Formulario incompleto',
                    message: 'Se requiere largo',
                    show: true
                });
                return false;
            }
            if (data.ancho === '0') {
                setAlert({
                    title: 'Formulario incompleto',
                    message: 'Se requiere ancho',
                    show: true
                });
                return false;
            }
        }
        // Campos obligatorios
        if (data.id_personal === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere personal',
                show: true
            });
            return false;
        }
        if (data.id_razon_movimiento === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere razón movimiento',
                show: true
            });
            return false;
        }
        if (data.cantidad === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere cantidad',
                show: true
            });
            return false;
        }
        if (Number(data.cantidad) < 0) {
            setAlert({
                title: 'Formulario erroneo',
                message: 'Cantidad debe ser mayor que cero',
                show: true
            });
            return false;
        }
        if (data.orden_trabajo === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere orden de trabajo',
                show: true
            });
            return false;
        }
        if (data.orden_venta === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere orden de venta',
                show: true
            });
            return false;
        }
        if (data.id_cliente === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere cliente',
                show: true
            });
            return false;
        }
        return true;
    }

    // Envio de formulario
    const handleSubmit = async () => {
        if (isFormValid()) {
            try {
                const response = await apiPostNewExit(data);
                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "La salida ha sido agregado exitosamente",
                        icon: "success",
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componentes
                    onReloadComponent(idReloadCompA);
                    onReloadComponent(idReloadCompB);
                    // Limpiar datos
                    setData({
                        id_entrada: '0',
                        id_personal: '0',
                        id_razon_movimiento: kDefaultReason,
                        cantidad: '0',
                        fecha_movimiento: moment().format('YYYY-MM-DD'),
                        orden_trabajo: '',
                        orden_venta: '',
                    });
                    setInfoPlace({
                        id_almacen_ubicacion: '0',
                        store: '0',
                        rack: '0',
                    });
                    setInfoProduct({
                        producto_nombre: 'Elija un Producto',
                        codigo_caja: 'Elija un Producto',
                        codigo_tarima: 'Elija un Producto',
                        lote: 'Elija un Producto',
                        cantidad_actual: 'Elija un Producto',
                    });
                    // Borrar parametro de url
                    onDeleteExtraParam(1);
                } else {
                    throw new Error("Error al agregar salida");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar salida',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                // Cerrar modal
                onSelectLevel(0);
                setIsSubmitLoading(false);
                setShow(false);
            }
        }
    }


    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => {
                    // Borrar parametro de url
                    onDeleteExtraParam(1);
                    setShow(false);
                    onSelectLevel(0);
                }}
                modalTitle="Salida"
                buttonTitle="Guardar Salida"
                handleClick={handleSubmit}
                size="md"
                isButtonLoading={isSubmitLoading}
            >

                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col xs="12">
                                    <div className="form-group">
                                        <label htmlFor="store" className="form-label">
                                            Almacén: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="store"
                                            id="store"
                                            value={infoPlace.store}
                                            onChange={handleChangeInfoPlace}
                                        >
                                            <option value="0">Seleccione un almacen</option>
                                            {
                                                stores.map(({ glb_nombre, glb_id_almacen }, index) => (
                                                    <option value={glb_id_almacen} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12">
                                    <div className="form-group">
                                        <label htmlFor="producto_nombre" className="form-label">
                                            Producto:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="producto_nombre"
                                            name="producto_nombre"
                                            value={infoProduct.producto_nombre}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                                <Col xs="12" md="6">
                                    <div className="form-group">
                                        <label htmlFor="rack" className="form-label">
                                            Rack: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="rack"
                                            id="rack"
                                            value={infoPlace.rack}
                                            onChange={handleChangeInfoPlace}
                                            disabled={infoPlace.store === '0'}
                                        >
                                            <option value="0">Seleccione un rack</option>
                                            {
                                                racks.map(({ nombre, codigo_rack, id_rack }, index) => (
                                                    <option value={id_rack} key={index}>
                                                        {`${nombre} - ${codigo_rack}`}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="12" md="6">
                                    <div className="form-group">
                                        <label htmlFor="id_almacen_ubicacion" className="form-label">
                                            Nivel: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_almacen_ubicacion"
                                            id="id_almacen_ubicacion"
                                            value={infoPlace.id_almacen_ubicacion}
                                            onChange={handleChangeInfoPlace}
                                            disabled={infoPlace.rack === '0'}
                                        >
                                            <option value="0">Seleccione un nivel</option>
                                            {
                                                levels.map(({ nombre, codigo_ubicacion, id_almacen_ubicacion }, index) => (
                                                    <option value={id_almacen_ubicacion} key={index}>
                                                        {`${nombre} - ${codigo_ubicacion}`}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_razon_movimiento" className="form-label">
                                            Razón: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_razon_movimiento"
                                            id="id_razon_movimiento"
                                            value={data.id_razon_movimiento}
                                            onChange={handleDataChange}
                                        >
                                            <option value="0">Seleccione una razón</option>
                                            {
                                                reasons.map(({ id_razon_movimiento, nombre }, index) => (
                                                    <option value={id_razon_movimiento} key={index}>
                                                        {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="12" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_entrada" className="form-label">
                                            Salida de Producto: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_entrada"
                                            id="id_entrada"
                                            value={data.id_entrada}
                                            onChange={handleDataChange}
                                        >
                                            {
                                                entries.length === 0
                                                    ? <option value="0">No hay productos disponibles :(</option>
                                                    : <>
                                                        <option value="0">Seleccione un producto</option>
                                                        {
                                                            entries.map(({ producto, codigo_proveedor, id_entrada }, index) => (
                                                                <option value={id_entrada} key={index}>
                                                                    {`${producto} - ${codigo_proveedor}`}
                                                                </option>
                                                            ))
                                                        }
                                                    </>
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="codigo_caja" className="form-label">
                                            Codigo de Embalaje:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="codigo_caja"
                                            name="codigo_caja"
                                            value={infoProduct.codigo_caja}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="codigo_tarima" className="form-label">
                                            Codigo de Tarima:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="codigo_tarima"
                                            name="codigo_tarima"
                                            value={infoProduct.codigo_tarima}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="lote" className="form-label">
                                            Lote:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lote"
                                            name="lote"
                                            value={infoProduct.lote}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="cantidad_actual" className="form-label">
                                            Cantidad Actual:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cantidad_actual"
                                            name="cantidad_actual"
                                            value={infoProduct.cantidad_actual}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="id_unidad" className="form-label">
                                            Unidad de Medida:
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_unidad"
                                            id="id_unidad"
                                            value={data.id_unidad}
                                            onChange={handleDataChange}
                                        >
                                            <option value="0">Seleccione una unidad de medida</option>
                                            {
                                                units.map(({ mag_id_magnitud, mag_simbolo, mag_nombre }, index) => (
                                                    <option value={mag_id_magnitud} key={index}>
                                                        {mag_simbolo} - {mag_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="id_unidad" className="form-label">
                                            Unidad:
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_unidad"
                                            id="id_unidad"
                                            value={data.id_unidad}
                                            onChange={handleDataChange}
                                        >
                                            <option value="0">Seleccione una unidad</option>
                                            {
                                                units.map(({ mag_id_magnitud, mag_simbolo, mag_nombre }, index) => (
                                                    <option value={mag_id_magnitud} key={index}>
                                                        {mag_simbolo} - {mag_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="largo" className="form-label">
                                            Largo:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="largo"
                                            name="largo"
                                            value={data.largo}
                                            onChange={handleDataChange}

                                        />
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="ancho" className="form-label">
                                            Ancho:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="ancho"
                                            name="ancho"
                                            value={data.ancho}
                                            onChange={handleDataChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="cantidad" className="form-label">
                                            Cantidad: *
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="cantidad"
                                            name="cantidad"
                                            value={data.cantidad}
                                            onChange={handleDataChange}
                                            min="1"
                                        />
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="orden_trabajo" className="form-label">
                                            Orden de Trabajo: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="orden_trabajo"
                                            name="orden_trabajo"
                                            value={data.orden_trabajo}
                                            onChange={handleDataChange}
                                        />
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="orden_venta" className="form-label">
                                            Invoice: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="orden_venta"
                                            name="orden_venta"
                                            value={data.orden_venta}
                                            onChange={handleDataChange}
                                        />
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="id_personal" className="form-label">
                                            Personal: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_personal"
                                            id="id_personal"
                                            value={data.id_personal}
                                            onChange={handleDataChange}
                                        >
                                            <option value="0">Seleccione un personal</option>
                                            {
                                                staffs.map(({ id_personal, nombre }, index) => (
                                                    <option value={id_personal} key={index}>
                                                        {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="6">
                                    <div className="form-group">
                                        <label htmlFor="id_cliente" className="form-label">
                                            Cliente: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_cliente"
                                            id="id_cliente"
                                            value={data.id_cliente}
                                            onChange={handleDataChange}
                                        >
                                            <option value="0">Seleccione un cliente</option>
                                            {
                                                clients.map(({ id_cliente, nombre, rfc }, index) => (
                                                    <option value={id_cliente} key={index}>
                                                        {nombre} - {rfc}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    )
}

export default AddExitByLevel;