import { useState, useEffect, useContext } from "react";
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from "sweetalert2";
import { Switch, useCheckboxState } from 'pretty-checkbox-react';
import 'pretty-checkbox';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import {
    apiPostNewMovReason,
} from '../../../common/api-invent';

const AddMovReason = ({ show, setShow }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComponent = 18;
    // Switches de opciones
    const s1 = useCheckboxState();

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        nombre: '',
        requiere_documento: 0,
    });

    // Manejo de estados de switch
    useEffect(() => {
        setData(data => ({
            ...data,
            requiere_documento: Number(s1.state),
        }));
    }, [s1.state]);

    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre',
                show: true
            });
            return false;
        }
        return true;
    }

    // Boton de creacion
    const handleSubmit = async event => {
        event.preventDefault();

        if (isFormValid()) {
            try {
                const response = await apiPostNewMovReason(data);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "La razón ha sido agregado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Carga de componente
                    onReloadComponent(idReloadComponent);
                    // Limpiar datos de formulario
                    setData({
                        nombre: '',
                        requiere_documento: 0,
                    });
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al agregar nueva razón");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar razón',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="md"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Agregar Razón"
                buttonTitle="Agregar"
                handleClick={handleSubmit}
            >
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md="7" xs="12">
                            <div className="form-group">
                                <label htmlFor="nombre" className="form-label">
                                    Nombre: *
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nombre"
                                    name="nombre"
                                    value={data.nombre}
                                    onChange={handleFormChange}
                                    placeholder="Razon"
                                />
                            </div>
                        </Col>
                        <Col className="mt-30" md="4">
                            <Switch
                                color="success"
                                shape="fill"
                                bigger
                                className="form-label"
                                {...s1}
                            >
                                ¿Requiere documento?
                            </Switch>
                        </Col>
                    </Row>
                </Form>
            </Modals>
        </>
    );
}

export default AddMovReason;