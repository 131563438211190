import PropTypes from 'prop-types';

import {
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';

export const OverlayTooltip = ({ children, placement, tooltip }) => {
    return (
        <OverlayTrigger
            placement={placement}
            delay={{ show: 250, hide: 250 }}
            overlay={
                <Tooltip id={`tip-${placement}-${tooltip}`}>
                    {tooltip}
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    )
}

OverlayTooltip.propTypes = {
    placement: PropTypes.string.isRequired,
    tooltip: PropTypes.node.isRequired,
}