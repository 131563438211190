import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import {
    apiGetAllJobs,
    apiGetStaffId,
    apiPatchStaff,
    apiGetAllStaff,
} from '../../../common/api-invent';
import {
    apiGetAllUsers
} from '../../../common/api-general'

const EditStaff = ({ show, setShow, id }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 22;

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [users, setUsers] = useState([]);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        id_usuario_empresa: '0',
        id_puesto: '0',
        id_personal_responsable: '0',
        nombre: ''
    });
    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Informacion del personal
                const reqStaff = await apiGetStaffId(id);
                setData({
                    ...reqStaff.data.content,
                    id_personal_responsable: reqStaff.data.content.id_personal_responsable ?? '0',
                    id_usuario_empresa: reqStaff.data.content.id_usuario_empresa ?? '0',
                });
                // Solicitar puestos de trabajo
                const reqJobs = await apiGetAllJobs();
                setJobs([...reqJobs.data.content]);
                // Solicitar personal
                const reqStaffs = await apiGetAllStaff();
                setStaffs([...reqStaffs.data.content]);
                // Solicitar usuarios
                const reqUsers = await apiGetAllUsers();
                setUsers([...reqUsers.data.content]);
            } catch (e) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setLoading(false);
        }
    }, [setData, setLoading, show, id]);

    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.id_puesto === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere puesto',
                show: true
            });
            return false;
        }
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre del personal',
                show: true
            });
            return false;
        }
        return true;
    }

    // Editar personal
    const handleSubmit = async event => {
        event.preventDefault();

        if (isFormValid()) {
            try {
                const sendData = {
                    ...data,
                    id_personal_responsable:
                        data.id_personal_responsable === '0'
                            ? null
                            : data.id_personal_responsable,
                    id_usuario_empresa:
                        data.id_usuario_empresa === '0'
                            ? null
                            : data.id_usuario_empresa
                };

                const response = await apiPatchStaff(id, sendData);
                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "El personal ha sido editado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al editar personal");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al editar personal',
                    text: error.response.data?.msg ??'Intentelo nuevamente más tarde',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Editar Personal"
                buttonTitle="Guardar"
                handleClick={handleSubmit}
                size="md"
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_puesto" className="form-label">
                                            Puesto: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_puesto"
                                            id="id_puesto"
                                            value={data.id_puesto}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un puesto</option>
                                            {
                                                jobs.map(({ glb_id_puesto, glb_nombre }, index) => (
                                                    <option value={glb_id_puesto} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_usuario_empresa" className="form-label">
                                            Usuario del Sistema:
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_usuario_empresa"
                                            id="id_usuario_empresa"
                                            value={data.id_usuario_empresa}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un usuario</option>
                                            {
                                                users.map(({ id_usuario_empresa, username, nombre }, index) => (
                                                    <option value={id_usuario_empresa} key={index}>
                                                        {nombre} - {username}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="nombre" className="form-label">
                                            Nombre: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nombre"
                                            name="nombre"
                                            value={data.nombre}
                                            onChange={handleFormChange}
                                            placeholder="Juan Hernandez"
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_personal_responsable" className="form-label">
                                            Persona Responsable:
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_personal_responsable"
                                            id="id_personal_responsable"
                                            value={data.id_personal_responsable}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un personal</option>
                                            {
                                                staffs.map(({ id_personal, nombre }, index) => (
                                                    <option value={id_personal} key={index}>
                                                        {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default EditStaff;