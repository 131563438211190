import { useState, useEffect, useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";
// Componentes Generales
import Modals from "../modal.component";
import ToastComponent, {
  ToastModalContainer,
} from "../../Toast/toast.component";
import Load from "../../Load/load.component";

import AddEntranceProductModal from "./addEntranceProduct.modal";
// Colores
import { ColorPrimary } from "../../../utilities/pallete.const";
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import {
  apiGetAllStaff,
  apiPostAddEntranceByProd,
  apiGetDocsProds,
} from "../../../common/api-invent";

const ProcessingEntranceProducts = ({ show, setShow, idProd }) => {
  const { onReloadComponent } = useContext(ReloadContext);

  const idReloadCompA = 23;
  const idReloadCompB = 27;

  // Manejar carga de datos
  const [loading, setLoading] = useState(true);
  const [staffs, setStaffs] = useState([]);

  // Objeto de alerta
  const [alert, setAlert] = useState({
    title: "",
    message: "",
    show: false,
  });

  // Hook para formulario
  const [data, setData] = useState({
    id_personal: "0",
    id_razon_movimiento: process.env.REACT_APP_DEFAULT_ID_REASON_ENTRANCE,
    id_producto_costo: "0",
    lote: "",
    codigo_caja: "",
    codigo_tarima: "",
    fecha_caducidad: "",
    fecha_movimiento: moment().format("YYYY-MM-DD"),
    numero_orden_compra: "",
    fecha_orden_compra: "",
    fecha_consumo: "",
    numero_pedido: "",
  });

  // Hook para entrada
  const [showModalEntrance, setShowModalEntrance] = useState(false);
  const [idEntrance, setIdEntrance] = useState(0);

  // Cargar datos
  useEffect(() => {
    const loadData = async () => {
      try {
        setIdEntrance(0);
        setLoading(true);
        let reqReceive = await apiGetDocsProds(idProd);
        reqReceive = reqReceive.data.content[0];
        console.log(reqReceive)
        setData((data) => ({
          ...data,
          codigo_caja: reqReceive.codigo_proveedor,
          numero_orden_compra: reqReceive.referencia,
          fecha_orden_compra: moment(reqReceive.fecha_documento).format("YYYY-MM-DD"),
          numero_pedido: reqReceive.referencia2
        }));
        // Solicitar personal
        const reqStaffs = await apiGetAllStaff();
        setStaffs([...reqStaffs.data.content]);
        setLoading(false);
      } catch (e) {
        console.log(`Ocurrio un error:\n${e}`);
        Swal.fire({
          icon: "error",
          title: "Error al cargar datos, intentelo despúes",
          confirmButtonText: "Aceptar",
        });
      }
    };
    if (show) loadData();
    return () => setLoading(false);
  }, [show, idProd]);

  // Llena el arreglo de datos con los datos del formulario
  const handleFormChange = (e) => {
    if (e.target.name === "fecha_consumo") {
      setData({
        ...data,
        [e.target.name]: e.target.value,
        fecha_caducidad: moment(e.target.value)
          .add(5, "years")
          .format("YYYY-MM-DD"),
      });
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  //Validación para formulario
  const isFormValid = () => {
    setAlert({
      title: "",
      message: "",
      show: false,
    });
    if (data.id_personal === "0") {
      setAlert({
        title: "Formulario incompleto",
        message: "Se requiere personal",
        show: true,
      });
      return false;
    }
    if (data.fecha_consumo === "") {
      setAlert({
        title: "Formulario incompleto",
        message: "Se requiere Usar después de...",
        show: true,
      });
      return false;
    }
    if (data.lote === "") {
      setAlert({
        title: "Formulario incompleto",
        message: "Se requiere lote",
        show: true,
      });
      return false;
    }
    if (data.fecha_caducidad === "") {
      setAlert({
        title: "Formulario incompleto",
        message: "Se requiere fecha de caducidad",
        show: true,
      });
      return false;
    }
    if (data.codigo_caja === "") {
      setAlert({
        title: "Formulario incompleto",
        message: "Se requiere codigo de embalaje",
        show: true,
      });
      return false;
    }
    if (data.numero_orden_compra === "") {
      setAlert({
        title: "Formulario incompleto",
        message: "Se requiere número de orden de compra",
        show: true,
      });
      return false;
    }
    if (data.fecha_orden_compra === "") {
      setAlert({
        title: "Formulario incompleto",
        message: "Se requiere fecha de orden de compra",
        show: true,
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (saveProduct) => {
    if (isFormValid()) {
      const alert = await Swal.fire({
        showCancelButton: true,
        showCloseButton: true,
        title: "¿Esta seguro que desea recibir este producto?",
        text: "La acción no se podrá deshacer",
        icon: "warning",
        confirmButtonText: "Si, recibir",
        cancelButtonText: "No, cancelar",
        cancelButtonColor: ColorPrimary,
        reverseButtons: true,
      });
      // La alerta ha sido aceptada
      if (alert.isConfirmed) {
        try {
          const sendData = {
            ...data,
            id_producto_costo: idProd,
          };
          const response = await apiPostAddEntranceByProd(sendData);
          if (response.data.type === "success") {
            await Swal.fire({
              title: "El producto ha sido recibido correctamente",
              icon: "success",
              confirmButtonText: "Aceptar",
            });
          } else {
            throw new Error("Error al recibir productos");
          }
          // Verificar si tambien sera almacenado
          if (saveProduct) {
            setIdEntrance(response.data?.content?.id_entrada);
          }
          // Recarga de componente
          onReloadComponent(idReloadCompA);
          onReloadComponent(idReloadCompB);
          // Cerrar modal
          setShow(false);
          // Limpiar datos
          setData({
            id_personal: "0",
            id_razon_movimiento: "1",
            lote: "",
            codigo_caja: "",
            codigo_tarima: "",
            fecha_consumo: "",
            fecha_movimiento: moment().format("YYYY-MM-DD"),
          });
          if (saveProduct) {
            setShowModalEntrance(true);
          }
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "Error al recibir",
            text: e.response?.data?.msg ?? "Intentelo nuevamente más tarde",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  return (
    <>
      <ToastModalContainer show={alert.show}>
        <ToastComponent
          type="danger"
          title={alert.title}
          message={alert.message}
          show={true}
          onClose={() =>
            setAlert({
              ...alert,
              show: false,
            })
          }
        />
      </ToastModalContainer>
      <Modals
        modalTitle="Recibir Producto"
        size="md"
        show={show}
        hideModal={() => {
          setShow(false);
        }}
        buttonTitle="Guardar y almacenar"
        handleClick={() => {
          handleSubmit(true);
        }}
        auxButtonTitle="Guardar y cerrar"
        handleAuxButton={() => {
          handleSubmit(false);
        }}
      >
        {loading ? (
          <Load size={150} marginBottom="10px" marginTop="10px" />
        ) : (
          <Form>
            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="fecha_movimiento"  className="form-label">Fecha en la que se recibe:</label>
                  <input 
                    type="date"
                    id="fecha_movimiento"
                    name="fecha_movimiento"
                    className="form-control"
                    min={moment().subtract('5', 'days').format('YYYY-MM-DD')}
                    max={moment().format('YYYY-MM-DD')}
                    value={data.fecha_movimiento}
                    onChange={handleFormChange}
                  />
                </div>
              </Col>
              <Col md="6" xs="12">
                <div className="form-group">
                  <label htmlFor="id_personal" className="form-label">
                    Personal que recibe: *
                  </label>
                  <select
                    className="form-select"
                    name="id_personal"
                    id="id_personal"
                    value={data.id_personal}
                    onChange={handleFormChange}
                  >
                    <option value="0">Seleccione un personal</option>
                    {staffs.map(({ id_personal, nombre }, index) => (
                      <option value={id_personal} key={index}>
                        {nombre}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md="6" xs="12">
                <div className="form-group">
                  <label htmlFor="lote" className="form-label">
                    Lote: *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lote"
                    name="lote"
                    value={data.lote}
                    onChange={handleFormChange}
                  />
                </div>
              </Col>
              <Col md="6" xs="12">
                <div className="form-group">
                  <label htmlFor="fecha_caducidad" className="form-label">
                    Usar despues de...: *
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="fecha_consumo"
                    name="fecha_consumo"
                    value={data.fecha_consumo}
                    onChange={handleFormChange}
                  />
                </div>
              </Col>
              <Col md="6" xs="12">
                <div className="form-group">
                  <label htmlFor="codigo_tarima" className="form-label">
                    Código Tarima:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="codigo_tarima"
                    name="codigo_tarima"
                    value={data.codigo_tarima}
                    onChange={handleFormChange}
                  />
                </div>
              </Col>
              <Col md="6" xs="12">
                <div className="form-group">
                  <label htmlFor="codigo_caja" className="form-label">
                    Código Embalaje: *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="codigo_caja"
                    name="codigo_caja"
                    value={data.codigo_caja}
                    onChange={handleFormChange}
                  />
                </div>
              </Col>
              <Col md="6" xs="12">
                <div className="form-group">
                  <label htmlFor="numero_orden_compra" className="form-label">
                    Número Orden de Compra: *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="numero_orden_compra"
                    name="numero_orden_compra"
                    value={data.numero_orden_compra}
                    onChange={handleFormChange}
                    disabled
                  />
                </div>
              </Col>
              <Col md="6" xs="12">
                <div className="form-group">
                  <label htmlFor="fecha_orden_compra" className="form-label">
                    Fecha Orden de Compra: *
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="fecha_orden_compra"
                    name="fecha_orden_compra"
                    value={data.fecha_orden_compra}
                    onChange={handleFormChange}
                    disabled
                  />
                </div>
              </Col>
              <Col md="6" xs="12">
                <div className="form-group">
                  <label htmlFor="numero_pedido" className="form-label">
                    Número de Pedido:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="numero_pedido"
                    name="numero_pedido"
                    value={data.numero_pedido}
                    disabled
                  />
                </div>
              </Col>
              <Col md="6" xs="12">
                <div className="form-group">
                  <label htmlFor="fecha_caducidad" className="form-label">
                    Fecha Caducidad: *
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="fecha_caducidad"
                    name="fecha_caducidad"
                    value={data.fecha_caducidad}
                    onChange={handleFormChange}
                    disabled
                  />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Modals>
      <AddEntranceProductModal
        show={showModalEntrance}
        setShow={setShowModalEntrance}
        id={idEntrance}
      />
    </>
  );
};
ProcessingEntranceProducts.defaultProps = {
  isBill: false,
};
export default ProcessingEntranceProducts;
