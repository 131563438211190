import backendRequest from './requests';
/* ---------------------- DATOS GLOBALES -----------------------*/
// Leer status globales
export const apiGetAllStatus = () => (
    backendRequest.get(`/datos/estatus/getAll`)
)
// Leer todos los paises
export const apiGetAllCountries = () => (
    backendRequest.get(`/datos/pais/getAll`)
)
// Leer todas las monedas
export const apiGetAllBadges = () => (
    backendRequest.get(`/datos/moneda/sat/all`)
)
/* ------------------------ ALMACENES ---------------------------*/
// Leer todos los almacenes
export const apiGetStores = () => (
    backendRequest.get(`/inventario/almacen/getAll`)
)
// Leer alamcen con id
export const apiGetStoreId = id => (
    backendRequest.get(`/inventario/almacen/ById/${id}`)
)
// Insertar un nuevo almacen
export const apiPostNewStore = (json) => (
    backendRequest.post('/inventario/almacen/new', json)
);
// Editar almacen
export const apiPatchStore = (id, data) => (
    backendRequest.patch(`/inventario/almacen/edit/${id}`, data)
)
// Leer racks de almacen en orden alfabetico
export const apiGetAllRacks = (id) => (
    backendRequest.get(`/inventario/rack/getAll?id_almacen=${id}`)
)
// Leer todos los racks CON EXISTENCIA de un almacen
export const apiGetRacksWithExistance = (idStore) => (
    backendRequest.get(`/inventario/rack/racksExistencia?id_almacen=${idStore}`)
);
/* ------------------------ RACKS ---------------------------*/
// Leer Rack con id
export const apiGetRackId = id => (
    backendRequest.get(`/inventario/rack/ById/${id}`)
);
// Leer niveles de un rack
export const apiGetLevelsWithRack = (idRack) => (
    backendRequest.get(`/inventario/almUbicacion/getExisAlmByRack?id_rack=${idRack}`)
);
// Insertar un nuevo rack
export const apiPostNewRack = (datos, idAlmacen) => (
    backendRequest.post(`/inventario/rack/new?id_almacen=${idAlmacen}`, datos)
)
// Editar rack
export const apiPatchRack = (id, data) => (
    backendRequest.patch(`/inventario/rack/edit/${id}`, data)
)
// Solicitar codigos qr de un rack
export const apiGetQRRack = (id) => (
    backendRequest.get(`/inventario/rack/qrcodes/${id}?urlPageEntrance=/15&urlPageExit=/22`)
)

//obtener el numero de cajas que que peden almacenar los racks
export const apiGetBoxesAllRacks = () => (
    backendRequest.get(`/inventario/almUbicacion/getBoxesExistAllRacks`)
)
/* ------------------------ NIVELES ---------------------------*/
// Eliminar un nivel
export const apiDeleteLeveRack = id => (
    backendRequest.delete(`/inventario/almUbicacion/delete/${id}`)
)
// Insertar un nuevo nivel
export const apiPostNewLevel = (data, idRack) => (
    backendRequest.post(`/inventario/almUbicacion/new?id_rack=${idRack}`, data)
)
// Leer nivel con id
export const apiGetLevelId = id => (
    backendRequest.get(`/inventario/almUbicacion/ById/${id}`)
)
// Editar nivel
export const apiPatchLevel = (id, data) => (
    backendRequest.patch(`/inventario/almUbicacion/edit/${id}`, data)
)

//Obtener todos los niveles de un rack
export const getRacksByStore = (id_store) => (
    backendRequest.get(`/inventario/almUbicacion/all?value=${id_store}&column=nombre`)
)
/* ------------------------ PRODUCTOS ---------------------------*/
// Crear nuevo producto
export const apiPostNewProduct = data => (
    backendRequest.post(`/inventario/producto/newProd`, data)
)
// Obtener todos los productos de una empresa
export const apiGetAllProducts = () => (
    backendRequest.get('/inventario/producto/prodByEmp')
)
// Obtener todos los productos de un proveedor
export const apiGetAllProductsByProv = (id) => (
    backendRequest.get(`/inventario/producto/prodsProveedor?column=nombre&value=${id}`)
)
// Obtener los ERP de producto
export const apiGetAllProductsERP = (limit = 1000) => (
    backendRequest.get(`/inventario/producto/erp?limit=${limit}`)
)
// Leer producto con id
export const apiGetProduct = id => (
    backendRequest.get(`/inventario/producto/ById/${id}`)
);
// Editar producto con id
export const apiPatchProduct = (id, data) => (
    backendRequest.patch(`/inventario/producto/editProd/${id}`, data)
);
// Eliminar producto con id
export const apiDeleteProduct = id => (
    backendRequest.delete(`/inventario/producto/deleteProd/${id}`)
);
/* ------------------------ DETALLES DE PRODUCTOS ---------------------------*/
// Obtener detalle(s) de producto
export const apiGetDetailsProduct = (id, entity) => (
    backendRequest.get(`/inventario/rosa/tablas/getAllByProd/${id}?entidad=${entity}`)
)
// Obtener detalle de producto con id
export const apiGetDetailProductId = (id, entity) => (
    backendRequest.get(`/inventario/rosa/tablas/getById/${id}?entidad=${entity}`)
)
// Editar detalle de producto con id
export const apiPatchDetailProduct = (entity, id, data) => (
    backendRequest.patch(`/inventario/rosa/tablas/editRaw/${id}?entidad=${entity}`, data)
)
// Crear nuevo detalle de producto
export const apiPostDetailProduct = (data, id, entity) => (
    backendRequest.post(`/inventario/rosa/tablas/newRaw?id_producto=${id}&entidad=${entity}`, data)
)
// Eliminar detalle del producto
export const apiDeleteDetailProduct = (id, entity) => (
    backendRequest.delete(`/inventario/rosa/tablas/deleteRaw/${id}?entidad=${entity}`)
)
/* ------------------------ FOTOS DE PRODUCTOS ---------------------------*/
// Agregar foto a un producto
export const apiPostPictureProduct = (id, data) => (
    backendRequest.post(`/inventario/producto/uploadImg/${id}`, data)
)
// Obtener todas las fotos
export const apiGetPicturesProduct = (id) => (
    backendRequest.get(`/inventario/producto/fotos/${id}`)
)
// Eliminar una foto con id
export const apiDeletePictureProduct = (id) => (
    backendRequest.delete(`/inventario/producto/deleteFoto/${id}`)
)
// Obtener informacion de una fotografia con id
export const apiGetPictureProductId = (id) => (
    backendRequest.get(`/inventario/producto/fotoById/${id}`)
)
// Editar información de fotografía con id
export const apiPatchPictureProduct = (id, data) => (
    backendRequest.patch(`/inventario/producto/editFoto/${id}`, data)
)
/* ------------------------ FICHAS DE PRODUCTO ---------------------------*/
// Agregar documento a un producto
export const apiPostDocProduct = (id, data) => (
    backendRequest.post(`/inventario/rosa/fichas/uploadDoc/${id}`, data)
)
// Obtener todas los documentos
export const apiGetGetDocsProduct = (id) => (
    backendRequest.get(`/inventario/rosa/fichas/all/${id}`)
)
// Eliminar un documento con su id
export const apiDeleteDocumentProduct = (id) => (
    backendRequest.delete(`/inventario/rosa/fichas/delete/${id}`)
)
// Obtener información de un documento con id
export const apiGetDocumentProductId = (id) => (
    backendRequest.get(`/inventario/rosa/fichas/ById/${id}`)
)
// Editar informacion de documento con id
export const apiPatchDocumentProduct = (id, data) => (
    backendRequest.patch(`/inventario/rosa/fichas/edit/${id}`, data)
)
/* --------------- PROPIEDADES DE PRODUCTOS (Tabla Medidas) ------------------*/
// Obtener todas las propiedades de un producto
export const apiGetPropiertiesProduct = id => (
    backendRequest.get(`/inventario/rosa/propiedades/all?id_producto=${id}`)
)
// Obtener propiedad de producto con id
export const apiGetPropiertyProductId = id => (
    backendRequest.get(`/inventario/rosa/propiedades/ById/${id}`)
)
// Editar propiedad de producto
export const apiPatchPropierty = (id, data) => (
    backendRequest.patch(`/inventario/rosa/propiedades/edit/${id}`, data)
)
// Agregar nueva propiedad
export const apiPostPropiertyProduct = (id, data) => (
    backendRequest.post(`/inventario/rosa/propiedades/new?id_producto=${id}`, data)
)
// Agregar multiples propiedades
export const apiPostPropiertiesProduct = (id, data) => (
    backendRequest.post(`/inventario/rosa/propiedades/varias?id_producto=${id}`, data)
)
// Eliminar propiedad
export const apiDeletePropiertyProduct = (id) => (
    backendRequest.delete(`/inventario/rosa/propiedades/delete/${id}`)
)
/* ----------------------- REGLAS DE ALMACENAMIENTO --------------------------*/
// Obtener las reglas de almacenamiento de un producto
export const apiGetStorageRulesProduct = id => (
    backendRequest.get(`/inventario/rosa/reglas/all/${id}`)
)
// Agregar nueva regla de almacenamiento
export const apiPostStorageRuleProduct = data => (
    backendRequest.post(`/inventario/rosa/reglas/new`, data)
)
// Editar regla de almacenamiento
export const apiPatchStorageRuleProduct = (id, data) => (
    backendRequest.patch(`/inventario/rosa/reglas/edit/${id}`, data)
)
// Eliminar regla de almacenamiento
export const apiDeleteStorageRuleProduct = id => (
    backendRequest.delete(`/inventario/rosa/reglas/delete/${id}`)
)
/* ----------------------------- MAGNINUTEDES --------------------------------*/
// Obtener todas las magnitudes
export const apiGetMagnitudsAll = () => (
    backendRequest.get(`/inventario/medidas/magnitud/getAll`)
)
// Obtener Unidades de una magnitud
export const apiGetUnitFromMagnitud = id => (
    backendRequest.get(`/inventario/medidas/unidad/getAll?id_magnitud=${id}`)
)
// Obtener Medidas de una magnitud
export const apiGetMeasureFromMagnitud = id => (
    backendRequest.get(`/inventario/medidas/medida/getAll?id_magnitud=${id}`)
)
// Obtener unidad con su id
export const apiGetUnitId = id => (
    backendRequest.get(`/inventario/medidas/unidad/ById/${id}`)
)
// Obtener medida con su id
export const apiGetMeasureId = id => (
    backendRequest.get(`/inventario/medidas/medida/ById/${id}`)
)
// Obtener todas las equivalencias entre unidades
export const apiGetAllMeasureEq = () => (
    backendRequest.get(`/inventario/medidas/equivalencia/all`)
)
/* ----------------------- DEPENDENCIAS DE PRODUCTOS --------------------------*/
// Obtener tuplas de una dependencia 
export const apiGetDependcy = dependency => (
    backendRequest.get(`/inventario/azul/getAllByEmp?entidad=${dependency}`)
);
// Obtener informacion de dependencia con id
export const apiGetDependcyId = (id, dependency) => (
    backendRequest.get(`/inventario/azul/getById/${id}?entidad=${dependency}`)
)
// Crear nueva dependencia
export const apiPostNewDependency = (data, dependency) => (
    backendRequest.post(`/inventario/azul/newRaw?entidad=${dependency}`, data)
)
// Actualizar informacion de dependencia
export const apiPatchDependency = (id, data, dependency) => (
    backendRequest.patch(`/inventario/azul/editRaw/${id}?entidad=${dependency}`, data)
)
// Borrar elemento de una dependencia 
export const apiDeleteDependcyId = (id, dependency) => (
    backendRequest.delete(`/inventario/azul/deleteRaw/${id}?entidad=${dependency}`)
);
/* ------------------------ PROVEEDORES ---------------------------*/
// Obtener todos los proveedores
export const apiGetAllProviders = (type = null) => (
    backendRequest.get(`/inventario/proveedores/getAll?id_tipo_proveedor=${type == null ? '' : type}`)
)
// Obtener informacion de proveedor con id
export const apiGetProviderId = id => (
    backendRequest.get(`/inventario/proveedores/getById/${id}`)
)
// Crear nuevo proveedor
export const apiPostNewProvider = (data) => (
    backendRequest.post(`/inventario/proveedores/new`, data)
)
// Actualizar informacion de dependencia
export const apiPatchProvider = (id, data) => (
    backendRequest.patch(`/inventario/proveedores/edit/${id}`, data)
)
// Borrar proveedor
export const apiDeleteProvider = id => (
    backendRequest.delete(`/inventario/proveedores/delete/${id}`)
)
// Obtener tipos de proveedor
export const apiGetTypeProviders = () => (
    backendRequest.get(`/inventario/proveedores/tipo/getAll`)
)
/* ------------------------ LISTAS PRECIO ---------------------------*/
// Obtener listas precios de proveedor
export const apiGetListPricesProv = (id) => (
    backendRequest.get(`/inventario/precios/lista/all/${id}`)
)
// Obtener productos de una lista de precios
export const apiGetAllProductsByListPrice = (id) => (
    backendRequest.get(`/inventario/precios/prodLista/all/${id}`)
)
// Crear nueva lista de precio con productos (arreglo dinamico)
export const apiPostListProducts = (idProv, data) => (
    backendRequest.post(`/inventario/precios/prodLista/variosPrecios?id_proveedor=${idProv}`, data)
)
// Crear nueva lista de precio con productos Documento
export const apiPostDocumentListProducts = (idProv, data) => (
    backendRequest.post(`/inventario/precios/prodLista/preciosDoc?id_proveedor=${idProv}`, data)
)
/* ------------------------ RAZONES MOVIMIENTO ---------------------------*/
// Obtener las razones de movimiento
export const apiGetAllMovsReasons = () => (
    backendRequest.get(`/inventario/movimientos/razones/all`)
)

export const apiGetDetails = (id_razon_movimiento) => (
    backendRequest.get(`/inventario/movimientos/razones/detalles/${id_razon_movimiento}`)
)

export const apiGetAllTypeMov = () => (
    backendRequest.get(`/inventario/movimientos/tipo/all`)
)

export const apiGetProductByTypeAndStore = (id_departures, id_store) => (
    backendRequest.get(`/inventario/movimientos/salidas/posible?id_tipo_salida=${id_departures}&id_almacen=${id_store}`)
)

export const apiGetRack = (id_store, id_product, id_departure) => (
    backendRequest.get(`/inventario/movimientos/existencias/racks?id_almacen=${id_store}&id_producto=${id_product}&id_tipo_salida=${id_departure}`)
)

export const apiGetLevel = (id_rack, id_product, id_departure) => (
    backendRequest.get(`/inventario/movimientos/existencias/niveles?id_rack=${id_rack}&id_producto=${id_product}&id_tipo_salida=${id_departure}`)
)

export const apiGetExistencias = (id_store_location, id_product, id_departure) => (
    backendRequest.get(`/inventario/movimientos/existencias/productos?id_almacen_ubicacion=${id_store_location}&id_producto=${id_product}&id_tipo_salida=${id_departure}`)
)

// Obtener razon por tipo (1 -> Entradas & 2 -> Salidas)
export const apiGetReasonByType = (type, status = '') => (
    backendRequest.get(`/inventario/movimientos/razones/razonByTipo?tipo_movimiento=${type}${status}`)
)
// Obtener todas las razones de salida
export const apiGetAllMovReasonsExit = () => (
    backendRequest.get(`/inventario/movimientos/razones/razonByTipo?tipo_movimiento=2`)
)
// Obtener todas las razones de entrada
export const apiGetAllMovReasonsEntrance = () => (
    backendRequest.get(`/inventario/movimientos/razones/razonByTipo?tipo_movimiento=1`)
)
// Crear nueva razon
export const apiPostNewMovReason = (data) => (
    backendRequest.post(`/inventario/movimientos/razones/new`, data)
)
/* ----------------------------- FACTURAS --------------------------------*/
// Agregar factura a un proveedor
export const apiPostDocumentBill = (idProv, data) => (
    backendRequest.post(`/inventario/costos/docsCostos/new?id_proveedor=${idProv}`, data)
)
// Obtener las factuas de un proveedor
export const apiGetBillsByProv = (id) => (
    backendRequest.get(`/inventario/costos/docsCostos/getAll/${id}`)
)
// Obtener los productos de una factura
export const apiGetAllProdsByBill = (id) => (
    backendRequest.get(`/inventario/costos/prods/all/${id}`)
)
/* ----------------------------- PUESTOS --------------------------------*/
// Agregar puesto
export const apiPostJob = (data) => (
    backendRequest.post(`/inventario/personal/puesto/new`, data)
)
// Obtener todos los puestos
export const apiGetAllJobs = () => (
    backendRequest.get(`/inventario/personal/puesto/all`)
)
// Obtener puesto por id
export const apiGetJobId = (id) => (
    backendRequest.get(`/inventario/personal/puesto/ById/${id}`)
)
// Eliminar un puesto con id
export const apiDeleteJobId = (id) => (
    backendRequest.delete(`/inventario/personal/puesto/delete/${id}`)
)
// Editar puesto
export const apiPatchJob = (id, data) => (
    backendRequest.patch(`/inventario/personal/puesto/edit/${id}`, data)
)
/* ----------------------------- PERSONAL --------------------------------*/
// Agregar personal
export const apiPostStaff = data => (
    backendRequest.post(`/inventario/personal/personal/new`, data)
)
// Obtener todo el personal
export const apiGetAllStaff = () => (
    backendRequest.get(`/inventario/personal/personal/all`)
)
// Obtener personal con relacion a tabla de usuarios 
export const apiGetStaffRelationUsers = () => (
    backendRequest.get(`/inventario/personal/personal/personalUser`)
)
// Obtener personal por id
export const apiGetStaffId = (id) => (
    backendRequest.get(`/inventario/personal/personal/ById/${id}`)
)
// Eliminar personal por id
export const apiDeleteStaffId = (id) => (
    backendRequest.delete(`/inventario/personal/personal/delete/${id}`)
)
// Editar personal
export const apiPatchStaff = (id, data) => (
    backendRequest.patch(`/inventario/personal/personal/edit/${id}`, data)
)
// Comparar contraseña de un personal
export const apiPostComparePassword = (data) => (
    backendRequest.post(`/inventario/personal/personal/personalPassword`, data)
)
/* ----------------------------- POR RECIBIR --------------------------------*/
// Obtener facturas pendientes
export const apiGetPendingDocs = () => (
    backendRequest.get(`/inventario/costos/docsCostos/facturaNoRecibido`)
)
// Generar entradas a partir de PRODUCTOS
export const apiPostAddEntranceByProd = (data) => (
    backendRequest.post(`/inventario/movimientos/entradas/nuevos`, data)
)
// Obtener productos pendientes de una factura
export const apiGetProcessingProds = (id) => (
    backendRequest.get(`/inventario/costos/prods/recibir?status=0&id_doc_prod_costo=${id}`)
)
// Obtener todos los productos pendientes por recibir
export const apiGetAllProcessingProds = () => (
    backendRequest.get(`/inventario/costos/prods/recibirEmpresa?status=0`)
)
// Obtener los documentos costos por id
export const apiGetDocsProds = (id) => (
    backendRequest.get(`/inventario/costos/prods/documentos?id_producto_costo=${id}`)
)
/* ----------------------------- ENTRADAS --------------------------------*/
// Obtener todas las entradas
export const apiGetAllEntries = () => (
    backendRequest.get(`/inventario/movimientos/entradas/all`)
)
// Obtener productos almacenados
export const apiGetAllProductsStocked = () => (
    backendRequest.get(`/inventario/movimientos/salidas/posibleSalida`)
)
// Obtener productos para alamacenar desde un nivel
export const apiGetEntriesbyLevel = (id) => (
    backendRequest.get(`/inventario/rosa/reglas/prodnivel?id_almacen_ubicacion=${id}`)
)
// Obtener entrada por id
export const apiGetEntryById = (id) => (
    backendRequest.get(`/inventario/movimientos/entradas/entradaProducto?id_entrada=${id}`)
)
// Obtener mas informacion de entrada por id
export const apiGetEntryExtraById = (id) => (
    backendRequest.get(`/inventario/movimientos/entradas/ById/${id}`)
)
/* ----------------------------------- SALIDAS --------------------------------------*/
// Posibles salidas por nivel
export const apiGetPossibleExitsByLevel = (id) => (
    backendRequest.get(`/inventario/movimientos/salidas/salidasNivel?id_almacen_ubicacion=${id}`)
)
// Detalles por nivel
export const apiGetDetailsByLevel = (id) => (
    backendRequest.get(`/inventario/movimientos/salidas/detallesNivel?id_almacen_ubicacion=${id}`)
)
// Generar nueva salida
export const apiPostNewExit = (id_tipo_salida, data) => (
    backendRequest.post(`/inventario/movimientos/existencias/editSalida?id_tipo_salida=${id_tipo_salida}`, data)
)
// Obtener registro de todas las saldias con paginacion
export const apiGetAllExitsPerPage = () => (
    backendRequest.get(`/inventario/movimientos/salidas/allBetween`)
)
// Obtener salidas por nivel
export const apiGetExitsByLevelPerPage = (id, page = 1, take = 20) => (
    backendRequest.get(`/inventario/movimientos/salidas/exitLevel?page=${page}&take=${take}&id_almacen_ubicacion=${id}`)
)
// Obtener salida por id
export const apiGetExitById = (id) => (
    backendRequest.get(`/inventario/movimientos/salidas/ById/${id}`)
)
// Obtener detalles de salida por id
export const apiGetExitDetailsById = () => (
    backendRequest.get(`/inventario/movimientos/detalles/all`)
)
// Obtener detalles de medida de salida por id
export const apiGetExitDetailsMeasures = (id) => (
    backendRequest.get(`/inventario/movimientos/detalles/medidas?id_det_salida=${id}`)
)
// Editar medidas de salida
export const apiPatchExitMeasure = (data) => (
    backendRequest.patch(`/inventario/movimientos/salidas/edit`, data)
)
// Aplicar medidas de salida
export const apiPatchApplyMeasure = (id) => (
    backendRequest.patch(`/inventario/movimientos/salidas/aplicar`, { id_salida: id })
)
// Obtener los productos que se encuentran en punto de reorden
export const apiPuntoReorden = (id_almacen) => (
    backendRequest.get(`/inventario/movimientos/existencias/puntoReorden?id_almacen=${id_almacen}`)
)
// Eliminar una salida
export const apiDeleteExit = (id) => (
    backendRequest.delete(`/inventario/movimientos/salidas/delete/${id}`)
)
// Obtener salida
export const apiGetExitPlate = (id) => (
    backendRequest.get(`/inventario/movimientos/salidas/get/${id}`)
)
// Editar salida
export const apiPatchExitPlate = (id_tipo_salida, data) => (
    backendRequest.patch(`/inventario/movimientos/salidas/edit?id_tipo_salida=${id_tipo_salida}`, data)
)
/* ------------------------------ DOCUMENTOS SALIDAS ---------------------------------*/
// Generar nuevo documento salida
export const apiPostNewDocumentExit = (data) => (
    backendRequest.post(`/inventario/movimientos/doc_salidas/subirSalida`, data)
)
// Obtener documentos de una salida
export const apiGetDocumentExit = (id) => (
    backendRequest.get(`/inventario/movimientos/doc_salidas/all?id_salida=${id}`)
)
/* ---------------------------------- EXISTENCIAS -------------------------------------*/
// Generar una existencia
export const apiPostNewExistence = (data) => (
    backendRequest.post(`/inventario/movimientos/existencias/new`, data)
)

//Obtener las cjas exitentes de todos los racks
export const apiGetExistBoxes = () => (
    backendRequest.get('/inventario/movimientos/existencias/cajasExistentes')
)

export const apiGetExistNotInArray = (data) => (
    backendRequest.post('/inventario/movimientos/existencias/cajas', data)
)

export const apiChangeLevel = (data) => (
    backendRequest.put('/inventario/movimientos/existencias/traspasos', data)
)
/* ----------------------------- CAJAS --------------------------------*/
// Obtener informacion de una caja con su id
export const apiGetBoxById = (id) => (
    backendRequest.get(`/inventario/inv/cajas/ById/${id}`)
)
/* ------------------------- INVENTARIO FISICO ----------------------------*/
// Guardar inventario fisico
export const apiPostNewPhysicalInvent = (data) => (
    backendRequest.post(`/inventario/inv/det/fisicoVarios`, data)
)
// Obtener si hay inventario fisico NO aplicado
export const apiGetTheresNoAppliedPhysicalInvent = (idStore) => (
    backendRequest.get(`/inventario/inv/fisico/invAplicado?id_almacen=${idStore}`)
)
// Obtener detalle de inventario fisico
export const apiGetByIdPhysicalInvent = (id) => (
    backendRequest.get(`/inventario/inv/fisico/ById/${id}`)
)
// Obtener productos de un inventario fisico
export const apiGetAllProdsPhysicalInvent = (id) => (
    backendRequest.get(`/inventario/inv/det/all?id_inventario_fisico=${id}`)
)
// Editar inventario fisico guardao
export const apiPatchPhysicalInvent = (data) => (
    backendRequest.patch(`/inventario/inv/det/editAll`, data)
)
// Aplicar inventario fisico
export const apiPostApplyPhysicalInvent = (data) => (
    backendRequest.post(`/inventario/inv/fisico/aplicar`, data)
)
// Obtener historial de inventarios fisicos
export const apiGetAllPhysicalInvent = ({ idStore, page }) => (
    backendRequest.get(`/inventario/inv/fisico/all?id_almacen=${idStore}&take=500&page=${page}`)
)
// Obtener detalle de  inventarios fisicos
export const apiGetDetailPhysicalInvent = (id) => (
    backendRequest.get(`/inventario/inv/det/all?id_inventario_fisico=${id}`)
)
/* ----------------------------- PEDACERIA --------------------------------*/
// Obtener productos de pedaceria de un almacen
export const apiGetAllScrapProductsByStore = (id) => (
    backendRequest.get(`/inventario/inv/pedaceria/prods?id_almacen=${id}`)
)
// Obtener pedaceria de un almacen
export const apiGetAllScrapByStore = ({ idStore, idProduct }) => (
    backendRequest.get(`/inventario/inv/pedaceria/all?id_producto=${idProduct}&id_almacen=${idStore}`)
)
// Obtener pedaceria por id
export const apiGetScrapByID = (id) => (
    backendRequest.get(`/inventario/inv/pedaceria/ById/${id}`)
)
// Guardar pedaceria
export const apiPostNewScrap = (data) => (
    backendRequest.post(`/inventario/inv/pedaceria/new`, data)
)
// Editar pedaceria
export const apiPatchScrap = (id) => (
    backendRequest.patch(`/inventario/inv/pedaceria/edit/${id}`)
)
// Obtener toda la pedaceria
export const apiGetAllScrap = () => (
    backendRequest.get(`/inventario/movimientos/pedaceria/allBetween`)
)
// Obtener medidas de una pedaceria por id
export const apiGetMedScrap = (id) => (
    backendRequest.get(`/inventario/movimientos/pedaceria/medidas?id_pedaceria_entrada=${id}`)
)
// Obtiene las mediadidas por id_caja
export const ApiGetMedidas = (id_caja) => (
    backendRequest.get(`/inventario/movimientos/pedaceria/get?id_caja=${id_caja}`)
)
/* ----------------------------- REPORTES --------------------------------*/
// Reporte de productos
export const apiPostNewReportProducts = (data) => (
    backendRequest.post(`/inventario/reportes/reporte/postAllProductosByProveedor`, data)
)
// Reporte de distribución (almacenes)
export const apiPostNewReporteStores = (data) => (
    backendRequest.post(`/inventario/reportes/reporte/postAllDistribucion`, data)
)
// Reporte de pedaceria
export const apiPostNewReportScrap = (data) => (
    backendRequest.post(`/inventario/reportes/reporte/postPedaceria`, data)
)
// Reporte de inventario fisico
export const apiPostNewReportPhysicalInvent = (data) => (
    backendRequest.post(`/inventario/reportes/reporte/postFisicoAndExistencias`, data)
)
// Report de salidas
export const apiPostNewReportExits = (data) => (
    backendRequest.post(`/inventario/reportes/reporte/reporteSalida`, data)
)
// Report de salidas
export const apiPostNewReportInventFact = (fecha_ini, fecha_fin) => (
    backendRequest.post(`/inventario/reportes/reporte/reporteInvenFact?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}`)
)
// Reporte de valuacion costo promedio
export const apiGetNewReportCostoPromedio = (data) => (
    backendRequest.post(`/inventario/reportes/reporte/valuacionCostoPromedio`, data)
)
// Generacion de polizas
export const apiGetPolizas = (data) => (
    backendRequest.post(`/inventario/reportes/reporte/polizas`, data)
)

// Reporte de entradas por compra vs listas precio
export const apiPostNewReportTicketsPriceLists = (fecha_ini, fecha_fin) => (
    backendRequest.post(`/inventario/reportes/reporte/reporteTicketsPriceLists?fecha_ini=${fecha_ini}&fecha_fin=${fecha_fin}`)
)
/* ------------------------- CLIENTES ----------------------------*/
// Obtener todos los clientes
export const apiGetAllClients = () => (
    backendRequest.get(`/inventario/clientes/all`)
)
// Obtener cliente por Id
export const apiGetClientById = (id) => (
    backendRequest.get(`/inventario/clientes/ById/${id}`)
)
// Guardar nuevo cliente
export const apiPostNewClient = (data) => (
    backendRequest.post(`/inventario/clientes/new`, data)
)
// Editar un cliente
export const apiPatchClient = (id, data) => (
    backendRequest.patch(`/inventario/clientes/edit/${id}`, data)
)
// Eliminar un cliente
export const apiDeleteClient = (id) => (
    backendRequest.delete(`/inventario/clientes/delete/${id}`)
)

//Obtener clientes del ERP
export const getClientsERP = () => (
    backendRequest.get('/inventario/clientes/clientesERP')
)

/* ------------------------ PEDIMENTOS ---------------------------*/
// 1. Agregar nuevo pedimento
export const apiPostNewPetitionFile = (data) => (
    backendRequest.post(`/inventario/pedimentos/pedimento/new`, data)
)
// 1. Obtener facturas de un pedimento
export const apiGetBillsByPetition = (id) => (
    backendRequest.get(`/inventario/costos/docsCostos/pedimento?id_pedimento=${id}`)
)

// 2. Agregar detalles costos
export const apiPostNewPetitionDetailDocs = (data) => (
    backendRequest.post(`/inventario/pedimentos/detCosto/varios`, data)
)

// 3. Agregar detalles gastos
export const apiPostNewPetitionOutgoings = (id, data) => (
    backendRequest.post(`/inventario/pedimentos/detGasto/new?id_pedimento=${id}`, data)
)
// 3. Obtener detalles de gastos
export const apiGetNewPetitionOutgoings = (id) => (
    backendRequest.get(`/inventario/pedimentos/detGasto/all?id_pedimento=${id}`)
)

// 4. Agregar cuentas de gastos
export const apiPostNewPetitionOutBills = (id, data) => (
    backendRequest.post(`/inventario/pedimentos/detCta/nuevo?id_pedimento=${id}`, data)
)
// 4. Obtener detalle cuenta de gasto
export const apiGetNewPetitionOutBulls = (id) => (
    backendRequest.get(`/inventario/pedimentos/detCta/get?id_pedimento=${id}`)
)

// 5. Generar calculos
export const apiPostNewPetitionCalc = (id) => (
    backendRequest.post(`/inventario/pedimentos/pedimento/calculo`, { id_pedimento: id })
)
// 5. Obtener calculos
export const apiGetNewPetitionCalc = (id) => (
    backendRequest.get(`/inventario/costos/prods/pedimento?id_pedimento=${id}`)
)

// Confirmar pedimento
export const apiPatchNewPetitionConfirm = (id) => (
    backendRequest.patch(`/inventario/pedimentos/pedimento/edit`, { id_pedimento: id })
)

// Obtener listado de pedimentos
export const apiGetAllPetitions = () => (
    backendRequest.get(`/inventario/pedimentos/pedimento/allPendientes?page=1&take=500`)
)
// Obtener detalle de pedimento por ID
export const apiGetPetitionById = (id) => (
    backendRequest.get(`/inventario/pedimentos/pedimento/detalles?id_pedimento=${id}`)
)
// Obtener listado de pedimentos ERP
export const apiGetAllPetitionsERP = () => (
    backendRequest.get(`/inventario/pedimentos/pedimento/allPedimentosERP?page=1&take=500`)
)
// Obtener listado de pedimentos ERP detalles
export const apiGetPetitionERPById = (id) => (
    backendRequest.get(`/inventario/pedimentos/detCosto/detPed?id_pedimento_erp=${id}`)
)

/* ------------------------ DASHBOARD ---------------------------*/
// Obtener los productos actuales
export const apiGetProductsAct = () => (
    backendRequest.get(`/inventario/dashboard/currentProducts`)
)
// Obtener las facturas por recibir
export const apiGetInvoice = () => (
    backendRequest.get(`/inventario/dashboard/pendingBills?fechaInicio=&fechaFin=`)
)
// Obtener productos en stock
export const apiGetProductsStock = (id_tipo_movimiento, id_almacen, fecha) => (
    backendRequest.get(`/inventario/dashboard/stockAllProducts?id_tipo_movimiento=${id_tipo_movimiento}&id_almacen=${id_almacen}&fecha=${fecha}`)
)
// Obtener todas las entradas
export const apiGetTickets = () => (
    backendRequest.get(`/inventario/dashboard/allEntradas`)
)
// Obtener todas las salidas
export const apiGetExit = () => (
    backendRequest.get(`/inventario/dashboard/allExits`)
)
// Obtener los indicadores KPI
export const apiGetIndicators = (fecha_inicio, fecha_fin, params) => (
    backendRequest.get(`/inventario/movimientos/salidas/indicadores?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}${params}`)
)
/* ------------------------ SQL TO EXCEL ---------------------------*/
// Obtener los scripts
export const apiGetScripts = () => (
    backendRequest.get(`/inventario/reportes/reporte/getReportesSQL`)
)
//Guardar script
export const apiSaveScript = (data) => (
    backendRequest.post(`/inventario/reportes/reporte/newReporteSQL`, data)
)
// Eliminar script
export const apiDeleteScript = id => (
    backendRequest.delete(`/inventario/reportes/reporte/deleteReporteSQL/${id}`)
);
//Obtener parámetros del script
export const apiGetScript = (id_reporte) => (
    backendRequest.get(`/inventario/reportes/reporte/getReporteSQL?id_reporte=${id_reporte}`)
)
//Generar script
export const apiGenerarScript = (data) => (
    backendRequest.post(`/inventario/reportes/reporte/sqlReport`, data)
)
//Información para editar el Script
export const apiPutScriptData = (data) => (
    backendRequest.put(`/inventario/reportes/reporte/editarReporteSQL`, data)
)
/* ------------------------ ENDPOINTS ---------------------------*/    
// Obtener los Endpoints
export const apiGetEndPoints = () => (
    backendRequest.get(`/inventario/reportes/reporte/getEndpoints`)
)
//Generar Endpoint
export const apiGenerarEndpoint = (data) => (
    backendRequest.post(`/inventario/reportes/reporte/createEndpoint`, data)
)
// Eliminar endpoint
export const apiDeleteEndPoint = id => (
    backendRequest.delete(`/inventario/reportes/reporte/deleteEndpoint/${id}`)
);
//Información Endpoint
export const apiGetEndPoint = (id_endpoint) => (
    backendRequest.get(`/inventario/reportes/reporte/getEndpoint?id_endpoint=${id_endpoint}`)
)
//Información para editar el EndPoint
export const apiPutEndPointData = (data) => (
    backendRequest.put(`/inventario/reportes/reporte/editEndpoint`, data)
)
// Obtener los Tipos de dato
export const apiGetTiposDatos = () => (
    backendRequest.get(`/inventario/reportes/reporte/getTiposDatos`)
)
//Ejecucion el Endpoint por medio de la función
export const apiGetExecute = (name) => (
    backendRequest.get(`${name}`)
)

//Obtener todos los prepedidos
export const apiGetAllPrepedidos = () => (
    backendRequest.get('/inventario/prepedidos')
)

//Borrar Prepedido
export const apiDeletePrepedido = (id) => (
    backendRequest.delete(`/inventario/prepedidos/${id}`)
)

//Obtener prepedido por id
export const apiGetPrepedidoById = (id) => (
    backendRequest.get(`/inventario/prepedidos/${id}`)
)

export const apiPostNewPrepedido = (data) => (
    backendRequest.post('/inventario/prepedidos', data)
)

export const apiPutPrepedido = (id, data) => (
    backendRequest.put(`/inventario/prepedidos/${id}`, data)
)

//Obetern los detalles de un producto para prepedido
export const apiGetDetailsProductPrepedido = (id) => (
    backendRequest.get(`/inventario/producto/${id}`)
)