import { useState, useContext, useEffect } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Switch, useCheckboxState } from 'pretty-checkbox-react';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import { apiGetRoles, apiPostNewUser } from '../../../common/api-general';
// Utilidades
import {
    validateEmail,
    hashText
} from '../../../utilities/funcs';
import { useTranslation } from 'react-i18next';
const AddUser = ({ show, setShow }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 30;
    const s1 = useCheckboxState();
    // Carga de data    
    const [providers, setProviders] = useState([]);
    const { state: s1State } = s1;
    const {t} = useTranslation();
    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        nombres: "",
        apellidos: "",
        email: "",
        username: "",
        password: "",
        id_rol:0,
        confirmpassword: "",
        notificaciones: 0
    });

    // Cargar datos
     // Cargar datos
     useEffect(() => {
        const loadData = async () => {
            try {
                // Informacion de proveedores
                const reqPro = await apiGetRoles();
                setProviders([...reqPro.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setProviders([]);
        }
    }, [setProviders, show]);
     
    // Moficacion de campos
    const onInputChange = e => {
        setData({
            ...data,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }

    // Llena datos con informacion del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

       // Manejo de estados de switch
    useEffect(() => {
        setData(data => ({
            ...data,
            notificaciones: Number(s1State),
        }));
    }, [s1State]);

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });

        if(data.id_rol === '')
        {
            setAlert({
                title: 'Error',
                message: 'Debe seleccionar un rol',
                show: true,
            });
            return false;
        }
        if (data.nombres === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre(s)',
                show: true
            });
            return false;
        }
        if (data.apellidos === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere apellidos(s)',
                show: true
            });
            return false;
        }
        if (data.email === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere email',
                show: true
            });
            return false;
        }
        if (!validateEmail(data.email)) {
            setAlert({
                title: 'Formulario erroneo',
                message: 'El email es incorrecto',
                show: true
            });
            return false;
        }
        if (data.username === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre de usuario',
                show: true
            });
            return false;
        }
        if (data.password === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere contraseña',
                show: true
            });
            return false;
        }
        if (data.confirmpassword === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere confirmación de contraseña',
                show: true
            });
            return false;
        }
        if (data.confirmpassword !== data.password) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Las contraseñas no coinciden',
                show: true
            });
            return false;
        }
        return true;
    }

    // Insertar nuevo usuario
    const _handleSubmit = async (closeModal = true) => {
        if (isFormValid()) {
            try {
                const sendData = {
                    ...data,
                    password: hashText(data.password),
                }
                delete sendData.confirmpassword;
                const response = await apiPostNewUser(sendData);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "EL usuario ha sido agregado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Limpiar datos
                    setData({
                        nombres: "",
                        apellidos: "",
                        email: "",
                        username: "",
                        password: "",
                        confirmpassword: "",
                        notificaciones: 0
                    });
                    s1.state = 0;
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar usuario");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nuevo usuario',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(true);
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="md"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle={t('agregar_usuario')}
                buttonTitle={t('guardar')}
                handleClick={handleSaveClose}
            >
                <Form>
                    <Row>
                        <Col md="6" xs="12">
                            <div className="form-group">
                                <label htmlFor="nombres" className="form-label">
                                    {t('nombre')}(s): *
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nombres"
                                    name="nombres"
                                    value={data.nombres}
                                    onChange={handleFormChange}
                                    placeholder="Juan Pedro"
                                />
                            </div>
                        </Col>
                        <Col md="6" xs="12">
                            <div className="form-group">
                                <label htmlFor="apellidos" className="form-label">
                                    {t('apellido')}(s): *
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="apellidos"
                                    name="apellidos"
                                    value={data.apellidos}
                                    onChange={handleFormChange}
                                    placeholder="Santa Cruz"
                                />
                            </div>
                        </Col>
                        <Col md="6" xs="12">
                            <div className="form-group">
                                <label htmlFor="email" className="form-label">
                                    Email: *
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    value={data.email}
                                    onChange={handleFormChange}
                                    placeholder="usario@mail.com"
                                />
                            </div>
                        </Col>
                        <hr />
                        <Col md="6" xs="12">
                            <div className="form-group">
                                <label htmlFor="username" className="form-label">
                                    {t('nombre_de_usuario')}: *
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    name="username"
                                    value={data.username}
                                    onChange={handleFormChange}
                                    placeholder="usuario001"
                                />
                            </div>
                        </Col>
                        <Col md="6" xs="12">
                            <div className='form-group'>
                                <label htmlFor='id_rol' className='form-label'>
                                    {t('seleccione_rol')}: * 
                                </label>
                                <select
                                className="form-select"
                                name="id_rol"
                                id="id_rol"
                                value={data.id_rol}
                                onChange={onInputChange}
                                >
                                    <option value="0">{t('seleccione_rol')}</option>
                                    {
                                        providers.map(({ id_rol, rol }, index) => (
                                            <option value={id_rol} key={index}>
                                                {rol}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        </Col>
                        <Col md="6" xs="12">
                            <div className="form-group">
                                <label htmlFor="password" className="form-label">
                                    {t('contrasena')}: *
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    value={data.password}
                                    onChange={handleFormChange}
                                    placeholder="*******"
                                />
                            </div>
                        </Col>
                        <Col md="6" xs="12">
                            <div className="form-group">
                                <label htmlFor="confirmpassword" className="form-label">
                                    {t('confirmar_contrasena')}: *
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="confirmpassword"
                                    name="confirmpassword"
                                    value={data.confirmpassword}
                                    onChange={handleFormChange}
                                    placeholder="*******"
                                />
                            </div>
                        </Col>
                       
                        <Col className="mt-30" md="4">
                            <Switch
                                color="success"
                                shape="fill"
                                bigger
                                className="form-label"
                                {...s1}
                            >
                                Recicbir notificacion por correo electronico
                            </Switch>
                        </Col>
                        
                    </Row>
                </Form>
            </Modals>
        </>
    );
}

export default AddUser;