import { useState, useContext } from "react";
import PropTypes from 'prop-types';
import Swal from "sweetalert2";
// Componentes
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import { apiPostNewDependency } from '../../../common/api-invent';
import { useTranslation } from "react-i18next";
const AddDependencyModal = ({ show, setShow, mainTitle, nameDependency, idReloadComponent }) => {
    const {t}=useTranslation();
    const { onReloadComponent } = useContext(ReloadContext);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        nombre: '',
        descripcion: '',
    });

    // Llena el arreglo de datos con los datos del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.nombre === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nombre',
                show: true
            });
            return false;
        }
        return true;
    }

    // Funciones por el usuario
    const handleSubmit = async event => {
        event.preventDefault();

        if (isFormValid()) {
            try {
                const response = await apiPostNewDependency(data, nameDependency);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu información ha sido agregado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Carga de componente
                    onReloadComponent(idReloadComponent);
                    // Limpiar datos
                    setData({
                        nombre: '',
                        descripcion: '',
                    });
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al agregar dependencia");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nuevo almacén',
                    text: error.response.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer show={alert.show}>
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle={mainTitle}
                buttonTitle="Agregar"
                handleClick={handleSubmit}
                size="md"
            >
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md="4">
                            <div className="form-group">
                                <label htmlFor="nombre" className="form-label">
                                    {t('nombre')} : *
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nombre"
                                    name="nombre"
                                    value={data.nombre}
                                    onChange={handleFormChange}
                                    placeholder={`Tipo 1`}
                                />
                            </div>
                        </Col>
                        <Col md="8">
                            <div className="form-group">
                                <label htmlFor="descripcion" className="form-label">
                                    {t('descripcion')} :
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="descripcion"
                                    name="descripcion"
                                    value={data.descripcion}
                                    onChange={handleFormChange}
                                    placeholder={`Descripcion de 1`}
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modals>
        </>
    );
}
// Documentacion Proptypes
AddDependencyModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    mainTitle: PropTypes.string.isRequired,
    nameDependency: PropTypes.string.isRequired,
    idReloadComponent: PropTypes.number.isRequired,
}
export default AddDependencyModal;