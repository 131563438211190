import { useReducer } from "react";
// Contexto
import ReloadContext from "./reload.context";
import ReloadReducer from "./reload.reducer";

const SelectItemState = ({ children }) => {
    const initialState = {
        reloadComp1: 0,         // Contenedor Niveles del rack
        reloadComp2: 0,         // Contenedor Racks
        reloadComp3: 0,         // Contenedor Almacenes
        reloadComp4: 0,         // Contenedor Proveedores
        reloadComp5: 0,         // Contenedor Familias
        reloadComp6: 0,         // Contenedor Tipos de Producto 
        reloadComp7: 0,         // Contenedor Clasificaciones
        reloadComp8: 0,         // Contenedor Todos los Productos
        reloadComp9: 0,         // Contenedor Productos - Cajas
        reloadComp10: 0,        // Contenedor Productos - Codigos
        reloadComp11: 0,        // Contenedor Productos - Caracteristicas
        reloadComp12: 0,        // Contenedor Productos - Propiedades
        reloadComp13: 0,        // Contenedor Productos - Fotografias
        reloadComp14: 0,        // Contenedor Productos - Documentos
        reloadComp15: 0,        // Contenedor Proveedores Nombres (Simple)
        reloadComp16: 0,        // Contenedor ListasPrecio - Info / Documentos
        reloadComp17: 0,        // Contenedor ListasPrecio - Productos
        reloadComp18: 0,        // Contenedor Razones Movimiento
        reloadComp19: 0,        // Contenedor (Facturas) Documentos - Productos Costo
        reloadComp20: 0,        // Contenedor Productos - Razones Almacenamiento
        reloadComp21: 0,        // Contenedor Puestos
        reloadComp22: 0,        // Contenedor Personal
        reloadComp23: 0,        // Contenedor Documentos por recibir
        reloadComp24: 0,        // Contenedor Productos Entrantes
        reloadComp25: 0,        // Contenedor Salidas Detalles
        reloadComp26: 0,        // Contenedor Registro de Salidas Productos
        reloadComp27: 0,        // Contenedor Productos por recibir
        reloadComp28: 0,        // Contenedor Registro Inventario Fisico
        reloadComp29: 0,        // Contenedor Registro Pedaceria
        reloadComp30: 0,        // Contenedor Usuarios de una empresa
        reloadComp31: 0,        // Contenedor Documentos Salida
        reloadComp32: 0,        // Tabla de Clientes
        reloadComp33: 0,        // Contenedor Salidas Detalles Medidas
        reloadComp34: 0,        // Contenedor Pedaceria
        reloadComp35: 0,        // Contenedor Pedaceria Detalles Medidas
        reloadComp36: 0,        // Contenedor de los Sql
        reloadComp37: 0,        // Contenedor de los Endpoints
        reloadComp38: 0,        // Contenedor de los Indicadores

    }
    const [state, dispatch] = useReducer(ReloadReducer, initialState);

    // Seleccionar un producto
    const onReloadComponent = (id) => {
        dispatch({
            idComponent: id
        })
    }

    // --------------------------------------------
    return (
        <ReloadContext.Provider
            value={{
                onReloadComponent,
                reloadComp1: state.reloadComp1,
                reloadComp2: state.reloadComp2,
                reloadComp3: state.reloadComp3,
                reloadComp4: state.reloadComp4,
                reloadComp5: state.reloadComp5,
                reloadComp6: state.reloadComp6,
                reloadComp7: state.reloadComp7,
                reloadComp8: state.reloadComp8,
                reloadComp9: state.reloadComp9,
                reloadComp10: state.reloadComp10,
                reloadComp11: state.reloadComp11,
                reloadComp12: state.reloadComp12,
                reloadComp13: state.reloadComp13,
                reloadComp14: state.reloadComp14,
                reloadComp15: state.reloadComp15,
                reloadComp16: state.reloadComp16,
                reloadComp17: state.reloadComp17,
                reloadComp18: state.reloadComp18,
                reloadComp19: state.reloadComp19,
                reloadComp20: state.reloadComp20,
                reloadComp21: state.reloadComp21,
                reloadComp22: state.reloadComp22,
                reloadComp23: state.reloadComp23,
                reloadComp24: state.reloadComp24,
                reloadComp25: state.reloadComp25,
                reloadComp26: state.reloadComp26,
                reloadComp27: state.reloadComp27,
                reloadComp28: state.reloadComp28,
                reloadComp29: state.reloadComp29,
                reloadComp30: state.reloadComp30,
                reloadComp31: state.reloadComp31,
                reloadComp32: state.reloadComp32,
                reloadComp33: state.reloadComp33,
                reloadComp34: state.reloadComp34,
                reloadComp35: state.reloadComp35,
                reloadComp36: state.reloadComp36,
                reloadComp37: state.reloadComp37,
                reloadComp38: state.reloadComp38,
            }}
        >
            {children}
        </ReloadContext.Provider>
    )
}
export default SelectItemState;