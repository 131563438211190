const visibleFields = {
    id_cliente: false,
    id_almacen: false,
    orden_trabajo: false,
    id_rack: false,
    id_almacen_ubicacion: false,
    id_razones_movimiento_detalles: false,
    comentarios: false,
    medidas: false,
    cantidad: false,
    cm2: true
}
export const fields ={
    // Área placa virgen
    15: {
        visibleFields: {
            ...visibleFields,
            id_cliente: true,
            orden_trabajo: true,
            medidas: true,
            cdi: true
        },
        requiredFields: [
            'id_cliente',
            'orden_trabajo',
            'version',
            'medidas',
            'cdi'
        ]
    },
    // Salida por desperdicio de reposicion
    21: {
        visibleFields: {
            ...visibleFields,
            id_razones_movimiento_detalles: true,
            cantidad: true,
            cm2: false
        },
        requiredFields: [
            'id_razones_movimiento_detalles',
            'cm2'
        ]
    },
    // Salida por reposicion a cliente (Material procesado entregado con defecto)
    16: {
        visibleFields: {
            ...visibleFields,
            id_cliente: true,
            id_razones_movimiento_detalles: true,
            orden_trabajo: true,
            medidas: true,
            comentarios: true,
            cdi: true
        },
        requiredFields: [
            'id_cliente',
            'id_razones_movimiento_detalles',
            'orden_trabajo',
            'version',
            'medidas',
            'cdi'
        ]
    },
    // Trapaso entre ubicaciones (cambiar a otro rack o nivel) 
    17: {
        visibleFields: {
            ...visibleFields,
            id_rack: true,
            id_almacen_ubicacion: true,
            medidas: true
        },
        requiredFields: [
            'id_rack',
            'id_almacen_ubicacion',
            'medidas'
        ]
    },
    // Traspaso entre almacenes
    14: {
        visibleFields: {
            ...visibleFields,
            id_almacen: true,
            id_rack: true,
            id_almacen_ubicacion: true,
            medidas: true
        },
        requiredFields: [
            'id_almacen',
            'id_rack',
            'id_almacen_ubicacion',
            'medidas'
        ]
    },
    // Salida de solvente
    18: {
        visibleFields: {
            ...visibleFields,
            medidas: true
        },
        requiredFields: [
            'medidas'
        ]
    },
    // Salida por calibracion
    19: {
        visibleFields: {
            ...visibleFields,
            cantidad: true,
            cm2: false,
            id_razones_movimiento_detalles: true
        },
        requiredFields: [
            'cm2',
            'id_razones_movimiento_detalles'
        ]
    },
    // Salida por merma/yield
    20: {
        visibleFields: {
            ...visibleFields,
            cantidad: true,
            cm2: false,
        },
        requiredFields: [
            'cm2'
        ]
    },
    // (favor de no utilizar, se conserva por datos historicos) - SALIDA POR DESPERDICIO (CALIBRACIÓN, NUEVO CLIENTE, DAÑO, CADUCIDAD, ETC.)
    9: {
        visibleFields: {
            ...visibleFields,
            cantidad: true,
            cm2: false,
        },
        requiredFields: [
            'cm2'
        ]
    },
    // (favor de no utilizar, se conserva por datos historicos) - SALIDA POR DESPERDICIO (MATERIAL PROCESADO Y NO ENTREGADO)
    12: {
        visibleFields: {
            ...visibleFields,
            cantidad: true,
            cm2: false,
        },
        requiredFields: [
            'cm2'
        ]
    },
    // COMERCIALIZACION
    22: {
        visibleFields: {
            ...visibleFields,
            id_cliente: true,
            orden_trabajo: true,
            medidas: true,
        },
        requiredFields: [
            'id_cliente',
            'orden_trabajo',
            'version',
            'medidas',
        ]
    },
    // Salida de papel
    45: {
        visibleFields: {
            ...visibleFields,
            medidas: true,
            orden_trabajo: true

        },
        requiredFields: [
            'medidas', 
            'orden_trabajo'
        ]
    },
    // SALIDA POR CORRECCIONES
    46: {
        visibleFields:{
            ...visibleFields,
            comentarios: true,
            cm2: false,
        },
        requiredFields: [
            'comentarios',  
            'cm2'
        ]
    },
    // SALIDA POR INVENTARIO FISICO
    47: {
        visibleFields: {
            ...visibleFields,
            cantidad: true,
            cm2: false,
        },
        requiredFields: [
            'cm2'
        ]
    },

    0: {
        visibleFields: {
            ...visibleFields
        }
    }
}

export default fields;