const crypto = require('crypto');
const password = process.env.REACT_APP_SECRET_SQL;
const salt = crypto.randomBytes(16);
const key = crypto.pbkdf2Sync(password, salt, 100000, 32, 'sha256');
const iv = crypto.randomBytes(16);

// Cifrado
export const encrypt_script = async (script) => {
    let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv);
    let encrypted = cipher.update(script);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return { iv: iv.toString('hex'), salt: salt.toString('hex'),encryptedData: encrypted.toString('hex') };
}

export const decrypt_script = async (script_json) => {
    const json = JSON.parse(script_json);
    let {iv,encryptedData,salt} = json;
    iv = Buffer.from(iv,'hex');
    encryptedData = Buffer.from(encryptedData,'hex');
    salt = Buffer.from(salt,'hex');
    
    let key = crypto.pbkdf2Sync(password, salt, 100000, 32, 'sha256');
    key = Buffer.from(key);
    
    const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
    let plaintext = decipher.update(encryptedData);
    
    plaintext = Buffer.concat([plaintext,decipher.final()]); // Posible BoF
    
    console.log(plaintext.toString('utf8'));
    return plaintext.toString();
}