import { useState, useEffect } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Iconos
import { ReactComponent as IconExcel } from '../../../assets/svg/icon-excel.svg'
import { ReactComponent as IconPdf } from '../../../assets/svg/icon-pdf.svg'
// Conexion Api
import {
    apiGetAllProviders,
    apiPostNewReportProducts,
} from '../../../common/api-invent';

const ReportProduct = ({ show, setShow }) => {

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [providers, setProviders] = useState([]);

    // Hook para formulario
    const [data, setData] = useState({
        id_proveedor: '0',
        fecha_termino: '',
        format: ''
    });

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Informacion de proveedores
                const reqPro = await apiGetAllProviders(1);
                setProviders([...reqPro.data.content]);
                setLoading(false);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData();
        return () => { }
    }, [setLoading, show]);

    // Llena datos con informacion del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }
    const onSelectFormat = e => {
        setData({
            ...data,
            format: e.target.id
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.format === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere formato',
                show: true
            });
            return false;
        }
        if (data.id_proveedor === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere proveedor',
                show: true
            });
            return false;
        }
        if (data.fecha_termino === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere fecha de termino',
                show: true
            });
            return false;
        }
        return true;
    }

    // Generar reporte
    const handleSubmit = async e => {
        e.preventDefault();

        if (isFormValid()) {
            try {
                setLoading(true);
                const response = await apiPostNewReportProducts(data);
                
                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu reporte ha sido generado exitosamente",
                        icon: "success",
                        confirmButtonText: 'Aceptar'
                    });
                    // Limpiar datos
                    setData({
                        id_proveedor: '0',
                        fecha_termino: '',
                        format: ''
                    });
                    // Descargar / mostrar reporte
                    window.open(response.data.content, '_blank');
                    // Cerrar modal
                    setShow(false);
                    setLoading(false);
                } else {
                    throw new Error("Error al agregar reporte");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al generar reporte',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="md"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Generar reporte de productos"
                buttonTitle="Generar"
                handleClick={handleSubmit}
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="50px"
                            marginTop="50px"
                        />
                        : <Form>
                            <Row>
                                <Col xs md="6">
                                    <div className="form-group">
                                        <label htmlFor="id_proveedor" className="form-label">
                                            Proveedor: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_proveedor"
                                            id="id_proveedor"
                                            value={data.id_proveedor}
                                            onChange={handleFormChange}
                                        >
                                            <option value="0">Seleccione un proveedor</option>
                                            {
                                                providers.map(({ id_proveedor, nombre }, index) => (
                                                    <option value={id_proveedor} key={index}>
                                                        {nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                <Col xs md="6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_termino" className="form-label">
                                            Fecha Término: *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="fecha_termino"
                                            name="fecha_termino"
                                            value={data.fecha_termino}
                                            onChange={handleFormChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs md="12">
                                    <div className="form-group">
                                        <label className="form-label">
                                            Elige el formato de tu reporte:
                                        </label>
                                    </div>
                                </Col>
                                <Col xs="6" md="4">
                                    <input name="format" type="radio" id="excel" onChange={onSelectFormat} />
                                    <label htmlFor="excel" style={{ display: "flex", gap: "10px" }}>
                                        <IconExcel /> Excel
                                    </label>
                                </Col>
                                <Col xs="6" md="4">
                                    <input name="format" type="radio" id="pdf" onChange={onSelectFormat} />
                                    <label htmlFor="pdf" style={{ display: "flex", gap: "10px" }}>
                                        <IconPdf />  Pdf
                                    </label>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default ReportProduct;