import { useEffect } from 'react';
import reactDom from 'react-dom';
import { PropTypes } from 'prop-types';
// Componentes
import {
    Toast,
    ToastContainer
} from 'react-bootstrap';
// Estilos personalizados
import './toast.styled.css';

// Componente para superponerse a un modal de bootstrap
const ToastModalContainer = ({ children, show }) => {
    const portalNode = document.createElement('div');

    useEffect(() => {
        portalNode.style = `
            position: fixed;
            z-index: 1100;
            height: auto;
            width: 325px;
            top: 0;
            right: 0;
        `;
        document.body.appendChild(portalNode);
        return () => {
            portalNode.remove();
        }
    }, [portalNode]);

    if (!show) {
        return <></>;
    }
    return reactDom.createPortal(
        <ToastContainer className="p-3" position="top-end">
            {children}
        </ToastContainer>,
        portalNode
    )
}

const ToastComponent = ({ type, title, message, show, onClose }) => {
    return (
        <Toast
            bg={type}
            show={show}
            autohide={true}
            delay={3000}
            onClose={onClose}
        >
            <Toast.Header
                closeVariant="white">
                {
                    <h5 className="mb-0">
                        {title}
                    </h5>
                }
            </Toast.Header>
            <Toast.Body>
                {message}
            </Toast.Body>
        </Toast>
    )
}

// Documentacion con proptypes
ToastComponent.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

export { ToastModalContainer };
export default ToastComponent;