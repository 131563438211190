// Dependencias
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// Estilos
import './mainHeader.styled.css';
// Imagenes
import LogoLetter from '../../assets/images/logo-letter.png'
// Iconos
import { ReactComponent as IconMenu } from '../../assets/svg/icon-menu.svg'
//import { ReactComponent as IconSettings } from '../../assets/svg/icon-settings.svg'
//import { ReactComponent as IconSearch } from '../../assets/svg/icon-search.svg'
import { ReactComponent as IconPlus } from '../../assets/svg/icon-plus.svg'
import { ReactComponent as IconDocument } from '../../assets/svg/icon-document.svg'
import { ReactComponent as IconHelp } from '../../assets/svg/icon-help.svg'
import { ReactComponent as IconLogout } from '../../assets/svg/icon-logout.svg'
import { ReactComponent as IconNotification } from '../../assets/svg/icon-notification.svg';
import { ReactComponent as IconTranslate } from '../../assets/svg/icons8-translate-24.svg';
import { ReactComponent as IconBot } from '../../assets/svg/icon-chat.svg';
import moment from 'moment';
// Modales para agregar
import AddStoreModal from '../Modals/Add/addStore.modal';
import AddRackModal from '../Modals/Add/addRack.modal';
import AddLevelModal from '../Modals/Add/addLevel.modal';
import AddProductModal from '../Modals/Add/addProduct.modal';
import AddDependencyModal from '../Modals/Add/addDependency.modal';
import AddProviderModal from '../Modals/Add/addProvider.modal';
import AddProdBox from '../Modals/Add/addProdBox.modal';
import AddProdCode from '../Modals/Add/addProdCode.modal'
import AddProdFeat from '../Modals/Add/addProdFeat.modal';
import AddProdProperties from '../Modals/Add/addProdProperties.modal';
import AddProdPicture from '../Modals/Add/addProdPicture.modal';
import AddProdDocument from '../Modals/Add/addProdDocument.modal';
import AddProdStorageRule from '../Modals/Add/addProdStorageRule.modal';
import AddListPricesDoc from '../Modals/Add/addListPricesDoc.modal';
import AddListPricesProds from '../Modals/Add/addListPricesProds.modal';
import AddMovReason from '../Modals/Add/addMovReason.modal';
import AddBillProds from '../Modals/Add/addBillProds.modal';
import AddRol from '../Modals/Add/addRol.modal';
import AddStaff from '../Modals/Add/addStaff.modal';
import AddEntranceByLevel from '../Modals/extra/addEntranceByLevel.modal';
import AddExitByLevel from '../Modals/extra/addExitByLevel.modal';
import AddUser from '../Modals/Add/addUser.modal';
import AddPhysicalInvent from '../Modals/Add/addPhysicalInvent.modal';
import AddScrap from '../Modals/Add/addScrap.modal';
import AddExitDocument from '../Modals/Add/addExitDocument.modal';
import AddDocsPetitions from '../Modals/Add/addDocsPetitions.modal';
import AddClient from '../Modals/Add/addClient.modal';
import AddExitPlate from '../Modals/extra/addExitPlate.modal';
import AddEndPoint from '../Modals/Add/addEndPoint.modal';
import Traspasos from '../Modals/Add/traspasos/Traspasos.modal';
import AddPrepedidos from '../Modals/extra/prepedidos.modal'

// Modales de reporte
import ReportProduct from '../Modals/Report/reportProduct.modal';
import ReportStores from '../Modals/Report/reportStores.modal';
import ReportScrap from '../Modals/Report/reportScrap.modal';
import ReportPhysicalInvent from '../Modals/Report/reportPhysicalInvent.modal';
import ReportExit from '../Modals/Report/reportExit.modal';
import ReportInventFact from '../Modals/Report/reportInventFact.modal';
import ReportCostoPromedio from '../Modals/Report/reportCostoPromedio.modal';
import ReportTicketsPriceLists from '../Modals/Report/reportTicketsByPurchaseVsPriceLists.modal';
import ReportSQL from '../Modals/Report/reportSql.modal';
import Polizas from '../Modals/Report/poliza.modal';
import { useTranslation } from 'react-i18next';
// Utilidades
import { BaseDashBoard } from '../../utilities/constants'
// Context
import AuthContext from '../../context/auth/auth.context';

//notificaciones
import {apiGetNotifications} from '../../common/api-general';
//Traducción
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from '../../locales/en.json';
import esTranslations from '../../locales/es.json';
import { apiPostNewPrepedido } from '../../common/api-invent';


i18n
    .use(initReactI18next)
    .init({
        resources: {
        en: {
            translation: enTranslations
        },
        es: {
            translation: esTranslations
        }},
        lng: 'es',
        fallbackLng: 'es',
        interpolation: {
        escapeValue: false
        }
    });


const ItemButton = ({ showMenu, text, idModal, icon }) => {
    const [show, setShow] = useState(false);
    const {t} = useTranslation();
    let children = <></>;
    switch (idModal) {
        case 1:
            // Agregar Almacen
            children = <AddStoreModal show={show} setShow={setShow} />
            break;
        case 2:
            // Agregar Rack
            children = <AddRackModal show={show} setShow={setShow} />
            break;
        case 3:
            // Agregar Nivel
            children = <AddLevelModal show={show} setShow={setShow} />
            break;
        case 4:
            // Agregar Producto
            children = <AddProductModal show={show} setShow={setShow} />
            break;
        case 5:
            // Agregar Familia
            children = <AddDependencyModal
                nameDependency="familia"
                mainTitle={t('agregar')+" "+t('familia')}
                show={show}
                setShow={setShow}
                idReloadComponent={5}
            />
            break;
        case 6:
            // Agregar Tipo producto
            children = <AddDependencyModal
                nameDependency="tipo_producto"
                mainTitle={t('agregar')+" "+t('tipo_producto')}
                show={show}
                setShow={setShow}
                idReloadComponent={6}
            />
            break;
        case 7:
            // Agregar Clasificacion
            children = <AddDependencyModal
                nameDependency="clasificacion"
                mainTitle="Agregar Clasificación"
                show={show}
                setShow={setShow}
                idReloadComponent={7}
            />
            break;
        case 8:
            // Agregar Clasificacion
            children = <AddProviderModal show={show} setShow={setShow} />
            break;
        case 9:
            // Agregar Caja de Producto
            children = <AddProdBox show={show} setShow={setShow} />
            break;
        case 10:
            // Agregar Codigo de Producto
            children = <AddProdCode show={show} setShow={setShow} />
            break;
        case 11:
            // Agregar Característica de Producto
            children = <AddProdFeat show={show} setShow={setShow} />
            break;
        case 12:
            // Agregar Propiedad de Producto
            children = <AddProdProperties show={show} setShow={setShow} />
            break;
        case 13:
            // Agregar Foto de Producto
            children = <AddProdPicture show={show} setShow={setShow} />
            break;
        case 14:
            // Agregar Documento de Producto
            children = <AddProdDocument show={show} setShow={setShow} />
            break;
        case 15:
            // Agregar Lista Precios con documento
            children = <AddListPricesDoc show={show} setShow={setShow} />
            break;
        case 16:
            // Agregar Lista Precios dinamica
            children = <AddListPricesProds show={show} setShow={setShow} />
            break;
        case 17:
            // Agregar Razón de Movimiento
            children = <AddMovReason show={show} setShow={setShow} />
            break;
        case 18:
            // Agregar Documento Costos
            children = <AddBillProds show={show} setShow={setShow} />
            break;
        case 19:
            // Agregar Regla de Almacenamiento
            children = <AddProdStorageRule show={show} setShow={setShow} />
            break;
        case 20:
            // Agregar Puesto de trabajo
            children = <AddRol show={show} setShow={setShow} />
            break;
        case 21:
            // Agregar Personal
            children = <AddStaff show={show} setShow={setShow} />
            break;
        case 22:
            // Reporte de producto
            children = <ReportProduct show={show} setShow={setShow} />
            break;
        case 23:
            // Reporte de producto
            children = <ReportStores show={show} setShow={setShow} />
            break;
        case 24:
            // Entrada por nivel
            children = <AddEntranceByLevel show={show} setShow={setShow} />
            break;
        case 25:
            // Salida por nivel
            children = <AddExitByLevel show={show} setShow={setShow} />
            break;
        case 26:
            // Agregar nuevo usuario
            children = <AddUser show={show} setShow={setShow} />
            break;
        case 27:
            // Agregar nuevo inventario fisico
            children = <AddPhysicalInvent show={show} setShow={setShow} />
            break;
        case 28:
            // Agregar documento de salida
            children = <AddExitDocument show={show} setShow={setShow} />
            break;
        case 29:
            // Agregar pedaceria
            children = <AddScrap show={show} setShow={setShow} />
            break;
        case 30:
            // Reporte de Pedaceria
            children = <ReportScrap show={show} setShow={setShow} />
            break;
        case 31:
            // Reporte de Inventario Fisico
            children = <ReportPhysicalInvent show={show} setShow={setShow} />
            break;
        case 32:
            // Agregar Pedimento
            children = <AddDocsPetitions show={show} setShow={setShow} />
            break;
        case 33:
            // Agregar Cliente
            children = <AddClient show={show} setShow={setShow} />
            break;
        case 34:
            // Agregar Cliente
            children = <ReportExit show={show} setShow={setShow} />
            break;
        case 37:
            // Salida
            children = <AddExitPlate show={show} setShow={setShow} />
            break;
        case 39:
            // Reporte de Inventario Facturacion
            children = <ReportInventFact show={show} setShow={setShow} />
            break;
        case 40:
            // Reporte Valuación Costo Promedio
            children = <ReportCostoPromedio show={show} setShow={setShow} />
            break;
        case 41:
            // Reporte Entradas por compra Vs Listas Precio
            children = <ReportTicketsPriceLists show={show} setShow={setShow} />
            break;
        case 42:
            // Reporte Entradas por compra Vs Listas Precio
            children = <ReportSQL show={show} setShow={setShow} />
            break;
        case 43:
            children = <Polizas show={show} setShow={setShow}/>
            break;
        case 44:
            // Agregar EndPoint
            children = <AddEndPoint show={show} setShow={setShow} />
            break;
        case 45:
            children = <Traspasos show={show} setShow={setShow} />
            break;
        case 46:
            children = <AddPrepedidos show={show} setShow={setShow} onSave={async (data) =>{
                const prepedido = await apiPostNewPrepedido(data)
                return prepedido
            }}/>
            break;
        default:
            break;
    }
    return (
        <li>
            <button onClick={() => {
                setShow(!show);
                showMenu(false);
            }}>
                <i className={icon} />
                {text}
            </button>
            {children}
        </li>
    )
}





const MainHeader = ({ contentPlusButton, contentReportButton, contentManualButton, onMenuButton }) => {
    // Extraer valores del context para logOut
    const { logOutUser } = useContext(AuthContext);
    // Hooks para manejo de botones
    const [showMenuRight, setShowMenuRight] = useState({
        user: false,
        notification: false,
        translate: false
    });
    
    const [showActions, setShowActions] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [showManual, setShowManual] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(1)
    const [showModal, setShowModal] = useState(false);
    

    const [l1,setL1] = useState('es');

    useEffect(() => {
        i18n
            .use(initReactI18next)
            .init({
                resources: {
                en: {
                    translation: enTranslations
                },
                es: {
                    translation: esTranslations
                }},
                lng: l1,
                fallbackLng: l1,
                interpolation: {
                escapeValue: false
                }
            });
    }, [l1])

    // Cerrar sesion
    const logOut = () => {
        logOutUser();
    }
    
    const changeLanguage = (e, lg) => {
        setL1(lg);
    }  
    
    // ----------------------------------------
    //    Eventos activados por el usuario
    // ----------------------------------------
    // Click en boton de Más
    const onClickActions = () => {
        setShowActions(!showActions);
        setShowReport(false);
    }
    
    // Click en boton de Manual
    const onClickManual = () => {
        setShowManual(!showManual)
        setShowReport(false)
        setShowActions(false)
    }

    // Click en boton de Reportes
    const onClickReports = () => {
        setShowReport(!showReport)
        setShowManual(false)
        setShowActions(false)
    }

    // Click en boton de notificaciones
    const onClickNotification = async e => {
        setShowMenuRight({
            user: false,
            notification: !showMenuRight.notification
        });
        if(!showMenuRight.notification){
            const params = `?page=${page}`
            const notifications = await apiGetNotifications(params);
            setNotifications(notifications.data.content);
        }else{ 
            setPage(1);
        }
    }

    const onScrollNotificaciones = async ({target}) => {
        if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
            setPage(page+1)
            const params = `?page=${page+1}`
            const response = await apiGetNotifications(params);
            setNotifications([
                ...notifications,
                ...response.data.content
            ]);
        }
    }

    // Click Boton del usuario 
    const onClickUser = e => {
        setShowMenuRight({
            user: !showMenuRight.user,
            notification: false,
            translate: false
        });
    }

    // Click Boton del translate 
    const onClickTranslate = e => {
        setShowMenuRight({
            user: false,
            notification: false,
            translate: !showMenuRight.translate
        });
    }

    // Click Boton del translate 
    const onClickChatGpt = () => {    
      setShowModal(true)                
    }

    return (
        <header className="main-header">
            {/* Logotipos  */}
            <div className="d-flex align-items-center logo-box justify-content-start">
                <Link to={BaseDashBoard + process.env.REACT_APP_PAGE_HOME} className="logo">
                    <div className="logo-mini w-50">
                        <span className="light-logo">
                            <img src={LogoLetter} alt="logo" />
                        </span>
                    </div>
                    <div className="logo-lg" >
                        <h4 className="dark-logo">
                            Inventarios Feraz
                        </h4>
                    </div>
                </Link>
            </div>
            {/* Menu Superior */}
            <nav className="navbar navbar-static-top">
                <div className="app-menu">
                    <ul className="header-megamenu nav">
                        <li className="btn-group nav-item">
                            <button
                                onClick={onMenuButton}
                                className="waves-effect waves-light nav-link push-btn btn-primary-light">
                                <IconMenu />
                            </button>
                        </li>
                        {/*<li className="btn-group d-lg-inline-flex d-none">
                            <div className="app-menu">
                                <div className="search-bx mx-5">
                                    <form>
                                        <div className="input-group">
                                            <input
                                                type="search"
                                                className="form-control"
                                                placeholder="Buscar..."
                                            />
                                            <div className="input-group-append">
                                                <button className="btn" type="submit" id="button-addon3">
                                                    <IconSearch />
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </li>*/}
                        {/* Boton de manual */}
                        {contentManualButton.length > 0
                            ? <li className="dropdown menu-right btn-group nav-item">
                                <button
                                    onClick={onClickManual}
                                    className="waves-effect full-screen waves-light btn-info-light">
                                    <IconHelp />
                                </button>
                                <ul
                                    className={'dropdown-menu animated flipInX ' + (showManual ? 'show' : '')}>
                                    <li>
                                        <div className="slimScrollDiv">
                                            <ul className="menu sm-scrol p-10">
                                                {
                                                    contentManualButton.map(({ link }, index) => (
                                                        <a key={index} href={link} target="_blank" rel="noreferrer">
                                                            <i className="si si-doc me-10" />
                                                            Abrir Manual
                                                        </a>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            : <></>
                        }
                        {/* Boton de acciones */}
                        {
                            contentPlusButton.length > 0
                            ?   <li className="dropdown menu-right btn-group nav-item">
                                    <button
                                        onClick={onClickActions}
                                        className="waves-effect full-screen waves-light btn-danger-light">
                                        <IconPlus />
                                    </button>
                                    <ul
                                        className={'dropdown-menu animated flipInX ' + (showActions ? 'show' : '')}>
                                        <li>
                                            <div className="slimScrollDiv">
                                                <ul className="menu sm-scrol p-10">
                                                    {
                                                        contentPlusButton.map(({ name, idModal }, index) => (
                                                            <ItemButton
                                                                key={index}
                                                                text={name}
                                                                idModal={idModal}
                                                                showMenu={setShowActions}
                                                                icon="si si-plus"
                                                            />
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                : <></>
                        }
                        {
                            contentReportButton.length > 0
                                ?
                                <li className="dropdown menu-right btn-group nav-item">
                                    <button
                                        onClick={onClickReports}
                                        className="waves-effect full-screen waves-light btn-warning-light">
                                        <IconDocument />
                                    </button>
                                    <ul
                                        className={'dropdown-menu animated flipInX ' + (showReport ? 'show' : '')}>
                                        <li>
                                            <div className="slimScrollDiv">
                                                <ul className="menu sm-scrol p-10">
                                                    {
                                                        contentReportButton.map(({ name, idModal }, index) => (
                                                            <ItemButton
                                                                key={index}
                                                                text={name}
                                                                idModal={idModal}
                                                                showMenu={setShowActions}
                                                                icon="si si-notebook"
                                                            />
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                : <></>
                        }
                    </ul>
                </div>
                <div className="navbar-custom-menu r-side">
                    <ul className="nav navbar-nav">
                        {/* Boton Traducción */}
                        <li className="dropdown nav-item">
                            <button
                                onClick={onClickTranslate}
                                className="waves-effect full-screen waves-light btn-danger-light">
                                <IconTranslate />
                            </button>
                            <ul
                                className={'dropdown-menu animated bounceIn ' + (showMenuRight.translate ? 'show' : '')}>
                                <li className="user-body">
                                    <button
                                        onClick={e => changeLanguage(e, "es")}
                                        className="dropdown-item">
                                        <i className="si si-check" />
                                        Español
                                    </button>
                                    <button
                                        onClick={e => changeLanguage(e, "en")}
                                        className="dropdown-item">
                                        <i className="si si-check" />
                                        Inglés
                                    </button>
                                </li>
                            </ul>
                        </li>
                        {/* Boton de Notificaciones */}
                        <li className="dropdown notifications-menu">
                            <button
                                onClick={onClickNotification}
                                className="waves-effect full-screen waves-light btn-info-light">
                                <IconNotification />
                            </button>
                            <ul className={'dropdown-menu animated bounceIn ' + (showMenuRight.notification ? 'show' : '')}>
                                <li className="header">
                                    <div className="p-20">
                                        <div className="flexbox">
                                            <div>
                                                <h4 className="mb-0 mt-0">Notificaciones</h4>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="slimScrollDiv" style={{position: "relative", overflow: "hidden", width: "auto", height: "250px"}}>
                                        <ul className="menu sm-scrol p-10 scroll-invisible" onScroll={onScrollNotificaciones} id='notificaciones' style={{overflowY: "scroll", width: "auto", height: "250px"}}>
                                            {
                                                notifications.map(( {mensaje, fecha}, index) => (
                                                    <li key={index}>
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <i className="si si-exclamation text-warning" style={{marginRight: '7px'}}></i>
                                                            <p style={{color:'rgba(255, 255, 255, 0.85)', margin:'0'}}>{mensaje}</p>
                                                        </div>
                                                        <p style={{color:'rgba(255, 255, 255, 0.85)', textAlign: 'end'}}>{moment(fecha).format("D-M-YY HH:mm")}</p>
                                                        <div style={{ borderTop: '1px solid black' ,height: '2px', maxWidth: '200px', padding:'0', marginLeft: '23px', backgroundColor: '#b5b5c3'}}></div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                        <div
                                            className="slimScrollBar"
                                            style={{background: "rgb(0, 0, 0)", width: "7px", position: "absolute", top: "30px", opacity: "0.1", display: "none", borderRadius: "7px", zIndex: "99", right: "1px", height: "220.07px"}}
                                        >
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        {/* Boton Usuario */}
                        <li className="dropdown nav-item">
                            <button
                                onClick={onClickUser}
                                className="waves-effect full-screen waves-light btn-danger-light">
                                <IconLogout />
                            </button>
                            <ul
                                className={'dropdown-menu animated bounceIn ' + (showMenuRight.user ? 'show' : '')}>
                                <li className="user-body">
                                    <button
                                        onClick={logOut}
                                        className="dropdown-item">
                                        <i className="si si-lock" />
                                        Cerrar Sesión
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav >
        </header >
    );
}

// Documentacion con proptypes
MainHeader.propTypes = {
    onMenuButton: PropTypes.func.isRequired,
    contentPlusButton: PropTypes.array,
    contentReportButton: PropTypes.array,
    contentManualButton: PropTypes.array,
}
MainHeader.defaultProps = {
    contentPlusButton: [],
    contentReportButton: [],
    contentManualButton: [],
}

export default MainHeader;