import { useState, useContext, useEffect } from 'react';
import Swal from 'sweetalert2';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import { apiGenerarScript, apiGetScript, apiGetEndPoints, apiGetEndPoint, apiGetExecute } from '../../../common/api-invent';
import { useTranslation } from 'react-i18next';
const EditReportSQL = ({ show, setShow, id }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 36;
    const [numCampos, setnumCampos] = useState(0);
    const [arreglo, setArreglo] = useState([]);
    // Carga de data    
    const [endpoints, setEndpoints] = useState([]);
    const { t } =  useTranslation();
    let parametrosData = []

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        id_reporte:"",
        parametros: [],
    });

    

    const onChangeSelectEndPoint = async (index, e) =>{
        try {
            // Informacion del endpoint
            const reqPro = await apiGetEndPoint(e.target.value);
            
            const ejecutarPath = await apiGetExecute(reqPro.data.content.path)
            const elemento = document.getElementById("resEndpoint"+index);
            const label = document.getElementById("resEndpointLabel"+index)
            label.classList.remove("select-display");
            elemento.classList.remove("select-display");
            ejecutarPath.data.content.map((item)=>{
                const option = document.createElement("option");
                option.value = JSON.stringify(item);
                option.text = JSON.stringify(item);
                return elemento.appendChild(option);
            })
            //setResEndpoint(ejecutarPath.data.content);
        } catch (e) {
            console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al ejecutar el EndPoint, verifique que la ruta asociada sea correcta.',
                    confirmButtonText: 'Aceptar',
                });
        }
    }
    
    // Cargar datos
    useEffect(() => {
        
        const loadData = async () => {
            try {
                // Informacion de proveedores
                const reqPro = await apiGetScript(id);
                if(reqPro.data.content.parametros.length === 0)
                {
                    setData({
                        ...data,
                        id_reporte: id
                    })
                    setnumCampos(0);
                    setArreglo([]);
                }else{
                    setArreglo([...reqPro.data.content.parametros]);
                    setnumCampos(reqPro.data.content.parametros.length);
                }
                
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: t('error_cargar_datos'),
                    confirmButtonText: t('aceptar'),
                });
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setArreglo([]);
        }
    }, [setData, setArreglo, show, id, data]);

    const changeItem  = async (value, parametro, tipo_dato, index) => {
        let conversion = JSON.parse(value) //entires  a conveersion
        let parametrosData2 = {
            "parametro":parametro,
            "valor": conversion[parametro],
            "tipo_dato":tipo_dato
        } 
        parametrosData = [...parametrosData,parametrosData2]
        data.parametros=[...data.parametros, JSON.parse(JSON.stringify(parametrosData2))];
        data.id_reporte=id;
    }
    
    // Cargar endpoints
    useEffect(() => {
        const loadData = async () => {
            try {
                // Informacion de proveedores
                const reqPro = await apiGetEndPoints();
                setEndpoints([...reqPro.data.content]);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setEndpoints([]);
        }
    }, [setEndpoints, show]);


    //Obtener params dinámicamente
    const getParams = (index, e, tipo_dato) => 
    {
        
        let parametrosData2 = {
            "parametro":e.currentTarget.name,
            "valor": e.currentTarget.value,
            "tipo_dato":tipo_dato
        }
        parametrosData[index] = parametrosData2
        data.parametros=JSON.parse(JSON.stringify(parametrosData));
        data.id_reporte=id;
     }
     
     //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.parametros === []) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Llene todos los parámetros',
                show: true
            });
            return false;
        }
        
        return true;
    }

    // Generar nuevo Reporte
    const _handleSubmit = async (closeModal = true) => {
        if (isFormValid()) 
        {
            try {
                const response = await apiGenerarScript(data);
                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Reporte generado correctamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp); 
                    //Limpiar datos
                    setData({
                        id_reporte:"",
                        parametros: [],
                    })
                    setArreglo([]);
                    setEndpoints([]);
                    window.open(response.data.content,'_blank');
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar el script");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nuevo script',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(true);
    }

    // Guardar y limpiar
    const handleSave = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(false);
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="xl"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Parametros del script"
                buttonTitle={t('generar')+" "+t('reporte')}
                handleClick={handleSaveClose}
                //auxButtonTitle="Agregar y guardar"
                handleAuxButton={handleSave}
            >
                <Form>
                    <Row>
                    {
                            numCampos > 0 
                            ?
                            arreglo.map((item, index) => 
                                
                                <div className='row mt-5'>
                                    <Col md="6" xs="6">
                                        <label htmlFor="nombre" className="form-label">
                                            {t('parametro')}: 
                                        </label>
                                        <div className="form-group mt-5">
                                            <input
                                                name={item.parametro}
                                                id={item.parametro}
                                                type="text"
                                                className="form-control"
                                                placeholder={item.parametro}
                                                value={item.parametro}
                                                key={item.parametro}
                                                disabled
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <label htmlFor="nombre" className="form-label">
                                            {t('valor')}:* 
                                        </label>
                                        <div className="form-group mt-5">
                                            { item.tipo_dato_tipo === 'Fecha' || item.tipo_dato_tipo === 'String' || item.tipo_dato_tipo === 'Number' ?
                                                <input
                                                name={item.parametro}
                                                key={item}
                                                id={item.parametro}
                                                type={item.tipo_dato_tipo === 'Fecha' ? 'date' : item.tipo_dato_tipo === 'String' ? 'text' : 'number'}
                                                className="form-control"
                                                onChange={e => getParams(index, e, item.tipo_dato_tipo)}
                                                />
                                                :
                                                <select name={item.parametro+"Select"} key={index} className="form-select" onChange={e => onChangeSelectEndPoint(index, e)}>
                                                <option value="0">{t('seleccione')} EndPoint</option>
                                                {
                                                    endpoints.map((item)=>
                                                        <option path={item.path} value={item.id_endpoint}>{item.nombre}</option>
                                                    )
                                                }
                                                </select>
                                            }
                                            
                                        </div>
                                    </Col>
                                    {  item.tipo_dato_tipo === 'Endpoint'
                                        ?
                                        <Col md="12" xs="12">
                                            <label htmlFor={"resEndpoint"+index} id={"resEndpointLabel"+index} className="form-label select-display">
                                                {t('seleccione')}:* 
                                            </label>
                                            <select name={"resEndpoint"+index} id={"resEndpoint"+index}  className="form-select select-display" onChange={e=>changeItem(e.target.value, item.parametro,item.tipo_dato_tipo, index)}>
                                        
                                                <option value="0">{t('seleccione')}</option>
                                                
                                        </select>
                                            
                                        </Col>
                                    :<></>
                                    }
                                    
                                </div>

                            )
                            : <>
                                 <div className='row mt-5'>
                                    <Col md="6" xs="6">
                                        <label htmlFor="nombre" className="form-label">
                                            {t('parametros')}: 
                                        </label>
                                        <div className="mt-5">
                                            <p> {t('no_parametros')} </p>
                                        </div>
                                    </Col>
                                </div>
                            </>
                        }
                    </Row>
                </Form>
            </Modals>
        </>
    );
}

export default EditReportSQL;