import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Context
import SelectItemContext from '../../../context/selectItem/selectItem.context';
import ReloadContext from "../../../context/reload/reload.context";
import { useTranslation } from 'react-i18next';
// Conexion Api
import {
    apiGetAllProducts,
    apiGetMagnitudsAll,
    apiGetUnitFromMagnitud,
    apiGetMeasureFromMagnitud,
    apiPostPropiertiesProduct,
} from '../../../common/api-invent';

const AddProdProperties = ({ show, setShow }) => {
    const { selectedProduct } = useContext(SelectItemContext);
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 12;
    const{t}=useTranslation();
    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [magnitudes, setMagnitudes] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [units, setUnits] = useState([]);

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        id_producto: '0',
        id_magnitud: '0',
        propiedades: {},
    });
    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Obtener todos los prodcutos disponibles
                const reqProducts = await apiGetAllProducts();
                setProducts([...reqProducts.data.content]);
                // Obtener magnitudes
                const reqMag = await apiGetMagnitudsAll();
                setMagnitudes([...reqMag.data.content]);
                setLoading(false);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos',
                    text: 'Intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setProducts([]);
            setLoading(false);
        }
    }, [setProducts, setLoading, show]);

    // Cambiar id de producto seleccionado
    useEffect(() => {
        setData(data => ({ ...data, id_producto: selectedProduct }));
    }, [selectedProduct]);

    // Seleccionar maginutd
    const onSelectMagnitud = async e => {
        const id_magnitud = Number(e.target.value);

        try {
            setLoading(true);
            // Obtener medidas de magnitud
            const reqMe = await apiGetMeasureFromMagnitud(id_magnitud);
            setMeasures([...reqMe.data.content])
            const arrNewProps = {};
            reqMe.data.content.forEach((element) => {
                arrNewProps[element.mag_id_medida] = {
                    id_medida: element.mag_id_medida,
                    id_unidad: 0,
                    valor: 0
                };
            })
            // Obtener unidades de magnitud
            const reqUn = await apiGetUnitFromMagnitud(id_magnitud);
            setUnits([...reqUn.data.content]);
            // Objeto con valores
            setData({
                ...data,
                id_magnitud,
                propiedades: { ...arrNewProps },
            });
            setLoading(false);
        } catch (error) {
            console.log(`Ocurrio un error:\n${error}`)
            Swal.fire({
                icon: 'error',
                title: 'Error al cargar datos',
                text: 'Intentelo despúes',
                confirmButtonText: 'Aceptar',
            });
        }
    }

    // Seleccionar productos
    const onSelectProduct = e => {
        setData({
            ...data,
            [e.target.name]: Number(e.target.value)
        });
    }

    const onPropertyChange = e => {
        // Id de medida a asiganar
        const idMeasure = e.currentTarget.parentElement.parentElement.parentElement.getAttribute("id_medidad");
        // Guardar datos
        setData({
            ...data,
            propiedades: {
                ...data.propiedades,
                [idMeasure]: {
                    ...data.propiedades[idMeasure],
                    [e.target.name]: Number(e.target.value)
                }
            }
        });
    }
    //Validación para formulario
    const isFormValid = async () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.id_producto === '0' || data.id_producto === 0) {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere producto',
                show: true
            });
            return false;
        }
        // Validacion de valores y unidades
        return (Object.keys(data.propiedades).every(element => {
            if (data.propiedades[element].valor === 0) {
                setAlert({
                    title: 'Formulario incompleto',
                    message: 'Revisa los valores',
                    show: true
                });
                return false;
            } else if (data.propiedades[element].id_unidad === 0) {
                setAlert({
                    title: 'Formulario incompleto',
                    message: 'Revisa las unidades',
                    show: true
                });
                return false;
            } else {
                return true;
            }
        }));
    }

    // Insertar nueva caja
    const _handleSubmit = async (closeModal = true) => {
        if (await isFormValid()) {
            try {
                const sendData = Object.keys(data.propiedades).map((key) => (
                    { ...data.propiedades[key] }
                ));
                const response = await apiPostPropiertiesProduct(data.id_producto, sendData);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu propiedad ha sido agregado exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Limpiar datos
                    setMeasures([]);
                    setData({
                        id_producto: selectedProduct,
                        id_magnitud: '0',
                        propiedades: {},
                    });
                    // Cerrar modal
                    setShow(!closeModal);
                } else {
                    throw new Error("Error al agregar propiedad");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar nueva propiedad',
                    text: error.response?.data?.msg ?? 'Revise que la propiedad no exista ya en el producto e intentelo más tarde',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    // Guardar y cerrar
    const handleSaveClose = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(true);
    }

    // Guardar y limpiar
    const handleSave = async e => {
        e.preventDefault();
        // Envio de información
        await _handleSubmit(false);
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="md"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle={t('agregar')+" "+t('propiedades')}
                buttonTitle={t('agregar_y_cerrar')}
                handleClick={handleSaveClose}
                auxButtonTitle={t('agregar_y_guardar')}
                handleAuxButton={handleSave}
                
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_producto" className="form-label">
                                            {t('producto')}: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_producto"
                                            id="id_producto"
                                            value={data.id_producto}
                                            onChange={onSelectProduct}
                                        >
                                            <option value="0">{t('seleccione')}  {t('producto')}</option>
                                            {
                                                products.map(({ pr_id_producto, pr_nombre }, index) => (
                                                    <option value={pr_id_producto} key={index}>
                                                        {pr_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_magnitud" className="form-label">
                                            {t('magnitud')}: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_magnitud"
                                            id="id_magnitud"
                                            value={data.id_magnitud}
                                            onChange={onSelectMagnitud}
                                        >
                                            <option value="0">{t('magnitud')}  {t('magnitud')}</option>
                                            {
                                                magnitudes.map(({ glb_id_magnitud, glb_nombre }, index) => (
                                                    <option value={glb_id_magnitud} key={index}>
                                                        {glb_nombre}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                                {
                                    measures.length > 0
                                        ? measures.map((element, index) => (
                                            <Row key={index} id_medidad={element.mag_id_medida}>
                                                <Col md="6" xs="6">
                                                    <div className="form-group">
                                                        <label htmlFor={`measure_${index}`} className="form-label">
                                                            {element.mag_nombre}: *
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id={`measure_${index}`}
                                                            name="valor"
                                                            value={data.propiedades[element.mag_id_medida]?.valor}
                                                            onChange={onPropertyChange}
                                                            placeholder="1, 0.1, 15, etc"
                                                            min="0.1"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="6" xs="6">
                                                    <div className="form-group">
                                                        <label htmlFor={`unit_${index}`} className="form-label">
                                                            {t('unidad')}: *
                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            name="id_unidad"
                                                            id={`unit_${index}`}
                                                            value={data.propiedades[element.mag_id_medida]?.id_unidad}
                                                            onChange={onPropertyChange}
                                                        >
                                                            <option value="0">Seleccione una unidad</option>
                                                            {
                                                                units.map(({ mag_id_unidad, mag_nombre, mag_simbolo }, index) => (
                                                                    <option value={mag_id_unidad} key={index}>
                                                                        {`${mag_simbolo} (${mag_nombre})`}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))
                                        : <></>
                                }
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default AddProdProperties;