import { useState } from 'react';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Conexion Api
import {
    apiPostNewReportTicketsPriceLists
} from '../../../common/api-invent';

const ReportTicketsPriceLists = ({ show, setShow }) => {
    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Manejar carga de datos
    const [loading, setLoading] = useState(false);

    // Hook para formulario
    const [data, setData] = useState({
        fecha_ini: moment().format("YYYY-MM-DD"),
        fecha_fin: moment().format("YYYY-MM-DD"),
    });

    // Llena datos con informacion del formulario
    const handleFormChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.fecha_ini === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere fecha de inicio',
                show: true
            });
            return false;
        }
        if (data.fecha_fin === '') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere fecha de termino',
                show: true
            });
            return false;
        }
        if (moment(data.fecha_fin).isAfter()) {
            setAlert({
                title: 'Formulario erroneo',
                message: 'Fecha termino maxima día de hoy',
                show: true
            });
            return false;
        }
        if (moment(data.fecha_fin).isSameOrBefore(data.fecha_ini)) {
            setAlert({
                title: 'Formulario erroneo',
                message: 'Fecha termino debe ser menor a inicio',
                show: true
            });
            return false;
        }
        return true;
    }

    // Generar reporte
    const handleSubmit = async e => {
        e.preventDefault();

        if (isFormValid()) {
            try {
                setLoading(true);
                const response = await apiPostNewReportTicketsPriceLists(data.fecha_ini, data.fecha_fin);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu reporte ha sido generado exitosamente",
                        icon: "success",
                        confirmButtonText: 'Aceptar'
                    });
                    // Limpiar datos
                    setData({
                        fecha_ini: moment().format("YYYY-MM-DD"),
                        fecha_fin: moment().format("YYYY-MM-DD"),
                        format: 'excel'
                    });
                    // Descargar / mostrar reporte
                    window.open(response.data.content, '_blank');
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al agregar reporte");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al generar reporte',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="md"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Generar reporte de Entradas por Compra VS Listas de Precio"
                buttonTitle="Generar"
                handleClick={handleSubmit}
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="50px"
                            marginTop="50px"
                        />
                        : <Form>
                            <Row>
                                <Col xs md="6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_ini" className="form-label">
                                            Fecha Inicio: *
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="fecha_inicio"
                                            name="fecha_ini"
                                            value={data.fecha_ini}
                                            onChange={handleFormChange}
                                        />
                                    </div>
                                </Col>
                                <Col xs md="6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_termino" className="form-label">
                                            Fecha Término: *
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="fecha_fin"
                                            name="fecha_fin"
                                            value={data.fecha_fin}
                                            onChange={handleFormChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default ReportTicketsPriceLists;