import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Estilos
import './horizontalTabs.styled.css';

const HorizontalTabs = ({
  tabsTitles,
  tabsContent,
  onChangeTab,
  onInitilize,
  activeTab
}) => {
  const [activeContent, setActiveContent] = useState(0);
  const [isFirstTime, setIsFirstTime] = useState(true);
  // Carga de funcion la primera vez
  useEffect(() => {
    if (isFirstTime && Object.keys(tabsTitles).length > 0) {
      onInitilize(tabsTitles[0].id);
      setIsFirstTime(false);
    }
  }, [tabsTitles, onInitilize, isFirstTime]);

  useEffect(()=>{
    setActiveContent(activeTab ?? 0)
  }, [activeTab, setActiveContent]);

  if (tabsTitles.length !== tabsContent.length) {
    console.warn("Titulos y contenido deben tener misma longitud\n");
    return <></>
  }
  return (
    <>
      <ul className="nav nav-tabs customtab2">
        {
          tabsTitles.map((title, index) => {
            return (
              <li
                key={index}
                className="nav-item"
              >
                <p
                  onClick={() => { setActiveContent(index); onChangeTab(title.id) }}
                  className={`nav-link ${index === activeContent ? 'active' : ''}`}
                  href="#home4">
                  <span className="hidden-sm-up">{index + 1}</span>
                  <span className="hidden-xs-down">{title.content}</span>
                </p>
              </li>
            )
          })
        }
      </ul>
      {/* Contenido de Tabs */}
      <div className="tab-content px-10 py-30">
        {
          tabsContent.map((node, index) => (
            <div
              key={index}
              className={`tab-pane ${index === activeContent ? 'active' : ''}`}
            >
              {node}
            </div>
          ))
        }
      </div>
    </>
  );
}
// Documentacion PropTypes
HorizontalTabs.propTypes = {
  tabsTitles: PropTypes.array.isRequired,
  tabsContent: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func,
  onInitilize: PropTypes.func,
  activeTab: PropTypes.number
}
HorizontalTabs.defaultProps = {
  onChangeTab: () => { },
  onInitilize: () => { },
}

export default HorizontalTabs;