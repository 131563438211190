import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion Api
import {
    apiGetMeasureId,
    apiGetUnitFromMagnitud,
    apiGetProduct,
    apiGetPropiertyProductId,
    apiPatchPropierty,
} from '../../../common/api-invent';

const EditProdProperties = ({ show, setShow, id }) => {
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 12;

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [units, setUnits] = useState([]);
    const [product, setProduct] = useState({
        nombre: ''
    });

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Hook para formulario
    const [data, setData] = useState({
        id_unidad: '0',
        valor: '0',
        nombre_medida: ''
    });
    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                // Informacion de la propiedad
                const reqProps = await apiGetPropiertyProductId(id);
                // Obtener magnitud de la medida
                const reqMea = await apiGetMeasureId(reqProps.data.content.id_medida);
                // Obtener unidades de la magnitud
                const reqUnis = await apiGetUnitFromMagnitud(reqMea.data.content.id_magnitud);
                setUnits([...reqUnis.data.content]);
                // Informacion del producto
                const reqProd = await apiGetProduct(reqProps.data.content.id_producto);
                setProduct(prod => ({
                    ...prod,
                    nombre: reqProd.data.content.nombre
                }))
                // Guardar informacion de la propiedad
                setData(data => ({
                    ...data,
                    id_unidad: reqProps.data.content.id_unidad,
                    valor: reqProps.data.content.valor,
                    nombre_medida: reqMea.data.content.nombre
                }))
                setLoading(false);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos',
                    text: 'Intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        // Previnir llamada mientras esta oculto
        if (show)
            loadData()
        // Funcion limpieza
        return () => {
            setLoading(false);
        }
    }, [setProduct, setData, setLoading, show, id]);


    const onPropertyChange = e => {
        // Guardar datos
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    }
    //Validación para formulario
    const isFormValid = async () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });

        if (data.valor === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere de un valor',
                show: true
            });
            return false;
        }
        if (data.id_unidad === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere de una unidad',
                show: true
            });
            return false;
        }
        return true;
    }

    // Insertar nueva caja
    const handleSubmit = async event => {
        event.preventDefault();

        if (await isFormValid()) {
            try {
                const sendData = { ...data }
                delete sendData.nombre_medida;
                const response = await apiPatchPropierty(id, sendData);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "Tu propiedad ha sido editada exitosamente",
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al editar propiedad");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al editar propiedad',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente más tarde',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }

    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                size="md"
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Editar Propiedad"
                buttonTitle="Guardar"
                handleClick={handleSubmit}
            >
                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="producto_nombre" className="form-label">
                                            Producto:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="producto_nombre"
                                            value={product.nombre}
                                            disabled
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Row>
                                    <Col md="6" xs="6">
                                        <div className="form-group">
                                            <label htmlFor="valor" className="form-label">
                                                {data.nombre_medida}: *
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="valor"
                                                name="valor"
                                                value={data.valor}
                                                onChange={onPropertyChange}
                                                placeholder="1, 0.1, 15, etc"
                                                min="1"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6" xs="6">
                                        <div className="form-group">
                                            <label htmlFor="id_unidad" className="form-label">
                                                Unidad: *
                                            </label>
                                            <select
                                                className="form-select"
                                                name="id_unidad"
                                                id="id_unidad"
                                                value={data.id_unidad}
                                                onChange={onPropertyChange}
                                            >
                                                <option value="0">Seleccione una unidad</option>
                                                {
                                                    units.map(({ mag_id_unidad, mag_nombre, mag_simbolo }, index) => (
                                                        <option value={mag_id_unidad} key={index}>
                                                            {`${mag_simbolo} (${mag_nombre})`}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    );
}

export default EditProdProperties;