// Estilos
import './App.css';
// Router
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// Paginas
import LoginPage from './pages/login.page';
import DashBoardPage from './pages/dashboard.page';
// Estados Globales
import AuthState from './context/auth/auth.state';
import ReloadState from './context/reload/reload.state';
import SelectItemState from './context/selectItem/selectItem.state';
import { headersAuth } from './common/requests';

// Revisar si el usuario esta autenticado
const token = localStorage.getItem('tokenUser');
const idCompanyUser = localStorage.getItem('idCompanyUser');
if (token && idCompanyUser) {
  headersAuth({
    Authorization: `Bearer ${localStorage.getItem('tokenUser')}`,
    id_empresa: idCompanyUser
  });
}

function App() {
  return (
    <AuthState>
      <ReloadState>
        <SelectItemState>
          <Router>
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/dashboard/:idPage?/:extraParam1?" component={DashBoardPage} />
            </Switch>
          </Router>
        </SelectItemState>
      </ReloadState>
    </AuthState >
  );
}

export default App;
