import { useState} from 'react';


export const useForms = (data) => {
    
    const [form, setForm] = useState([data])

    const onChangeField = (e) => {
        const index = Number(e.target.getAttribute('index'));
        const field = e.target.name;
        const aux = form;
        aux[index] = {
            ...aux[index],
            [field]: e.target.value,
        };
        setForm([...aux]);
    }

    const onDeleteRow = e => {
        const index = Number(e.currentTarget.getAttribute('index'));
        // Objeto auxiliar para guardar valores
        const aux = [...form];
        // Eliminar valor
        aux.splice(index, 1);
        // Guardar valores
        setForm([...aux])
    }

    const onAddRow = (data) => {
        setForm([...form,
            data
        ]);
    }

    const resetForm = () => {
        setForm([data])
    }

    return{
        form,
        onChangeField,
        setForm,
        onDeleteRow,
        onAddRow,
        resetForm
    }
}