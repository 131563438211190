import { useState, useEffect, useContext } from 'react';
import {
    Form,
    Row,
    Col,
} from 'react-bootstrap';
import Swal from 'sweetalert2';
// Componentes Generales
import Modals from '../modal.component';
import ToastComponent, { ToastModalContainer } from '../../Toast/toast.component';
import Load from '../../Load/load.component';
// Contexto
import ReloadContext from "../../../context/reload/reload.context";
// Conexion API
import {
    apiGetEntryById,
    apiGetEntryExtraById,
    apiGetStorageRulesProduct,
    apiPostNewExistence
} from '../../../common/api-invent';

const AddEntranceProductModal = ({ show, setShow, id }) => {
    const idZLevel = process.env.REACT_APP_STORE_ID_ZLEVEL;
    
    const { onReloadComponent } = useContext(ReloadContext);
    const idReloadComp = 24;

    // Manejar carga de datos
    const [loading, setLoading] = useState(true);
    const [rules, setRules] = useState([]);

    // Hook para formulario
    const [data, setData] = useState({
        id_entrada: '0',
        id_almacen_ubicacion: '0',
        producto_nombre: '',
        codigo_caja: '',
        codigo_tarima: '',
        lote: '',
    });

    // Objeto de alerta
    const [alert, setAlert] = useState({
        title: '',
        message: '',
        show: false
    });

    // Escuchar cambio en id
    useEffect(() => {
        setData(data => ({ ...data, id_entrada: id }));
    }, [id]);

    // Cargar datos
    useEffect(() => {
        const loadData = async () => {
            try {
                setLoading(true);
                // Solicitar datos de entrada
                const reqEntry = await apiGetEntryById(id);
                const reqEntryExtra = await apiGetEntryExtraById(id);
                const idProduct = reqEntry.data.content.id_producto;
                // Solicitar reglas
                const reqRules = await apiGetStorageRulesProduct(idProduct);
                setRules([
                    ...reqRules.data.content.map(e => ({
                        text: `${e.nivel} - ${e.codigo} | ${e.r_razon_ubicacion} | ${e.nombre}`,
                        value: e.id_almacen_ubicacion,
                        enabled: e.enabled
                    }))
                ]);
                setData(data => ({
                    ...data,
                    producto_nombre: reqEntry.data.content.producto,
                    codigo_caja: reqEntry.data.content.codigo_caja,
                    codigo_tarima: reqEntryExtra.data.content.codigo_tarima,
                    lote: reqEntry.data.content.lote,
                }))
                setLoading(false);
            } catch (e) {
                console.log(`Ocurrio un error:\n${e}`)
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cargar datos, intentelo despúes',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
        if (show)
            loadData()
        return () => setLoading(false);
    }, [show, id]);

    // Llena el arreglo de datos con los datos del formulario
    const handleChangeRule = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    //Validación para formulario
    const isFormValid = () => {
        setAlert({
            title: '',
            message: '',
            show: false,
        });
        if (data.id_almacen_ubicacion === '0') {
            setAlert({
                title: 'Formulario incompleto',
                message: 'Se requiere nivel para almacenear',
                show: true
            });
            return false;
        }
        return true;
    }

    // Envio de formulario
    const handleSubmit = async () => {
        if (isFormValid()) {
            try {
                const response = await apiPostNewExistence(data);

                if (response.data.type === "success") {
                    await Swal.fire({
                        title: "La entrada ha sido agregado exitosamente",
                        icon: "success",
                        confirmButtonText: 'Aceptar'
                    });
                    // Recarga de componente
                    onReloadComponent(idReloadComp);
                    // Limpiar datos
                    setData({
                        id_entrada: '0',
                        id_almacen_ubicacion: '0',
                        producto_nombre: '',
                        codigo_caja: '',
                        codigo_tarima: '',
                        lote: '',
                    });
                    // Cerrar modal
                    setShow(false);
                } else {
                    throw new Error("Error al agregar entrada");
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al agregar entrada',
                    text: error.response?.data?.msg ?? 'Intentelo nuevamente',
                    confirmButtonText: 'Aceptar',
                });
            }
        }
    }


    return (
        <>
            <ToastModalContainer
                show={alert.show}
            >
                <ToastComponent
                    type="danger"
                    title={alert.title}
                    message={alert.message}
                    show={true}
                    onClose={() => setAlert({
                        ...alert,
                        show: false,
                    })}
                />
            </ToastModalContainer>
            <Modals
                show={show}
                hideModal={() => { setShow(false) }}
                modalTitle="Almacenar Producto"
                buttonTitle="Almacenar"
                handleClick={handleSubmit}
                size="md"
            >

                {
                    loading
                        ? <Load
                            size={150}
                            marginBottom="10px"
                            marginTop="10px"
                        />
                        : <Form>
                            <Row>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="producto_nombre" className="form-label">
                                            Producto:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="producto_nombre"
                                            name="producto_nombre"
                                            value={data.producto_nombre}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="codigo_caja" className="form-label">
                                            Codigo de Embalaje:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="codigo_caja"
                                            name="codigo_caja"
                                            value={data.codigo_caja}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="codigo_tarima" className="form-label">
                                            Codigo de Tarima:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="codigo_tarima"
                                            name="codigo_tarima"
                                            value={data.codigo_tarima}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                                <Col md="6" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="lote" className="form-label">
                                            Lote:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lote"
                                            name="lote"
                                            value={data.lote}
                                            readOnly={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md="12" xs="12">
                                    <div className="form-group">
                                        <label htmlFor="id_almacen_ubicacion" className="form-label">
                                            Almacenado en: *
                                        </label>
                                        <select
                                            className="form-select"
                                            name="id_almacen_ubicacion"
                                            id="id_almacen_ubicacion"
                                            value={data.id_almacen_ubicacion}
                                            onChange={handleChangeRule}
                                        >
                                            <option value="0">Seleccione un almacenamiento</option>
                                            {
                                                rules.map(({ text, value, enabled }, index) => (
                                                    <option value={value} key={index} disabled={enabled}>
                                                        {text}
                                                    </option>
                                                ))
                                            }
                                            <option value={idZLevel}> Nivel Z - Nivel Z | Almacenamiento Provisional</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                }
            </Modals>
        </>
    )
}

export default AddEntranceProductModal;